import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import '../pages/manage-infrastructure/ManageInfrastructure.css';

export default class CollapsibleListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNavbar: true,
      itemHover: ''
    };

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.getNavbarToggleIcon = this.getNavbarToggleIcon.bind(this);
  }

  getNavbarToggleIcon() {
    return '';
  }

  toggleNavbar() {
    this.setState({
      showNavbar: !this.state.showNavbar
    });
  }

  render() {
    return (
      <div className="levels-navbar">
        <Navbar color="faded" light>
          <NavbarToggler
            children={this.getNavbarToggleIcon}
            onClick={this.toggleNavbar}
            className={this.state.showNavbar ? 'not-active-toggler' : 'active-toggler'}
          />

          <NavbarBrand
            className="level-view-header"
            onMouseOut={() => this.setState({ itemHover: '' })}
          >
            <p onClick={this.toggleNavbar}>{`${this.props.headerName}s `}</p>
            <p className="resources-display" onClick={this.toggleNavbar}>
              {(this.props.tierArray.length === 0 ? '...' : this.props.tierArray.length) +
                (this.props.tierArray.length > 1 ? ' Resources' : ' Resource')}
            </p>
          </NavbarBrand>

          <button
            className="default-button"
            onClick={this.props.addHandler.bind(this, this.props.headerName)}
          >
            {`+ Add ${this.props.headerName}`}
          </button>

          <Collapse isOpen={!this.state.showNavbar} navbar>
            <hr className="styled-nav" />
            <Nav navbar>
              {this.props.tierArray.map(tier => {
                return (
                  <NavItem
                    className={
                      this.state.itemHover === tier.thisId
                        ? 'level-view-active'
                        : 'level-view-items'
                    }
                    onMouseOver={() => this.setState({ itemHover: tier.thisId })}
                    key={tier.thisId}
                    id={tier.thisId}
                  >
                    <div id="level-row-title"> {tier.thisName ? tier.thisName : tier.thisId} </div>

                    {this.state.itemHover === tier.thisId && (
                      <div className="level-icon-buttons">
                        <IconButton
                          className="icon-button"
                          aria-label="Edit"
                          id={tier.thisName}
                          onClick={this.props.editHandler.bind(
                            this,
                            tier.thisId,
                            this.props.headerName
                          )}
                          style={{
                            color: '#166ed5',
                            cursor: 'pointer'
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          className="icon-button"
                          aria-label="Delete"
                          id={tier.thisName}
                          onClick={this.props.deleteHandler.bind(
                            this,
                            tier.thisId,
                            this.props.headerName
                          )}
                          style={{
                            color: '#F62227',
                            cursor: 'pointer'
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    )}
                  </NavItem>
                );
              })}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
