import React, { Fragment, Component } from 'react';
import Divider from '@material-ui/core/Divider';
import { Card, CardBody } from 'mdbreact';
import './NewRequest.css';
import _ from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';
import memoizeOne from 'memoize-one';
import { Modal } from 'react-bootstrap';
import SearchAndSubmit from '../SearchAndSubmit';
import MoreImage from '../../img/More_blue.png';
import RequestCardComponent from './RequestCardComponent';
import { getFormattedDate } from '../../common/Utilities';

export default class NewRequest extends Component {
  constructor(props) {
    super(props);

    this.selectItem = this.selectItem.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.getUserSearchAndSubmit = this.getUserSearchAndSubmit.bind(this);
    this.getDefaultInput = this.getDefaultInput.bind(this);
    this.submitButton = this.submitButton.bind(this);
    this.cancelRequest = this.cancelRequest.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.buildItemSchema = this.buildItemSchema.bind(this);
    this.buildUsersSchema = this.buildUsersSchema.bind(this);
    this.selectVendor = this.selectVendor.bind(this);
    this.getVendorSearchAndSubmit = this.getVendorSearchAndSubmit.bind(this);
    this.buildVendorsSchema = this.buildVendorsSchema.bind(this);
    this.handleVendorInput = this.handleVendorInput.bind(this);
    this.getInputStyle = this.getInputStyle.bind(this);
    this.checkDuplicateRequest = this.checkDuplicateRequest.bind(this);
    this.returnDuplicateModal = this.returnDuplicateModal.bind(this);
    this.returnDuplicateRequestList = this.returnDuplicateRequestList.bind(this);
    this.showDuplicateRequestCards = this.displayDuplicateRequestCards.bind(this);

    this.state = {
      searchItem: undefined,
      showSearchList: true,
      selectPastOrder: true,
      duplicateRequestList: [],
      duplicateRequestExists: false
    };
  }

  async componentDidMount() {
    let itemInfoObj = {
      title: 'Item Information',
      propertySchema: [
        {
          name: 'Item Name',
          param_name: 'stock_item_name',
          validation(input) {
            return {
              error: !input || input === '',
              message: 'Please provide an item name'
            };
          },
          input_type: this.getDefaultInput,
          input_type_length: 'm'
        },
        {
          name: 'Order Number',
          param_name: 'order_number',
          input_type: this.getDefaultInput,
          input_type_length: 'm'
        },
        {
          name: 'Vendor',
          param_name: 'vendor',
          input_type: this.getVendorSearchAndSubmit,
          input_type_length: 'm'
        },
        {
          name: 'Package Size',
          param_name: 'size',
          input_type: this.getDefaultInput,
          input_type_length: 's',
          editable: true
        }
      ]
    };

    const requestInfoObj = {
      title: 'Request Information',
      propertySchema: [
        {
          name: "Requester's Name",
          param_name: 'user_id',
          validation(input) {
            return {
              error: !input || input === '',
              message: 'Please provide your name'
            };
          },
          input_type: this.getUserSearchAndSubmit,
          input_type_length: 'm'
        },
        {
          name: 'How many to order?',
          param_name: 'quantity',
          input_type: this.getDefaultInput,
          input_type_length: 's'
        },
        {
          name: 'Comments',
          param_name: 'comments',
          input_type: this.getDefaultInput,
          input_type_length: 'm',
          placeholder: 'Add optional comments'
        }
      ]
    };

    if (this.hasOptionalColumnHeader('Cost')) {
      itemInfoObj.propertySchema.push({
        name: 'Cost ($)',
        param_name: 'cost',
        validation(input) {
          return {
            error: input && !/^[0-9]*(\.[0-9]{1,2}){0,1}$/.test(input),
            message: 'Please provide a cost up to 2 decimal places without $ sign'
          };
        },
        input_type: this.getDefaultInput,
        input_type_length: 's'
      });
    }

    const groupSchema = [itemInfoObj, requestInfoObj];
    const formContents = {};
    const currentStyle = {};

    groupSchema.forEach(eachObj => {
      eachObj.propertySchema.forEach(schemaItem => {
        formContents[schemaItem.param_name] = null;
        currentStyle[schemaItem.param_name] = null;
      });
    });

    this.setState({
      groupSchema,
      formContents,
      currentStyle,
      isValid: formContents,
      usersSchema: this.buildUsersSchema(),
      vendorsSchema: this.buildVendorsSchema()
    });
  }

  hasOptionalColumnHeader = columnHeader => {
    const { optionalColumnHeader } = this.props;
    return optionalColumnHeader && optionalColumnHeader.indexOf(columnHeader) !== -1;
  };

  getInputStyle(schemaItem, inputValue, clicked) {
    const onBlurStyle = {
      width: '50%',
      lineHeight: 2,
      boxSizing: 'border-box',
      borderColor: '#ced4da',
      padding: 5,
      borderRadius: 5,
      resize: 'none'
    };

    const readOnlyStyle = {
      width: '50%',
      border: 0,
      resize: 'none'
    };

    const errorStyle = {
      width: '50%',
      lineHeight: 2,
      boxSizing: 'border-box',
      borderColor: 'red',
      padding: 5,
      borderRadius: 5,
      resize: 'none'
    };

    const onFocusStyle = {
      width: '50%',
      lineHeight: 2,
      boxSizing: 'border-box',
      padding: 5,
      borderRadius: 5,
      resize: 'none',
      color: '#495057',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
    };

    const { isValid } = this.state;
    if (clicked) {
      return onFocusStyle;
    }
    if (inputValue && !schemaItem.editable) {
      return readOnlyStyle;
    }
    if (isValid[schemaItem.param_name].error) {
      return errorStyle;
    }
    return onBlurStyle;
  }

  getDefaultInput(schemaItem, searchItem) {
    const inputValue = searchItem && searchItem[schemaItem.param_name];

    return (
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <TextareaAutosize
          name={schemaItem.param_name}
          onFocus={event => {
            const style = this.getInputStyle(schemaItem, inputValue, true);
            this.setState({
              currentStyle: {
                ...this.state.currentStyle,
                [event.target.name]: style
              }
            });
          }}
          onBlur={event => {
            this.setState({
              currentStyle: {
                ...this.state.currentStyle,
                [event.target.name]: null
              }
            });
          }}
          style={
            this.state.currentStyle[schemaItem.param_name] ||
            this.getInputStyle(schemaItem, inputValue, false)
          }
          readOnly={schemaItem.editable ? false : inputValue}
          value={
            schemaItem.editable
              ? this.state.formContents[schemaItem.param_name]
              : inputValue || this.state.formContents[schemaItem.param_name] || ''
          }
          placeholder={schemaItem.placeholder}
          onChange={e => {
            this.setState({
              formContents: {
                ...this.state.formContents,
                [schemaItem.param_name]: e.target.value
              }
            });
          }}
          maxRows={3}
        />
      </form>
    );
  }

  selectUser(selectedUser) {
    this.setState({
      formContents: {
        ...this.state.formContents,
        user_id: selectedUser
      },
      isValid: {
        ...this.state.isValid,
        user_id: false
      }
    });
  }

  getUserSearchAndSubmit() {
    const { formContents, usersSchema, isValid, showUserList } = this.state;
    const { users } = this.props;
    return (
      <div>
        <SearchAndSubmit
          value={users[formContents.user_id] ? users[formContents.user_id].fullname : ''}
          searchMap={usersSchema}
          selectItem={this.selectUser}
          searchStyle="fixed"
          showSearchList={showUserList}
          name="user_id"
          isAutoResizable
          inputNotValid={isValid.user_id.error}
        />
      </div>
    );
  }

  buildUsersSchema() {
    const suggestionToQueryParam = [];
    const { users } = this.props;

    for (const key in users) {
      const object = users[key];

      suggestionToQueryParam.push({
        id: key,
        key: [object.fullname, object.username],
        displayString: object.fullname
      });
    }

    return suggestionToQueryParam;
  }

  selectVendor(selectedVendor) {
    const vendor = this.props.vendors[selectedVendor];
    this.setState({
      formContents: {
        ...this.state.formContents,
        vendor
      },
      isValid: {
        ...this.state.isValid,
        vendor: false
      }
    });
  }

  handleVendorInput(inputValue) {
    if (this.state.formContents.vendor !== inputValue) {
      this.setState({
        formContents: {
          ...this.state.formContents,
          vendor: inputValue
        },
        isValid: {
          ...this.state.isValid,
          vendor: false
        }
      });
    }
    return null;
  }

  getVendorSearchAndSubmit(schemaItem) {
    const {
      searchItem,
      selectPastOrder,
      formContents,
      vendorsSchema,
      showSearchList,
      isValid
    } = this.state;
    if (selectPastOrder && formContents.vendor) {
      return (
        <form
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          {this.getDefaultInput(schemaItem, searchItem)}
        </form>
      );
    }
    // otherwise take in user input with the vendor-suggestion list
    return (
      <div
        onFocus={() => {
          this.setState({ showSearchList: true, selectPastOrder: false });
        }}
      >
        <SearchAndSubmit
          value={formContents.vendor}
          searchMap={vendorsSchema}
          selectItem={this.selectVendor}
          searchStyle="fixed"
          showSearchList={showSearchList}
          handleInputChange={value => {
            this.handleVendorInput(value);
          }}
          name="vendor"
          isAutoResizable
          inputNotValid={isValid.vendor.error}
        />
      </div>
    );
  }

  buildVendorsSchema() {
    const suggestionToQueryParam = [];
    const { vendors } = this.props;

    for (const key in vendors) {
      const object = vendors[key];

      suggestionToQueryParam.push({
        id: key,
        key: object,
        displayString: object
      });
    }

    return suggestionToQueryParam;
  }

  buildItemSchema() {
    const suggestionToQueryParam = [];
    const { stockItems } = this.props;

    for (const key in stockItems) {
      const object = stockItems[key];

      suggestionToQueryParam.push({
        id: object.stock_item_id,
        key: [object.vendor, object.order_number, object.stock_item_name, object.size],
        displayString: this.getDisplayString(object)
      });
    }

    return suggestionToQueryParam;
  }

  cachedbuildItemSchema = memoizeOne(length => this.buildItemSchema());

  getDisplayString(item) {
    let result = '';

    if (item.stock_item_name) {
      result += `Item: <span class='list-group-item-highlight'>${item.stock_item_name}</span> `;
    }
    if (item.order_number) {
      result += `Order Number: <span class='list-group-item-highlight'>${item.order_number}</span> `;
    }
    if (item.size) {
      result += `Package Size: <span class='list-group-item-highlight'>${item.size}</span> `;
    }
    if (item.vendor) {
      result += `Vendor: <span class='list-group-item-highlight'>${item.vendor}</span> `;
    }

    return result;
  }

  selectItem(selectedItem) {
    const { groupSchema } = this.state;
    const { stockItems } = this.props;
    const item = stockItems.find(eachItem => {
      if (eachItem.stock_item_id === selectedItem) {
        return eachItem;
      }
      return undefined;
    });

    const finalValue = item || selectedItem;

    const contents = _.cloneDeep(this.state.formContents);
    const isValid = _.cloneDeep(this.state.isValid);

    groupSchema.forEach(eachObj => {
      eachObj.propertySchema.forEach(schemaItem => {
        contents[schemaItem.param_name] = finalValue[schemaItem.param_name];
        isValid[schemaItem.param_name] = false;
      });
    });

    this.setState({
      searchItem: finalValue,
      formContents: contents,
      isValid,
      selectPastOrder: true
    });
  }

  validateInput() {
    let error = false;
    const { groupSchema, formContents } = this.state;
    const isValid = _.cloneDeep(this.state.isValid);

    groupSchema.forEach(eachObj => {
      eachObj.propertySchema.forEach(schemaItem => {
        if (schemaItem.validation) {
          const validationResult = schemaItem.validation(formContents[schemaItem.param_name]);
          isValid[schemaItem.param_name] = validationResult;
          if (validationResult.error) {
            error = true;
          }
        }
      });
    });

    this.setState({
      isValid
    });

    return error;
  }

  getStatusDate = date => {
    if (date === 'No ETA') {
      return 'No ETA';
    }
    return getFormattedDate(date, 'MMM DD, YYYY');
  };

  showComments = comments => {
    if (comments && comments.length > 20) {
      return `${comments.substring(0, 20)}...`;
    }
    return comments;
  };

  handleCloseModal = () => {
    this.setState({ duplicateRequestExists: false });
  };

  returnDuplicateModal() {
    const { duplicateRequestExists, searchItem } = this.state;
    if (searchItem && duplicateRequestExists) {
      return (
        <Modal
          backdropClassName="modal-backdrop"
          dialogClassName="modal-style"
          show={duplicateRequestExists}
          backdrop
          centered
          scrollable
          onHide={this.handleCloseModal}
        >
          <Modal.Header>
            <Modal.Title className="modal-title">
              <b>This item has already been requested</b>
            </Modal.Title>
            <button type="submit" className="close-button-modal" onClick={this.handleCloseModal}>
              X
            </button>
          </Modal.Header>

          <Modal.Body>
            <h4 className="modal-body">Active request(s) for this item:</h4>
            {this.displayDuplicateRequestCards(false, true)}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <h4>Would you like to create a new request for this item anyway?</h4>
            <div className="duplicate-request-modal-buttons">
              <button type="submit" className="cancel-button-modal" onClick={this.handleCloseModal}>
                Cancel
              </button>
              <button type="submit" className="submit-button-modal" onClick={this.submitButton}>
                Submit Request
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  }

  returnDuplicateRequestList(requestList, trimArray = false, filterBystatus = false) {
    const { searchItem } = this.state;
    let duplicateRequestList = [];
    if (searchItem) {
      const idToSearch = searchItem.stock_item_id;
      const nameToSearch = searchItem.stock_item_name;
      duplicateRequestList = requestList.filter(function(each) {
        if (
          (each.stock_item_id && each.stock_item_id === idToSearch) ||
          (nameToSearch && nameToSearch.toLowerCase() === each.item_name.toLowerCase())
        ) {
          if (filterBystatus) {
            if (
              each.status === 'New' ||
              each.status === 'Arriving' ||
              each.status === 'Backorder'
            ) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      });
    }
    duplicateRequestList = duplicateRequestList.sort(function(a, b) {
      return b.restock_requested_timestamp - a.restock_requested_timestamp;
    });
    if (trimArray && duplicateRequestList.length > 5) {
      duplicateRequestList.length = 5;
    }
    return duplicateRequestList;
  }

  checkDuplicateRequest() {
    const { searchItem } = this.state;
    let duplicateRequestList = [];
    const { requestDataList } = this.props;
    if (searchItem.stock_item_id) {
      duplicateRequestList = this.returnDuplicateRequestList(requestDataList, false, true);
    }
    if (!this.validateInput()) {
      if (duplicateRequestList.length > 0) {
        this.setState({
          duplicateRequestList,
          duplicateRequestExists: true
        });
      } else {
        this.submitButton();
      }
    }
  }

  async submitButton() {
    if (!this.validateInput()) {
      const { searchItem, formContents } = this.state;
      const { addStockItem, updateStockItem, addRequests, cancelRequestFn } = this.props;
      let stockItemId;
      let isNewItem = false;
      formContents.stock_item_name = formContents.stock_item_name.toUpperCase();
      if (!searchItem.stock_item_id) {
        const payload = {
          vendor: formContents.vendor || null,
          order_number: formContents.order_number || null,
          size: formContents.size || null,
          stock_item_name: formContents.stock_item_name
        };

        if (this.hasOptionalColumnHeader('Cost')) {
          payload.cost = Number(this.state.formContents.cost);
        }

        stockItemId = await addStockItem(payload);
        isNewItem = true;
      }

      const payload = {
        stock_item_id: stockItemId || searchItem.stock_item_id,
        restock_request_details: [
          {
            user_id: formContents.user_id,
            restock_requested_timestamp: Date.now(),
            restock_last_updated_timestamp: Date.now(),
            quantity: formContents.quantity && parseInt(formContents.quantity)
          }
        ],
        vendor: formContents.vendor || null,
        order_number: formContents.order_number || null,
        stock_item_name: formContents.stock_item_name,
        size: formContents.size || null,
        comment: formContents.comments || null,
        status: 'New'
      };

      // added this for Cost
      if (this.hasOptionalColumnHeader('Cost')) {
        payload.cost = Number(this.state.formContents.cost);
      }

      const form = _.cloneDeep(this.state.formContents);
      const oldStockItem = _.cloneDeep(this.state.searchItem);

      if (!isNewItem) {
        updateStockItem(form, oldStockItem);
      }
      addRequests(payload);
      cancelRequestFn();
    }
    this.setState({ duplicateRequestExists: false });
  }

  cancelRequest() {
    const formContents = {};
    this.state.groupSchema.forEach(eachObj => {
      eachObj.propertySchema.forEach(schemaItem => {
        formContents[schemaItem.param_name] = null;
      });
    });

    this.setState({
      searchItem: '',
      selectItem: undefined,
      formContents,
      isValid: formContents,
      duplicateRequestExists: false
    });

    this.props.cancelRequestFn();
  }

  displayDuplicateRequestCards(trimArray = false, filterByStatus = false) {
    const { requestDataList, renderListLabel } = this.props;
    const localDuplicateRequestList = this.returnDuplicateRequestList(
      requestDataList,
      trimArray,
      filterByStatus
    );
    if (localDuplicateRequestList.length > 0) {
      return (
        <div>
          {renderListLabel()}
          {localDuplicateRequestList.map(value => (
            <RequestCardComponent
              value={value}
              hasOptionalColumnHeader={this.hasOptionalColumnHeader}
              getStatusDate={this.getStatusDate}
              showComments={this.showComments}
            />
          ))}
        </div>
      );
    }
    return null;
  }

  render() {
    const { groupSchema, isValid, searchItem } = this.state;
    const { requestDataList } = this.props;
    const localDuplicateRequestList = this.returnDuplicateRequestList(requestDataList);
    const itemSchema = this.cachedbuildItemSchema(this.props.stockItems.length);
    const submitForm = item => {
      return (
        <Fragment>
          <Divider />
          {localDuplicateRequestList.length > 0 && searchItem.stock_item_name ? (
            <div className="duplicate-request-card">
              <h4>
                Recent Requests for
                <b>
                  <u>{searchItem.stock_item_name}</u>
                </b>
              </h4>
              {this.displayDuplicateRequestCards(true)}
            </div>
          ) : (
            <div className="no-duplicate-request-found">
              {searchItem.stock_item_name && (
                <h4>
                  <b>No recent requests found for {searchItem.stock_item_name}</b>
                </h4>
              )}
            </div>
          )}

          <div
            className="submit-form"
            onClick={event => {
              let showSearchList = false;
              let showUserList = false;
              if (event.target.name === 'vendor') {
                showSearchList = true;
              } else if (event.target.name === 'user_id') {
                showUserList = true;
              }

              this.setState({ showSearchList, showUserList });
            }}
          >
            {groupSchema.map((eachObj, index) => (
              <div className="new-request-form">
                <div className="new-request-form-title">
                  <h3>
                    <b>{eachObj.title}</b>
                  </h3>
                </div>
                <div className={`new-request-form-group${index}`}>
                  {eachObj.propertySchema.map(schemaItem => {
                    return (
                      <div className="input-div" key={schemaItem.name}>
                        <p className="form-input-label">{schemaItem.name}</p>
                        {schemaItem.input_type(schemaItem, item)}
                        {isValid[schemaItem.param_name].error && (
                          <p className="input-error-message">
                            {isValid[schemaItem.param_name].message}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className="horizontal-center-align">
            <button
              type="submit"
              className="request-submit-button"
              onClick={this.checkDuplicateRequest}
            >
              Submit Request
            </button>
          </div>
        </Fragment>
      );
    };

    const getPostAction = fn => {
      return (
        <Fragment>
          Don't see what you're looking for?
          <button
            className="add-new-item"
            onClick={() => {
              fn();
              this.selectItem({
                stock_item_id: '',
                vendor: '',
                order_number: '',
                size: '',
                stock_item_name: ''
              });
            }}
          >
            <div>Add a new Item</div>
          </button>
        </Fragment>
      );
    };
    return (
      <Card className="new-request-card">
        <CardBody id="main-card-body">
          <div className="title">
            <h2>New Request</h2>
          </div>
          <Divider />
          <div className="back-button">
            <p onClick={() => this.cancelRequest()} className="back-button-text">
              <img src={MoreImage} alt="more" className="back-button-icon" /> Cancel Request
            </p>
          </div>
          <div className="search-form">
            <p className="search-form-label">Enter item name or order number.</p>
            {
              <SearchAndSubmit
                selectItem={this.selectItem}
                postAction={getPostAction}
                searchStyle="fixed"
                searchMap={itemSchema}
                showSearchList
                name="stock_item_name"
                deleteStockItem={this.props.deleteStockItem}
              />
            }
          </div>
          {searchItem && submitForm(searchItem)}
          {this.returnDuplicateModal()}
        </CardBody>
      </Card>
    );
  }
}
