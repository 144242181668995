
class ErrorMessageHelper {

    /**
     *
     * @params:
     *    errorObject {
     *         code: "",
     *         name: "",
     *         message: ""  //received from aws sdk
     *      },
     *
     *    inputValue : "" // optional param
     *
     * @return errorObject {
     *            code: "",
     *            name: "",
     *            message: ""  // custom error message
     *      }
     */

    getErrorMessage(errorObject, input) {

      const errorMessageMap = {
          DefaultException: "Oops! Please try again.",
          UserNotFoundException: "Oops! Username " + input['username'] + " not found. Please try again.",
          NotAuthorizedException: "Oops! Incorrect password. Please try again.",
          AccountNotFoundException: "Oops! Account ID " + input['accountId'] + " not found. Please try again."
       };


      let errorCode = "DefaultException";
      let customErrorMessage = errorMessageMap[errorCode];

      if(errorObject.code){
          errorCode = errorObject.code;
          customErrorMessage = errorMessageMap[errorCode];
      }

      errorObject.message = customErrorMessage

      return errorObject;
    }
}

export default new ErrorMessageHelper();
