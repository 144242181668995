import React, { Fragment } from 'react';
import { Redirect } from 'react-router';
import { Card, CardBody } from 'mdbreact';
import { Alert } from 'react-bootstrap';
import './CreateUser.css';
import './UsersPage.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import UserBasePage from './UserBasePage';
import UserService from '../../services/UserService';
import ProfileForm from '../../components/ProfileForm';

export default class CreateUser extends UserBasePage {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      phone: null,
      jobTitle: '',
      givenName: '',
      familyName: '',
      switchPage: false,
      newUser: undefined,
      initialized: false,
      touched: {
        givenName: false,
        familyName: false,
        username: false,
        email: false,
        phone: false
      },
      createUserError: false,
      createUserErrorMessage: '',
      isValidPhoneNumber: true,
      isAdmin: false
    };
  }

  switchPages() {
    this.setState({
      switchPage: true
    });
  }

  handleChange = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  handleBlur = field => {
    const touched = { ...this.state.touched };
    touched[field] = true;
    this.setState({ touched });
  };

  canBeSubmitted = () => {
    const errors = this.validateCreateUserForm();
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    return !isDisabled;
  };

  shouldMarkError = field => {
    const errors = this.validateCreateUserForm();
    const hasError = errors[field];
    const shouldShow = this.state.touched[field];
    return hasError && shouldShow;
  };

  validatePhoneNumber = () => {
    this.handleBlur('phone');
    let valid = true;
    if (this.state.phone) {
      valid = isValidPhoneNumber(this.state.phone);
    }
    this.setState({ isValidPhoneNumber: valid });
    return valid;
  };

  validateCreateUserForm = () => {
    return {
      givenName: this.state.givenName.length === 0,
      familyName: this.state.familyName.length === 0,
      username: this.state.username.length === 0 || /\s/g.test(this.state.username),
      email:
        this.state.email.length === 0 ||
        !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/g.test(this.state.email),
      phone: this.state.phone && !this.state.isValidPhoneNumber
    };
  };

  componentDidMount() {
    const context = this;
    super.initializeUserService(UserService).then(_ => {
      context.setState({ initialized: true });
    });
  }

  /**
   * * {
   *     username: string,
   *     email: string (optional if phone is provided),
   *     phone: string (optional if email is provided),
   *     verifyMethod: ['email'|'sms']    //default 'email'
   *     givenName: string,
   *     familyName: string,
   *     title: string - optional
   * }
   * @param event
   * @return {Promise<void>}
   */
  handleSubmit = async event => {
    event.preventDefault();

    const username = this.state.username.toLowerCase();
    const { email, phone, givenName, familyName, jobTitle, isAdmin } = { ...this.state };

    const payload = {
      username,
      email,
      phone,
      verifyMethod: 'email', // change to 'sms' if we support verify through text message
      givenName,
      familyName,
      title: jobTitle,
      isAdmin
    };

    const context = this;
    UserService.createUser(payload).then(
      _ => {
        context.setState({
          username: '',
          email: '',
          phone: null,
          jobTitle: '',
          givenName: '',
          familyName: '',
          newUser: username,
          touched: {
            givenName: false,
            familyName: false,
            username: false,
            email: false
          },
          createUserError: false,
          createUserErrorMessage: ''
        });
        if (this.state.isAdmin) {
          UserService.makeAdmin(username).then(_ => {
            context.setState({ isAdmin: false });
          });
        }
      },
      err => {
        err.message += '! Please try a different one.';
        this.setState({ createUserError: true, createUserErrorMessage: err.message });
      }
    );
  };

  createUserSuccessBanner = () => {
    return (
      <Alert className="add-user-alerts" bsStyle="success" onDismiss={this.handleDismiss}>
        <h4>Success!</h4>
        <p>{`User ${this.state.newUser} has been created!`}</p>
      </Alert>
    );
  };

  handleDismiss = () => {
    this.setState({ newUser: undefined });
  };

  renderCreateUserErrorMessage = () => {
    if (this.state.createUserError) {
      return (
        <div className="error-message-group">
          <p className="error-message-text">{this.state.createUserErrorMessage}</p>
        </div>
      );
    }
    return null;
  };

  render() {
    if (this.state.switchPage) {
      return (
        <Redirect
          push
          to={{
            pathname: '/users'
          }}
        />
      );
    }

    const style = {
      width: '10px',
      verticalAlign: 'baseline'
    };
    return (
      <Fragment>
        <Card className="content_holder">
          {this.state.newUser && this.createUserSuccessBanner()}
          <div className="back-button">
            <p
              onClick={() => this.switchPages()}
              style={{ width: 'fit-content', cursor: 'pointer' }}
            >
              <img src={require('../../img/More.png')} alt="more" style={style} /> Back
            </p>
          </div>
          <Card className="add-user">
            <div className="users-title">Add User</div>
            <CardBody>
              {this.renderCreateUserErrorMessage()}
              <ProfileForm
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                shouldMarkError={this.shouldMarkError}
                validatePhoneNumber={this.validatePhoneNumber}
                create
              />
              <button
                disabled={!this.canBeSubmitted()}
                onClick={this.handleSubmit}
                className="add-user-button"
              >
                Create
              </button>
            </CardBody>
          </Card>
        </Card>
      </Fragment>
    );
  }
}
