import React, { Component, Fragment } from 'react';

import ManageOrganizationPage from 'pages/manage-infrastructure/ManageOrganizationPage';
import ManageTrackersPage from 'pages/manage-infrastructure/ManageTrackers';
import ManageInventory from 'pages/manage-infrastructure/ManageInventory';
import AbstractHomePage from 'pages/home/AbstractHomePage';
import TabbedSectionComponent from '../../components/vertical-tab-with-contents/TabbedSectionComponent';
import ConfigurationService from '../../services/ConfigurationService';

const tabContents = [
  {
    name: 'Plant',
    component: ManageOrganizationPage
  },
  {
    name: 'Tags',
    component: ManageTrackersPage
  },
  {
    name: 'Inventory',
    component: ManageInventory
  }
];

export default class Configuration extends Component {
  constructor(props) {
    super(props);

    const solutionConfig = ConfigurationService.getSolutionConfiguration();
    const manageOrganizationConfiguration = solutionConfig.manageOrganizationConfiguration;
    const manageOrganizationTabs = manageOrganizationConfiguration.tabs;
    const applicableTabs = this.getApplicableTabs(manageOrganizationTabs);
    const defaultTab = manageOrganizationConfiguration.defaultTab;

    this.state = {
      options: applicableTabs,
      defaultOptionIndex: defaultTab
    };
  }

  getApplicableTabs = manageOrganizationTabs => {
    const applicableTabs = [];
    manageOrganizationTabs.forEach(tabName => {
      const component = tabContents.find(e => e.name === tabName).component;
      const tab = { name: tabName, component };
      applicableTabs.push(tab);
    });

    return applicableTabs;
  };

  render() {
    return (
      <Fragment>
        <AbstractHomePage />
        <div style={{ 'margin-top': '3%' }}>
          <TabbedSectionComponent
            className="configuration rule-tab-selection"
            tabContents={this.state.options}
          />
        </div>
      </Fragment>
    );
  }
}
