import React, { Fragment } from 'react';
import { Card } from 'mdbreact';
import './UsersPage.css';
import DeleteIcon from '@material-ui/icons/Delete';
import UserService from '../../services/UserService';
import UserBasePage from './UserBasePage';

export default class UsersPage extends UserBasePage {
  constructor(props) {
    super(props);
    this.state = {
      users: undefined,
      superAdminUsers: undefined,
      itemHover: '',
      // inputUserSearch: '',
      switchPage: false,
      customerConfig: undefined
    };
  }

  componentDidMount() {
    const context = this;

    super.initializeUserService(UserService).then(async () => {
      await context.fetchUsers();
    });
  }

  async fetchUsers() {
    const superAdminUsers = (await UserService.getUsersInGroup('SuperAdmin')).map(
      user => user.Username
    );
    const users = (await UserService.getUsers()).map(user => {
      if (superAdminUsers.includes(user.Username)) {
        user.isSuperAdmin = true;
      }
      return user;
    });

    this.setState({ users });
  }

  handleDeleteClick = async user => {
    const firstName = user.Attributes.find(obj => {
      return obj.Name === 'given_name';
    });
    const lastName = user.Attributes.find(obj => {
      return obj.Name === 'family_name';
    });
    const username = user.Username;
    let confirmedMessage = 'Are you sure you want to delete user: ';
    if (firstName && lastName) {
      confirmedMessage += `${firstName.Value} ${lastName.Value}?`;
    } else {
      confirmedMessage += `${username}?`;
    }
    const confirmed = window.confirm(confirmedMessage);
    if (confirmed) {
      const context = this;

      UserService.deleteUser(user.Username).then(async () => {
        await context.fetchUsers();
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Card className="content_holder">
          <div className="users-list">
            <div className="users-list-title">Manage Users</div>

            <a href="/users/create" className="btn default-button add">
              + Add New User
            </a>

            <table className="users-table">
              <thead>
                <tr className="users-header">
                  <th className="users-header-username">Username</th>
                  <th className="users-header-firstName">First Name</th>
                  <th className="users-header-lastName">Last Name</th>
                  <th className="users-header-email">Email</th>
                  <th className="users-header-deleteButton"> </th>
                </tr>
              </thead>
              <tbody>
                {this.state.users &&
                  this.state.users.map(user => {
                    const email = UserService.getAttribute(user, 'email');
                    const firstName = UserService.getAttribute(user, 'given_name');
                    const lastName = UserService.getAttribute(user, 'family_name');
                    const itemRow = [
                      <tr
                        className={
                          this.state.itemHover === user.Username
                            ? 'user-row user-view-active'
                            : 'user-row'
                        }
                        onMouseOver={() => this.setState({ itemHover: user.Username })}
                        key={user.Username}
                        id={user.Username}
                      >
                        <td> {user.Username} </td>
                        <td> {firstName ? firstName : '-'} </td>
                        <td> {lastName ? lastName : '-'} </td>
                        <td> {email} </td>
                        <td>
                          {this.state.itemHover === user.Username && !user.isSuperAdmin ? (
                            <div
                              className="deleteButton"
                              onClick={() => this.handleDeleteClick(user)}
                            >
                              <DeleteIcon />
                            </div>
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                    ];
                    return itemRow;
                  })}
              </tbody>
            </table>
          </div>
        </Card>
      </Fragment>
    );
  }
}

/*
Search functionality for users is not yet implemented.
Put back under <div className="users-list-title">Manage Users</div> when implemented
<InputGroup className="user-search-bar">
    <FormControl id="search-form" type="text" placeholder="Search users" onChange={this.handleInputUserSearch} value={this.state.inputUserSearch}/>
</InputGroup>
*/
