import React, { Component } from 'react';
import EditIcon from '@material-ui/icons/Edit';

export default class EditActionRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  async handleClick() {
    await this.props.colDef.actionHandler(this.props.data);
  }

  render() {
    return (
      <div className="EditButton" onClick={this.handleClick}>
        <EditIcon style={{ color: '#166ed5', cursor: 'pointer' }} />
      </div>
    );
  }
}
