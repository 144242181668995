import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import './css/Overview.css';

const OverviewHeader = () => {
  return (
    <Fragment>
      <th className="header-text">Inventory Health</th>
      <th className="header-text">Total # of Units</th>
      <th className="header-text">Total # of Items</th>
    </Fragment>
  );
};

const OverviewBody = ({ health, color, units, items }) => {
  const statusColor = {
    color
  };
  return (
    <Fragment>
      <td style={statusColor} className="body-text">
        {health}
      </td>
      <td className="body-text">{units}</td>
      <td className="body-text">{items}</td>
    </Fragment>
  );
};

OverviewBody.propTypes = {
  health: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  units: PropTypes.number.isRequired,
  items: PropTypes.number.isRequired
};

const WeeklyStatsHeader = () => {
  return (
    <Fragment>
      <th className="header-text">Avg. Consumption Rate</th>
      <th className="header-text">Units Added</th>
      <th className="header-text">Units Consumed</th>
    </Fragment>
  );
};

const WeeklyStatsBody = ({ rate, added, consumed }) => {
  return (
    <Fragment>
      <td className="body-text">{rate}</td>
      <td className="body-text">{added}</td>
      <td className="body-text">{consumed}</td>
    </Fragment>
  );
};

WeeklyStatsBody.propTypes = {
  rate: PropTypes.string.isRequired,
  added: PropTypes.number.isRequired,
  consumed: PropTypes.number.isRequired
};

/*
 * Overview takes props from InventoryComponent and passes
 * the data into 3 stateless function fragments which together,
 * display the Overview data
 */
export default class Overview extends PureComponent {
  render() {
    const {
      health,
      color,
      totalUnits,
      totalItems,
      consumptionRate,
      unitsAdded,
      unitsConsumed
    } = this.props;

    return (
      <Fragment>
        <p className="table-title">Overview</p>
        <table>
          <thead>
            <tr>
              <OverviewHeader className="table-header" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <OverviewBody
                className="table-body"
                health={health}
                color={color}
                units={totalUnits}
                items={totalItems}
              />
            </tr>
          </tbody>
        </table>
        <p className="table-title">Metrics</p>
        <table>
          <thead>
            <tr>
              <WeeklyStatsHeader className="table-header" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <WeeklyStatsBody
                className="table-body"
                rate={`${consumptionRate} per day`}
                added={unitsAdded}
                consumed={unitsConsumed}
              />
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  }
}

Overview.propTypes = {
  health: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  totalUnits: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  consumptionRate: PropTypes.number.isRequired,
  unitsAdded: PropTypes.number.isRequired,
  unitsConsumed: PropTypes.number.isRequired
};
