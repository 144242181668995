import React, { PureComponent, Fragment } from 'react';
import { FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDownAlt
} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GridCard from './GridCard';
import SideFilter from './SideFilter';
import Style from './css/GridView.module.css';

const FilterIcon = {
  ascending: {
    filterFunc: (objects, attribute) => {
      return objects.sort((a, b) => {
        return sortByAttribute(a[attribute], b[attribute]);
      });
    }
  },
  descending: {
    filterFunc: (objects, attribute) => {
      return objects.sort((a, b) => {
        return sortByAttribute(b[attribute], a[attribute]);
      });
    }
  }
};

let sortByAttribute = (x, y) => {
  if (x && !y) {
    return 1;
  }
  if (!x && y) {
    return -1;
  }
  return String(x).localeCompare(String(y));
};

export default class GridView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      placeholder: 'Search for Asset',
      selectedFilter: null,
      filteredData: null,
      loading: true,
      currentSort: {
        object_name: 'ascending',
        due_date: 'ascending',
        lastUpdated: 'ascending'
      },
      attribute: 'due_date'
    };
  }

  componentDidMount() {
    const { searchObject, filter, tagMap, objects } = this.props;
    objects.forEach((item, index) => {
      const trackerName = tagMap[item.tracker_id] || 'No Tag';
      return (objects[index] = { ...objects[index], tracker_serial: trackerName });
    });

    this.setState({
      selectedFilter: 'All Assets',
      attribute: 'due_date',
      loading: false,
      data: objects,
      searchObject,
      filter
    });
  }

  filterBar = placeholder => {
    const { filterValue } = this.state;

    return (
      <div className={Style.filter_group}>
        <a className="btn default-button add" href="/tracked-object/add">
          + Add Asset
        </a>
        <FormControl
          type="text"
          className={Style.grid_search_bar}
          value={filterValue}
          placeholder={placeholder}
          onChange={e => {
            this.setState({ filterValue: e.target.value });
          }}
        />
        {this.sortButton()}
      </div>
    );
  };

  sortButton = () => {
    const { currentSort, attribute, selectedFilter } = this.state;
    const sortSchema = [
      {
        name: 'Name',
        attribute: 'object_name',
        icon: currentSort.object_name === 'ascending' ? faSortAlphaDown : faSortAlphaUp
      },
      {
        name: 'Last Updated',
        attribute: 'lastUpdated',
        icon: currentSort.lastUpdated === 'ascending' ? faSortNumericDown : faSortNumericUp
      }
    ];

    if (selectedFilter === 'All Assets') {
      sortSchema.unshift({
        name: 'Exp. Date',
        attribute: 'due_date',
        icon: currentSort.due_date === 'ascending' ? faSortNumericDown : faSortNumericUp
      });
    }

    return (
      <Dropdown>
        <DropdownToggle nav caret id="custom-nav">
          <FontAwesomeIcon icon={faSortAmountDownAlt} size="lg" className="filter-icon" />
        </DropdownToggle>
        <DropdownMenu className="dropdownmenu">
          {sortSchema.map(each => {
            return (
              <DropdownItem
                onClick={() => {
                  this.sortClick(each.attribute);
                }}
                active={each.attribute === attribute}
                toggle={false}
                style={{ outline: 'none' }}
              >
                {`${each.name}  `}
                <FontAwesomeIcon icon={each.icon} className="flter-icon" />
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  };

  sortClick = attribute => {
    const { currentSort } = this.state;
    let nextSort = '';
    if (currentSort[attribute] === 'ascending') {
      nextSort = 'descending';
    } else {
      nextSort = 'ascending';
    }
    this.setState({
      currentSort: {
        ...this.state.currentSort,
        [attribute]: nextSort
      },
      attribute
    });
  };

  handleSelected = selectedFilter => {
    this.setState({
      selectedFilter,
      filteredData: null
    });
    if (selectedFilter === 'Location' || selectedFilter === 'Type') {
      this.setState({ attribute: 'object_name' });
    }
    if (selectedFilter === 'Location') {
      this.filterByLocation();
    } else if (selectedFilter === 'Type') {
      this.filterByType();
    } else if (selectedFilter === 'All Assets') {
      this.setState({
        attribute: 'due_date',
        loading: false
      });
    }
  };

  filterByType = () => {
    const { tagMap } = this.props;

    const data = this.props.objects;
    data.forEach((item, index) => {
      const trackerName = tagMap[item.tracker_id] || 'No Tag';
      return (data[index] = { ...data[index], tracker_serial: trackerName });
    });

    let filteredData = [];
    filteredData = this.filterTypes(data);

    this.setState({
      filteredData,
      loading: false
    });
  };

  filterTypes = data => {
    let result = [];

    let orderList = this.splitByType(data);
    orderList = this.splitAndSort(orderList);
    result = orderList.map(ibt => {
      const items = ibt.items;
      const lastUpdatedTime = this.getLastUpdatedTime(items);

      const splitByLocations = this.sortByGroupInfoName(this.splitByDepartment(items));

      return {
        groupInfo: ibt.groupInfo,
        items: splitByLocations,
        lastUpdated: lastUpdatedTime,
        object_name: ibt.object_name
      };
    });
    return result;
  };

  sortAlpha = list => {
    const sortedList = []
      .concat(list)
      .sort((a, b) => (a.groupInfo.name > b.groupInfo.name ? 1 : -1));
    return sortedList;
  };

  splitAndSort = list => {
    let status = [];
    let normal = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].groupInfo.attributes.count.missing.value > 0) {
        status.push(list[i]);
      } else {
        normal.push(list[i]);
      }
    }
    status = this.sortAlpha(status);
    normal = this.sortAlpha(normal);
    return status.concat(normal);
  };

  filterByLocation = () => {
    const data = this.props.objects;

    let filteredData = [];

    filteredData = this.filterLocations(data);

    this.setState({
      filteredData,
      loading: false
    });
  };

  filterLocations = data => {
    const inventoryByLocations = this.sortByGroupInfoName(this.splitByDepartment(data));
    let result;

    result = inventoryByLocations.map(sbt => {
      const items = sbt.items;
      const lastUpdatedTime = this.getLastUpdatedTime(items);

      return {
        groupInfo: sbt.groupInfo,
        items,
        lastUpdated: lastUpdatedTime,
        object_name: sbt.object_name
      };
    });

    return result;
  };

  splitOrders = data => {
    return data.map(order => {
      return {
        groupInfo: {
          id: order.tracked_object_id,
          name: order.object_name,
          order
        },
        items: order,
        object_name: order.object_name
      };
    });
  };

  sortByGroupInfoName = list => {
    const sortedList = this.splitAndSort(list);

    return sortedList;
  };

  splitByDepartment = data => {
    const deparmentIdMap = {};
    const groupByDepartmentMap = data.reduce((byDepartmentMap, item) => {
      const department = item.department;
      if (!department) {
        return null;
      }

      // group by department id
      const departmentId = department.department_id;
      const list = byDepartmentMap[departmentId] || [];
      list.push(item);

      byDepartmentMap[departmentId] = list;

      // register department
      deparmentIdMap[departmentId] = department;

      return byDepartmentMap;
    }, {});

    return Object.keys(groupByDepartmentMap).map(departmentId => {
      const department = deparmentIdMap[departmentId];
      const items = groupByDepartmentMap[departmentId];
      const statusCount = this.getStatusCount(items);

      return {
        groupInfo: {
          id: department.department_id,
          name: department.department_name,
          attributes: {
            count: {
              total: {
                name: 'Total:',
                value: items.length
              },
              missing: {
                name: 'Missing:',
                value: statusCount
              }
            }
          }
        },
        items,
        object_name: department.department_name
      };
    });
  };

  splitByType = data => {
    const groupByTypeMap = data.reduce((typeMap, item) => {
      const typeId = item.object_name.split(' ')[0];
      if (typeId) {
        const list = typeMap[typeId] || [];
        list.push(item);
        typeMap[typeId] = list;
      }
      return typeMap;
    }, {});

    return Object.keys(groupByTypeMap).map((typeId, index) => {
      const items = groupByTypeMap[typeId] || [];

      const statusCount = this.getStatusCount(items);

      return {
        groupInfo: {
          id: typeId || `Unknown-${index}`,
          name: typeId || 'Unknown',
          link: 'link-to',
          attributes: {
            count: {
              total: {
                name: 'Total:',
                value: items.length
              },
              missing: {
                name: 'Missing:',
                value: statusCount
              }
            }
          }
        },
        items,
        object_name: typeId
      };
    });
  };

  getStatusCount(items) {
    let count = 0;
    items.forEach(item => {
      if (item.tags && item.tags.miss_found_status === 'Missing') {
        count++;
      }
    });
    return count;
  }

  getLastUpdatedTime(items) {
    const times = [];
    items.forEach(item => {
      const time = item.received_timestamp || item.published_timestamp;
      times.push(new Date(time));
    });

    return times.length > 0 ? Math.max.apply(null, times) : undefined;
  }

  getPlaceholder() {
    const searchObject = this.state.searchObject ? this.state.searchObject : null;
    let placeholder = 'Search for an asset, asset type or location';

    if (searchObject && searchObject.type) {
      if (searchObject.type === 'Item') {
        placeholder = `Search all ${searchObject.value}s`;
      } else if (searchObject.type === 'Location') {
        placeholder = `Search for an asset in ${this.state.data[0].department.department_name}`;
      }
    }

    return placeholder;
  }

  singleAssetGridCards(filterVal) {
    const { data, currentSort, attribute, filterValue } = this.state;
    const newData = FilterIcon[currentSort[attribute]].filterFunc(data, attribute);
    return (
      <Fragment>
        <div className={Style.grid_view_container}>
          {newData.map(object =>
            object.object_name.toLowerCase().includes(filterValue.toLowerCase()) ? (
              <GridCard
                lastSeenLocation={object.department.department_name}
                tracker_serial={object.tracker_serial}
                filter={filterVal}
                key={object.tracked_object_id}
                object_type_id={object.tracked_object_id}
                name={object.object_name}
                imageUrl={object.image_url}
                isMissing={object.tags ? object.tags.miss_found_status === 'Missing' : false}
                timeLastUpdated={object.lastUpdated}
                expirationDate={object.due_date}
              />
            ) : null
          )}
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      selectedFilter,
      filteredData,
      loading,
      filterValue,
      attribute,
      currentSort
    } = this.state;
    const { filter } = this.props;

    const searchbarPlaceholder = this.getPlaceholder();
    if (loading) {
      return null;
    }
    if (filter) {
      if (!filteredData) {
        return (
          <Fragment>
            {this.filterBar(searchbarPlaceholder)}
            {filter ? (
              <SideFilter
                filter
                selectedFilter={selectedFilter}
                handleSelected={this.handleSelected}
              />
            ) : null}
            {this.singleAssetGridCards(false)}
          </Fragment>
        );
      }
      const newFilteredData = FilterIcon[currentSort[attribute]].filterFunc(
        filteredData,
        attribute
      );
      return (
        <Fragment>
          {this.filterBar(searchbarPlaceholder)}
          {filter ? (
            <SideFilter
              filter
              selectedFilter={selectedFilter}
              handleSelected={this.handleSelected}
            />
          ) : null}
          <div className={Style.grid_view_container}>
            {newFilteredData &&
              newFilteredData.map(object =>
                object.groupInfo.name.toLowerCase().includes(filterValue.toLowerCase()) ? (
                  <GridCard
                    groupBy={selectedFilter}
                    filter={filter}
                    key={object.groupInfo.id}
                    object_type_id={object.groupInfo.id}
                    name={object.groupInfo.name}
                    imageUrl={null}
                    quantity={object.groupInfo.attributes.count}
                    timeLastUpdated={object.lastUpdated}
                  />
                ) : null
              )}
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        {this.filterBar(searchbarPlaceholder)}
        {this.singleAssetGridCards(filter)}
      </Fragment>
    );
  }
}

GridView.propTypes = {
  objects: PropTypes.arrayOf(PropTypes.object).isRequired
};
