import RestService from './RestService';
import ConfigurationService from './ConfigurationService';

export default class OrderTrackService {
  active_orders = {};
  order_delay = 0;
  order_unknown = 0;
  order_onTime = 0;
  constructor(snapData, tagData) {
    const inventoryCount = {};
    this.active_orders = snapData;
    let today = new Date();

    snapData.forEach(each => {
      if (each.due_date && each.due_date < today.getTime()) this.order_delay++;
      else if (each.due_date && each.due_date >= today.getTime()) this.order_onTime++;
      else if (!each.due_date) this.order_unknown++;
      inventoryCount[each.tracked_object_id] = inventoryCount[each.tracked_object_id]
        ? inventoryCount[each.tracked_object_id] + 1
        : 1;
    });

    this.tagMap = {};
    tagData.forEach(tag => {
      this.tagMap[tag.tracker_id] = tag.tracker_serial;
    });

    const config = ConfigurationService.getRestServiceConfiguration();
    this.trackData = {};
    snapData.forEach(object => {
      this.trackData[object.tracked_object_id] = object;
      if (object.image) {
        this.trackData[
          object.tracked_object_id
        ].image_url = `${config.imageBucket.endpoint}/${object.image.image_url}`;
      } else {
        this.trackData[object.tracked_object_id].image_url = null;
      }

      this.trackData[object.tracked_object_id].tracker_serial = this.tagMap[object.tracker_id];
    });
  }

  getWeeklyStatus() {
    const weeklyStatus = {
      weeklyStatsLabel1: 'Orders Completed Weekly',
      weeklyStatsLabel2: 'Orders On-Time',
      weeklyStatsLabel3: 'Orders Delayed',
      weeklyStats1: '-',
      weeklyStats2: this.order_onTime,
      weeklyStats3: this.order_delay
    };

    return weeklyStatus;
  }

  getTrackerMap = () => {
    return this.tagMap;
  };

  getTrackDataList() {
    const objectList = [];

    Object.keys(this.trackData).forEach(eachkey => {
      objectList.push(this.trackData[eachkey]);
    });
    return objectList.sort((a, b) => {
      return a.object_name - b.object_name;
    });
  }

  static async build(searchObject) {
    let snapData = await RestService.get('/tracked-objects/active');
    // filter out orders
    snapData = snapData.filter(object => {
      return object.object_class === 'order';
    });

    const tagData = await RestService.get('/trackers');

    if (searchObject.type && searchObject.type === 'Location') {
      snapData = snapData.filter(object => {
        return object.department.department_id === searchObject.value;
      });
    }

    return new OrderTrackService(snapData, tagData);
  }
}
