import React, { Fragment } from 'react';
import { Card, Input } from 'mdbreact';
import './InventoryRequestComponent.css';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Calendar from 'react-calendar';
import Moment from 'react-moment';
import _ from 'lodash';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import { getFormattedDate } from '../../common/Utilities';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import SummaryTileList from './SummaryTileList';

const borderStyleMap = {
  Arriving: 'border-yellow',
  Arrived: 'border-green',
  New: 'border-blue',
  Backorder: 'border-red'
};

const backgroundStyleMap = {
  Arriving: 'background-yellow',
  Arrived: 'background-green',
  New: 'background-blue',
  Backorder: 'background-red'
};

const summaryTileMap = {
  New: 'New Requests',
  Backorder: 'Backorder',
  Arriving: 'Arriving By...',
  Arrived: 'Arrived'
};

export default class InventoryRequestComponent extends React.Component {
  char_limit = {
    stock_item_name: 95,
    order_number: 34,
    quantity: 4,
    size: 30,
    vendor: 18,
    cost: 15
  };

  constructor(props) {
    super(props);
    this.state = {
      tileData: [],
      checked: [],
      noEta: false,
      checkAll: false,
      dataList: this.props.dataList,
      filteredList: [],
      displayList: [],
      menu: false,
      selectedDate: null,
      statusList: [],
      showDeleteModal: false,
      enableEdit: false,
      editRequestsObject: {},
      row: null,
      col: null,
      colToSort: '',
      isSortAscending: true,
      searchQuery: '',
      statusFilteredList: [],
      defaultTab: this.props.defaultTab
    };
    this.handleQueryChange = this.handleQueryChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { searchQuery } = this.state;
    const list = this.buildFilteredData(
      newProps.selectedVendor,
      newProps.dataList,
      newProps.vendorCount
    );
    list.sort(function(a, b) {
      return b.restock_requested_timestamp - a.restock_requested_timestamp;
    });
    this.setState({
      tileData: this.buildTileData(
        newProps.selectedVendor,
        newProps.dataList,
        newProps.vendorCount
      ),
      dataList: this.props.dataList,
      filteredList: list,
      displayList: list,
      checked: [],
      checkAll: false,
      defaultTab: newProps.defaultTab
    });
    if (searchQuery) {
      this.handleQueryChange();
    }
  }

  componentDidMount() {
    const { selectedVendor, dataList, vendorCount } = this.props;
    const { searchQuery } = this.state;
    const list = this.buildFilteredData(selectedVendor, dataList, vendorCount);
    list.sort(function(a, b) {
      return b.restock_requested_timestamp - a.restock_requested_timestamp;
    });
    this.setState({
      tileData: this.buildTileData(selectedVendor, dataList, vendorCount),
      dataList,
      filteredList: list,
      displayList: list
    });
    if (searchQuery) {
      this.handleQueryChange();
    }
  }

  buildFilteredData = (filterList, dataList, vendorCount) => {
    let newFilteredList = [];

    if (filterList.length === 0) {
      return [];
    }
    if (filterList.length === vendorCount) {
      newFilteredList = _.cloneDeep(dataList);
    } else {
      dataList.forEach(each => {
        filterList.forEach(eachVendor => {
          if (each.vendor === eachVendor) {
            newFilteredList.push(each);
          }
        });
      });
    }
    this.handleQueryChange();
    return newFilteredList;
  };

  buildTileData = (filterList, dataList, vendorCount) => {
    let newFilteredList = [];
    if (filterList.length === 0) {
      return [];
    }
    if (filterList.length === vendorCount) {
      newFilteredList = _.cloneDeep(dataList);
    } else {
      dataList.forEach(each => {
        filterList.forEach(eachVendor => {
          if (each.vendor === eachVendor) {
            newFilteredList.push(each);
          }
        });
      });
    }

    const tempData = {};
    newFilteredList.forEach(each => {
      if (tempData[each.status]) {
        tempData[each.status].count += 1;
      } else {
        tempData[each.status] = { status: each.status, count: 1 };
      }
    });
    const summaryTileData = [];
    Object.keys(summaryTileMap).forEach(each => {
      Object.keys(tempData).forEach(eachKey => {
        if (each === eachKey) {
          summaryTileData.push({
            title: summaryTileMap[each],
            totalOrders: tempData[eachKey].count,
            status: tempData[eachKey].status
          });
        }
      });
      if (
        summaryTileData.length <= 0 ||
        summaryTileData[summaryTileData.length - 1].status !== each
      ) {
        summaryTileData.push({ title: summaryTileMap[each], totalOrders: 0, status: each });
      }
    });
    return summaryTileData;
  };

  handleToggleSelectAll = () => {
    const { checkAll } = this.state;
    const newChecked = [];
    if (!checkAll) {
      this.state.displayList.forEach(each => {
        newChecked.push(each.restock_request_id);
      });
    }
    this.setState({ checked: newChecked, checkAll: !checkAll });
  };

  handleToggle = value => () => {
    const { checked, editRequestsObject } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      delete editRequestsObject[value];
    }

    if (newChecked.length === 0) {
      this.setState({ enableEdit: false, editRequestsObject: {} });
    }

    this.setState({
      checked: newChecked,
      checkAll: newChecked.length === this.state.filteredList.length
    });
  };

  deselectRequests = (filteredList = null) => {
    const { displayList, checked, editRequestsObject } = this.state;
    const localDisplayList = filteredList || displayList;
    const newChecked = checked;
    for (let i = 0; i < checked.length; i++) {
      const currentRestockid = checked[i];
      const found = localDisplayList.findIndex(eachObj => {
        return eachObj.restock_request_id === currentRestockid;
      });

      if (found === -1) {
        newChecked.splice(i, 1);
        i--;
      }
    }
    const keys = Object.keys(editRequestsObject);
    if (keys.length) {
      for (const key of keys) {
        if (newChecked.indexOf(key) === -1) {
          delete editRequestsObject[key];
        }
      }
    }
    this.setState({
      checked: newChecked,
      editRequestsObject
    });
  };

  filterFunction = statusList => {
    let newDataList = [];
    const { filteredList, colToSort, checked, searchQuery } = this.state;
    let { checkAll } = this.state;
    if (statusList !== ['New']) {
      this.setState({
        defaultTab: null
      });
    }
    if (statusList.length === 0) {
      newDataList = [];
      this.setState({
        checkAll: false
      });
    } else if (statusList.length === 5) {
      newDataList = filteredList;
    } else {
      filteredList.forEach(each => {
        statusList.forEach(eachStatus => {
          if (each.status === eachStatus) {
            newDataList.push(each);
          }
        });
      });
    }
    this.deselectRequests(newDataList);
    if (newDataList.length !== checked.length || checked.length === 0) {
      checkAll = false;
    }
    this.setState({
      statusList,
      displayList: newDataList,
      statusFilteredList: newDataList,
      checkAll
    });
    if (colToSort) this.handleSort(colToSort, newDataList, true);
    if (searchQuery) this.handleQueryChange(null, newDataList);
  };

  renderCheckBox = value => {
    return (
      <div className="checkbox-outer">
        <div
          className={
            this.state.checked.indexOf(value) !== -1 ? 'checkbox-checked' : 'checkbox-unchecked'
          }
        />
      </div>
    );
  };

  handleQueryChange = (e, statusFilteredList = null) => {
    const searchQuery = e ? e.target.value : this.state.searchQuery;
    let searchFilteredList = [];
    this.setState({
      searchQuery
    });
    const listToFilter = statusFilteredList || this.state.statusFilteredList;
    const cols = [
      'item_name',
      'order_number',
      'size',
      'vendor',
      'by',
      'status',
      'comment',
      'quantity',
      'cost',
      'restock_requested_timestamp'
    ];

    const { checked, colToSort } = this.state;
    if (searchQuery) {
      searchFilteredList = listToFilter.filter(function(each) {
        for (let i = 0; i < cols.length; i++) {
          if (cols[i] === 'restock_requested_timestamp') {
            if (
              each[cols[i]] &&
              getFormattedDate(each[cols[i]], 'MMM DD, YYYY')
                .toString()
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            ) {
              return true;
            }
          }
          if (
            each[cols[i]] &&
            each[cols[i]]
              .toString()
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
      this.deselectRequests(searchFilteredList);
      this.setState({
        displayList: [...searchFilteredList]
      });
      this.handleSort(colToSort, searchFilteredList, true);
    } else {
      this.setState({
        displayList: [...listToFilter]
      });
      this.handleSort(colToSort, listToFilter, true);
    }
    if (searchFilteredList.length !== checked.length || checked.length === 0) {
      this.setState({
        checkAll: false
      });
    }
  };

  compareValues = (colName, isSortAscending = this.state.isSortAscending) => {
    let sortDir = isSortAscending ? 1 : -1;
    if (colName === 'status') sortDir *= -1;
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(colName) || !b.hasOwnProperty(colName)) {
        return 0;
      }
      let varA = typeof a[colName] === 'string' ? a[colName].toUpperCase() : a[colName];
      let varB = typeof b[colName] === 'string' ? b[colName].toUpperCase() : b[colName];
      switch (colName) {
        case 'cost':
          if (!varA) return -sortDir;
          if (!varB) return sortDir;
          break;
        case 'size':
          // regex removes all non digit characters from the string
          if (varA) {
            varA =
              varA.replace(/[^0-9.]/gi, '') === ''
                ? -Infinity
                : Number(varA.replace(/[^0-9.]/gi, ''));
          } else return sortDir;
          if (varB) {
            varB =
              varB.replace(/[^0-9.]/gi, '') === ''
                ? -Infinity
                : Number(varB.replace(/[^0-9.]/gi, ''));
          } else return -sortDir;
          break;
        default:
          if (varA == null || varA === '') {
            return sortDir;
          }
          if (varB == null || varB === '') {
            return -sortDir;
          }
          break;
      }

      if (varA > varB) {
        return sortDir;
      }
      if (varA < varB) {
        return -sortDir;
      }
      return 0;
    };
  };

  handleSort = (colName, listToSort = null, maintainSortforSearch = null) => {
    const { colToSort, isSortAscending, displayList } = this.state;
    const localListToSort = listToSort || displayList;
    const localsortDir =  maintainSortforSearch ? isSortAscending : (colToSort === colName ? !isSortAscending : true);
    const sortedList = localListToSort.sort(this.compareValues(colName, localsortDir));

    this.setState({
      displayList: sortedList,
      isSortAscending: localsortDir,
      colToSort: colName
    });
  };

  returnLabelDiv = (className, id, labelName) => {
    const { colToSort, isSortAscending } = this.state;
    return (
      <div className={className} id={id} onClick={e => this.handleSort(e.currentTarget.id)}>
        {labelName}
        {colToSort === id && (
          isSortAscending ? (
            <ArrowUp className="arrowup" style={{ fontSize: 16 }} />
          ) : (
            <ArrowDown className="arrowdown" style={{ fontSize: 16 }} />
          )
        ) }
      </div>
    );
  };

  renderListLabel = () => {
    return (
      <div className="list-item-title">
        <div className="item-small" onClick={this.handleToggleSelectAll}>
          <div className="checkbox-outer">
            <div className={this.state.checkAll ? 'checkbox-checked' : 'checkbox-unchecked'} />
          </div>
        </div>
        {this.returnLabelDiv('item-large-name item-label', 'item_name', 'Item Name')}
        {this.returnLabelDiv('item-medium item-label', 'order_number', 'Order #')}
        {this.returnLabelDiv('item-small item-label', 'quantity', 'Qty.')}
        {this.returnLabelDiv('item-medium item-label', 'size', 'Size')}
        {this.returnLabelDiv('item-medium item-label', 'vendor', 'Vendor')}
        {this.hasOptionalColumnHeader('Cost')
          ? this.returnLabelDiv('item-medium item-label', 'cost', 'Cost')
          : null}
        {this.returnLabelDiv(
          'item-label restock_requested_timestamp',
          'restock_requested_timestamp',
          'Request Date'
        )}
        {this.returnLabelDiv('item-large-name item-label', 'by', 'By')}
        {this.returnLabelDiv('item-large-name item-label', 'status', 'Status')}
        {this.returnLabelDiv('item-large item-label', 'comment', 'Comments')}
      </div>
    );
  };

  handleChangeStatus = event => {
    this.setState({ actionClicked: event.target.id });
  };

  handleSubmitStatus = async () => {
    const { selectedDate, actionClicked, checked, noEta, searchQuery } = this.state;
    const { updateStatus } = this.props;
    if (selectedDate || noEta) {
      await updateStatus(actionClicked, selectedDate || 'No ETA', checked);
      this.setState({
        checked: [],
        actionClicked: null,
        selectedDate: null,
        menu: false,
        checkAll: false,
        noEta: false
      });
    }
    if (searchQuery) this.handleQueryChange();
  };

  handleDeleteClick = () => {
    // Information to be displayed
    const { displayList, checked } = this.state;
    const { deleteRequests } = this.props;
    const arrangedDisplayInfo = {};
    let counter = 1;
    displayList.forEach(displayItem => {
      checked.forEach(value => {
        if (displayItem.restock_request_id === value) {
          arrangedDisplayInfo[`Item ${counter}`] = displayItem.item_name;
          counter++;
        }
      });
    });

    // rowData
    const data = {
      display_info: arrangedDisplayInfo,
      delete_info: checked,
      confirm_function: async args => {
        await deleteRequests(args);
        this.handleQueryChange();
      }
    };

    this.setState({ showDeleteModal: true, rowData: data });
  };

  showDeleteModal = () => {
    // To display the confirmation message
    const { rowData } = this.state;
    return (
      <ConfirmDeleteModal
        rowData={rowData}
        headerName="Request"
        cancel_function={this.cancelDeleteModal}
      />
    );
  };

  cancelDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  toggleMenu = event => {
    if (event.target.id.length === 0) {
      this.setState({ actionClicked: null, selectedDate: null, menu: false });
    } else if (event.target.id === 'menu') {
      this.setState({
        actionClicked: null,
        selectedDate: new Date(),
        menu: !this.state.menu,
        noEta: false
      });
    } else {
      this.setState({ menu: true });
    }
  };

  toggleComments = async event => {
    const { updatedComment, commentMenuId, searchQuery } = this.state;
    if (event.target.id.length === 0) {
      if (updatedComment !== null) {
        await this.props.updateComment(updatedComment, commentMenuId);
      }
      this.setState({ commentMenuId: null, updatedComment: null });
    } else {
      if (commentMenuId && event.target.id !== commentMenuId) {
        if (updatedComment !== null) {
          await this.props.updateComment(updatedComment, commentMenuId);
        }
      }
      this.setState({ updatedComment: null, commentMenuId: event.target.id });
    }
    if (searchQuery) this.handleQueryChange();
  };

  changeDate = date => {
    this.setState({
      selectedDate: date,
      noEta: null
    });
  };

  markAsNoETA = event => {
    this.setState({
      selectedDate: null,
      noEta: event.target.checked
    });
  };

  cancelAll = () => {
    this.setState({ checked: [], checkAll: false, editRequestsObject: {} });
  };

  handleEditClick = () => {
    this.setState({
      enableEdit: true
    });
  };

  cancelEdit = () => {
    this.setState({
      enableEdit: false,
      editRequestsObject: {}
    });
  };

  /* Saves the changes made after editing the request */
  saveEdit = async () => {
    const { editRequestsObject, searchQuery } = this.state;
    await this.props.editRequests(editRequestsObject);
    this.setState({
      enableEdit: false,
      editRequestsObject: {},
      checked: []
    });
    if (searchQuery) this.handleQueryChange();
  };

  renderEditActionBar = () => {
    if (this.state.checked.length > 0) {
      return (
        <Card className="action-bar">
          <div className="action-large delete-request" onClick={this.cancelEdit}>
            Cancel
          </div>
          <div className="action-large change-status" onClick={this.saveEdit}>
            Save
          </div>
        </Card>
      );
    }
  };

  renderActionBar = () => {
    const { checked, menu, actionClicked, selectedDate, showDeleteModal, noEta } = this.state;
    if (checked.length > 0) {
      return (
        <Card className="action-bar">
          <div className="action-xlarge bold close" onClick={this.cancelAll}>
            x
          </div>
          <div className="action-small number">{checked.length}</div>
          <div className="action-small">requests selected</div>
          <Dropdown direction="up" isOpen={menu} toggle={this.toggleMenu}>
            <DropdownToggle id="menu" tag="span" caret className="change-status action-large">
              Change Status
            </DropdownToggle>
            <DropdownMenu modifiers={null}>
              {actionClicked ? (
                <Fragment>
                  <Calendar onChange={this.changeDate} value={selectedDate} className="calendar" />
                  <div className="calendar-button-group">
                    <div className="calendar-button cancel" onClick={this.toggleMenu}>
                      Cancel
                    </div>
                    {actionClicked === 'Backorder' && (
                      <div className="no_eta_container">
                        <input
                          className="no_eta_checkbox"
                          type="checkbox"
                          checked={noEta}
                          onChange={this.markAsNoETA}
                        />
                        <label className="no_eta_label"> No ETA </label>
                      </div>
                    )}
                    <div
                      className={
                        selectedDate || noEta
                          ? 'calendar-button submit'
                          : 'calendar-button submit-disabled'
                      }
                      onClick={this.handleSubmitStatus}
                    >
                      Submit
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <DropdownItem id="Backorder" onClick={this.handleChangeStatus}>
                    Backorder
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem id="Arriving" onClick={this.handleChangeStatus}>
                    Arriving
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem id="Arrived" onClick={this.handleChangeStatus}>
                    Arrived
                  </DropdownItem>
                </Fragment>
              )}
            </DropdownMenu>
          </Dropdown>
          <div className="action-large edit-request" onClick={this.handleEditClick}>
            Edit Request
          </div>
          <div className="action-large delete-request" onClick={this.handleDeleteClick}>
            Delete Request
          </div>
          {showDeleteModal && this.showDeleteModal()}
        </Card>
      );
    }
  };

  showComments = comments => {
    if (comments && comments.length > 20) {
      return `${comments.substring(0, 20)}...`;
    }
    return comments;
  };

  handleCommentChange = event => {
    this.setState({ updatedComment: event.target.value });
  };

  handleEditChange = (id, value, restockId) => {
    const { editRequestsObject } = this.state;
    if (!editRequestsObject[restockId]) {
      editRequestsObject[restockId] = {};
    }
    editRequestsObject[restockId][id] = value;
    this.setState({ editRequestsObject });
  };

  renderEditInput = (attrName, hint, restockId) => {
    return (
      <Input
        disabled={!this.state.enableEdit}
        value={this.state[attrName]}
        hint={hint}
        className="input-text"
        id={attrName}
        onInput={event => {
          attrName === 'quantity' || attrName === 'cost'
            ? this.handleEditChange(event.target.id, Number(event.target.value), restockId)
            : this.handleEditChange(event.target.id, event.target.value, restockId);
        }}
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderRadius: 5,
          fontSize: 12,
          textAlign: 'left',
          padding: 2
        }}
      />
    );
  };

  canEditRequest = value => {
    const { enableEdit, checked } = this.state;
    const isChecked = checked.indexOf(value);
    return isChecked !== -1 && enableEdit;
  };

  hasOptionalColumnHeader = columnHeader => {
    const { optionalColumnHeader } = this.props;
    return optionalColumnHeader && optionalColumnHeader.indexOf(columnHeader) !== -1;
  };

  /*
Changing the state after click
  */
  switchState = (tuple, attribute) => {
    this.setState({
      show: !this.state.show,
      row: tuple,
      col: attribute
    });
  };

  /*
  after click, show full box of text
  */
  display = (tuple_id, field, data_display) => {
    if (this.state.show && this.state.row === tuple_id && this.state.col === field) {
      return <div className="expand-box">{data_display} </div>;
    }
  };

  click = (button, sty, tuple, attr, result) => {
    return (
      <div onClick={() => this.switchState(tuple, attr)}>
        {result}
        <nobr className={sty}> {button}</nobr>
      </div>
    );
  };

  getStatusDate = date => {
    if (date === 'No ETA') {
      return 'No ETA';
    }
    return getFormattedDate(date, 'MMM DD, YYYY');
  };

  fullExpand = (name, tuple, attribute, limit) => {
    let more_button = 'more';
    let close_button = 'Close';
    let result = '';
    /*
Quantity attribute is too small to show the "See more", so replace
with ".." instead
    */
    if (name && attribute && attribute === 'quantity') {
      name = name.toString();
      more_button = '..';
      close_button = 'X';
      result = name.substring(0, limit);
    } else if (name) {
      result = name.substring(0, limit).concat('...');
    }
    /*
Create button to expand the text if its too long
    */
    if (name && name.length > limit) {
      return !this.state.show || this.state.row !== tuple || this.state.col !== attribute
        ? this.click(more_button, 'moreButton', tuple, attribute, result)
        : this.click(close_button, 'closeButton', tuple, attribute, result);
    }
    return name;
  };

  edit = (canEdit, string_name, tuple_id, data_display, char_limit) => {
    if (canEdit) {
      return this.renderEditInput(string_name, data_display, tuple_id);
    }
    return this.fullExpand(data_display, tuple_id, string_name, char_limit);
  };

  render() {
    const { selectedVendor } = this.props;
    const {
      tileData,
      displayList,
      checked,
      commentMenuId,
      enableEdit,
      searchQuery,
      defaultTab
    } = this.state;
    return (
      <Fragment>
        <div className="title-inventory">Inventory Request</div>
        <SummaryTileList
          defaultTab={defaultTab}
          tileData={tileData}
          filterFunction={this.filterFunction}
          selectedVendor={selectedVendor}
        />
        <Card className="list-card">
          <div className="inventory-search-box">
            <Input
              type="search"
              value={searchQuery}
              label="Search Inventory Requests..."
              onChange={this.handleQueryChange}
            />
          </div>
          {this.renderListLabel()}

          {displayList.map(value => {
            const canEdit = this.canEditRequest(value.restock_request_id);
            return (
              <Card
                className={`list-item ${borderStyleMap[value.status]} ${
                  checked.indexOf(value.restock_request_id) !== -1 ? 'list-item-checked' : ''
                }`}
                key={value.restock_request_id}
              >
                <div className="item-small" onClick={this.handleToggle(value.restock_request_id)}>
                  {this.renderCheckBox(value.restock_request_id)}
                </div>

                <div
                  id={value.restock_request_id}
                  className={canEdit ? 'edit-box-large' : 'item-large-name'}
                >
                  {this.edit(
                    canEdit,
                    'stock_item_name',
                    value.restock_request_id,
                    value.item_name,
                    this.char_limit.stock_item_name
                  )}
                  {this.display(value.restock_request_id, 'stock_item_name', value.item_name)}
                </div>

                <div className={canEdit ? 'edit-box-medium' : 'item-medium'}>
                  {this.edit(
                    canEdit,
                    'order_number',
                    value.restock_request_id,
                    value.order_number,
                    this.char_limit.order_number
                  )}
                  {this.display(value.restock_request_id, 'order_number', value.order_number)}
                </div>

                <div className={canEdit ? 'edit-box-small' : 'item-small'}>
                  {this.edit(
                    canEdit,
                    'quantity',
                    value.restock_request_id,
                    value.quantity,
                    this.char_limit.quantity
                  )}
                  {this.display(value.restock_request_id, 'quantity', value.quantity)}
                </div>

                <div className={canEdit ? 'edit-box-small' : 'item-medium'}>
                  {this.edit(
                    canEdit,
                    'size',
                    value.restock_request_id,
                    value.size,
                    this.char_limit.size
                  )}
                  {this.display(value.restock_request_id, 'size', value.size)}
                </div>

                <div className={canEdit ? 'edit-box-medium' : 'item-medium'}>
                  {this.edit(
                    canEdit,
                    'vendor',
                    value.restock_request_id,
                    value.vendor,
                    this.char_limit.vendor
                  )}
                  {this.display(value.restock_request_id, 'vendor', value.vendor)}
                </div>

                {this.hasOptionalColumnHeader('Cost') ? (
                  <div className={canEdit ? 'edit-box-medium' : 'item-medium'}>
                    {this.edit(
                      canEdit,
                      'cost',
                      value.restock_request_id,
                      `$${Number(value.cost).toFixed(2)}`,
                      this.char_limit.cost
                    )}
                    {this.display(
                      value.restock_request_id,
                      'cost',
                      `$${Number(value.cost).toFixed(2)}`
                    )}
                  </div>
                ) : null}
                <div className="item-medium">
                  <Moment format="MMM DD, YYYY">{value.restock_requested_timestamp}</Moment>
                </div>
                <div className="item-large-name">{value.by}</div>
                <div className="item-large-name">
                  <div className={'status '.concat(backgroundStyleMap[value.status])}>
                    <div>{value.status}</div>
                    <div>
                      {value.status !== 'New' && (
                        <p> {this.getStatusDate(value.restock_last_updated_timestamp)} </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="item-large comments-group">
                  <Dropdown
                    direction="up"
                    isOpen={commentMenuId === value.restock_request_id}
                    toggle={this.toggleComments}
                  >
                    <DropdownToggle
                      id={value.restock_request_id}
                      tag="span"
                      className="comments"
                      value={value.comment}
                    >
                      {this.showComments(value.comment)}
                    </DropdownToggle>
                    <DropdownMenu className="menu-box" modifiers={null}>
                      <textarea
                        autoFocus
                        rows="8"
                        defaultValue={value.comment}
                        className="input-field"
                        onChange={this.handleCommentChange}
                      />
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Card>
            );
          })}
          {enableEdit ? this.renderEditActionBar() : this.renderActionBar()}
        </Card>
      </Fragment>
    );
  }
}
