import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ReportsPage from './pages/report/ReportsPage';
import TrackPage from './pages/track/TrackPage';
import HomePage from './pages/home/HomePage';
import DepartmentThresholdPage from './pages/threshold/DepartmentThresholdPage';
import UsersPage from './pages/users/UsersPage';
import CreateUserPage from './pages/users/CreateUser';
import UserProfilePage from './pages/users/UserProfilePage';
import ManageTrackers from './pages/manage-infrastructure/ManageTrackers';
import ConfigurationPage from './pages/manage-infrastructure/Configuration';
import ObjectDetails from './pages/tracked-object/ObjectDetails';
import LoginPage from './pages/login/LoginPage';
import AlertsPage from './pages/alert/AlertsPage';
import InventoryRequestsPage from './pages/inventory-requests/InventoryRequestsPage';
import AddNewTrackedObject from './pages/track/AddNewTrackedObject';

import AuthorizedProcessAppRoute from './route/AuthorizedProcessAppRoute';
import { UnauthorizedPage } from './pages/error/UnauthorizedPage';

const defaultRoute = () => {
  return <h1>Not Found</h1>;
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route path="/error/authorized" component={UnauthorizedPage} />
      <AuthorizedProcessAppRoute exact path="/" component={HomePage} hide_header_searchBar />
      <AuthorizedProcessAppRoute path="/alerts" component={AlertsPage} />
      <AuthorizedProcessAppRoute exact path="/reports" component={ReportsPage} />
      <AuthorizedProcessAppRoute exact path="/threshold" component={DepartmentThresholdPage} />
      <AuthorizedProcessAppRoute path="/tracked-objects" component={TrackPage} />
      <AuthorizedProcessAppRoute path="/tracked-object/details" component={ObjectDetails} />
      <AuthorizedProcessAppRoute path="/tracked-object/add" component={AddNewTrackedObject} />
      <AuthorizedProcessAppRoute
        exact
        path="/users/create"
        component={CreateUserPage}
        authorizedRoles={['SuperAdmin']}
      />
      <AuthorizedProcessAppRoute
        exact
        path="/users"
        component={UsersPage}
        authorizedRoles={['SuperAdmin']}
      />
      <AuthorizedProcessAppRoute exact path="/userProfile" component={UserProfilePage} />
      <AuthorizedProcessAppRoute path="/manage-infrastructure" component={ConfigurationPage} />
      <AuthorizedProcessAppRoute exact path="/manage-tags" component={ManageTrackers} />
      <AuthorizedProcessAppRoute path="/inventory-requests" component={InventoryRequestsPage} />

      <Route render={defaultRoute} />
    </Switch>
  );
};

export default Routes;
