import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import './css/ProgressBar.css';

/*
 * ProgressBar takes props from GridCard, calculates percentage variables,
 * converts into inline styling variables, and displays dynamic
 * progress bar for each object.
 */
export default class ProgressBar extends PureComponent {
  render() {
    const { min, max, value, healthColor } = this.props;

    let actualMax;
    if (max) actualMax = max;
    else if (!max && value > min) actualMax = Math.ceil(value * 1.1);
    else if (!max && value <= min) actualMax = Math.ceil(min * 1.1);

    let barPercentage = (value / actualMax) * 100;
    if (barPercentage === 100) barPercentage = 96.5;
    else if (barPercentage > 100) barPercentage = 100;
    else if (barPercentage < 4 || !barPercentage) barPercentage = 4;

    let minPercentage = (min / actualMax) * 100;
    if (minPercentage > 75 && max) minPercentage = 75;
    else if (!minPercentage) minPercentage = 3;
    else if (minPercentage < 10) minPercentage = 10;

    let barWidthAndColor = {
      width: `${barPercentage}%`,
      background: healthColor,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px'
    };
    if (barPercentage === 100) {
      barWidthAndColor = {
        width: `${barPercentage}%`,
        background: healthColor,
        borderRadius: '10px'
      };
    }

    let currentStockMark = {
      left: `${barPercentage - 1.2}%`
    };
    if (barPercentage >= 96.5) {
      currentStockMark = {
        left: '95%'
      };
    } else if (barPercentage < 3 || !barPercentage) {
      currentStockMark = {
        left: '3%'
      };
    }

    const currentStockText = {
      width: `${barPercentage || 3}%`
    };

    const lowStockMark = {
      left: `${minPercentage - 1.3}%`
    };

    return (
      <Fragment>
        <div className="bar-container">
          <p style={currentStockText} data-value={value} className="current-marker" />
          <div className="current-stock-mark" style={currentStockMark} />
          <div className="box">
            <div className="fill" style={barWidthAndColor} />
          </div>
          <div className="tick-mark">
            <p className="marker-zero bolder">0</p>
          </div>
          {!max ? null : (
            <div className="tick-mark show-overstock">
              <p className="marker-number bolder">{max}</p>
              <p className="marker-text bolder">Overstock</p>
            </div>
          )}
          {minPercentage <= 3 ? null : (
            <div className="tick-mark" style={lowStockMark}>
              <p className="marker-number bolder">{min}</p>
              <p className="marker-text bolder">Low Stock</p>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

ProgressBar.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  healthColor: PropTypes.string.isRequired
};

ProgressBar.defaultProps = {
  max: undefined
};
