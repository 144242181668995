import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Style from './css/GridCard.module.css';
import { getFormattedDate, getCustomerLogoSmall } from '../../../common/Utilities';

export default class GridCard extends PureComponent {
  constructor(props) {
    super(props);

    const {
      name,
      object_type_id,
      timeLastUpdated,
      quantity,
      filter,
      lastSeenLocation,
      tracker_serial,
      groupBy,
      isMissing,
      imageUrl,
      expirationDate
    } = this.props;
    this.state = {
      src: imageUrl === null ? getCustomerLogoSmall() : imageUrl,
      errored: false,
      objectTypeId: object_type_id,
      name,
      healthColor: '',
      timeLastUpdated,
      quantity,
      filter,
      lastSeenLocation,
      tracker_serial,
      groupBy,
      isMissing,
      expirationDate
    };
  }

  onError = () => {
    const { errored } = this.state;
    if (!errored) {
      this.setState({
        errored: true
      });
    }
  };

  componentDidMount() {
    const { isMissing, quantity, filter, expirationDate } = this.props;
    this.setState({
      healthColor: this.getHealthColor(expirationDate, isMissing, quantity, filter)
    });
  }

  getHealthColor(expirationDate, isMissing, quantity, filter) {
    const healthColorMap = {
      red: '#FF3D46',
      yellow: '#F6B045',
      green: '#62D32E',
      gray: '#C6CBD4',
      orange: '#F74446'
    };
    if (isMissing || (quantity && quantity.missing.value > 0)) {
      return healthColorMap.orange;
    }
    if (!filter) {
      const date = new Date();
      const timeToExpiration = expirationDate - date.getTime();
      if (!expirationDate) {
        return healthColorMap.gray;
      }
      if (timeToExpiration <= 0) {
        return healthColorMap.red;
      }
      if (timeToExpiration <= 604800000) {
        return healthColorMap.yellow;
      }
    }
    return healthColorMap.green;
  }

  render() {
    const {
      name,
      healthColor,
      timeLastUpdated,
      quantity,
      filter,
      lastSeenLocation,
      tracker_serial,
      groupBy,
      objectTypeId,
      isMissing,
      expirationDate,
      src
    } = this.state;
    const time = getFormattedDate(timeLastUpdated, 'hh:mm A');
    const date = getFormattedDate(timeLastUpdated, 'MMM Do');
    const expDate = expirationDate ? getFormattedDate(expirationDate, 'll') : '-';

    const borderColor = { borderTop: `15px solid ${healthColor}` };
    if (filter) {
      const total = quantity.total.value;
      const missing = quantity.missing.value;
      return (
        <a
          style={borderColor}
          className={`${Style.card_container}`}
          href={`${groupBy === 'Type' ? `?item=${objectTypeId}` : `?location=${objectTypeId}`}`}
        >
          <div className={`${Style.top}`}>
            <img src={src} alt="Failed to load" onError={this.onError} />

            <div className={`${Style.details}`}>
              <p className={`${Style.name}`}>{name}</p>

              <div className={`${Style.kpiContainer}`}>
                <div className={`${Style.flex_direction_row}`}>
                  <p className={`${Style.label}`}>Total: </p>
                  <p className={`${Style.count}`}>{total}</p>
                </div>

                <div className={`${Style.missing} ${Style.flex_direction_row}`}>
                  <p className={`${Style.label}`}>Missing: </p>
                  <p className={`${Style.count} ${missing > 0 ? Style.red_font : ''}`}>{missing}</p>
                </div>
              </div>
            </div>
          </div>
          <hr className={`${Style.horizontal_line2}`} />
          <div className={`${Style.bottom}`}>
            <div className={`${Style.lastupdated_overall}`}>
              <p className={`${Style.bottom_title}`}>Last Updated:</p>
              <p className={`${Style.bottom_data}`}>{`  ${time} - ${date}`}</p>
            </div>
          </div>
        </a>
      );
    }
    return (
      <Fragment>
        <a
          style={borderColor}
          className={`${Style.card_container} ${Style.asset_container}`}
          href={`tracked-object/details?type=order&item=${objectTypeId}`}
        >
          <div className={`${Style.top}`}>
            <img
              className={`${Style.card_image}`}
              src={src}
              alt="Failed to load"
              onError={this.onError}
            />
            <div className={`${Style.details}`}>
              <p className={`${Style.name}`}>{name}</p>

              <div className={`${Style.kpiContainer}`}>
                <div className={`${Style.active} ${Style.flex_direction_row}`}>
                  <p className={`${Style.label} ${Style.min_width_label}`}>Tag:</p>
                  <p className={`${Style.tracker_serial}`}>
                    ...
                    {tracker_serial.substring(tracker_serial.length - 5, tracker_serial.length)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {isMissing ? (
            <Fragment>
              <p className={`${Style.asset_status}`}>MISSING</p>
              <hr className={`${Style.horizontal_line}`} />
            </Fragment>
          ) : (
            <hr className={`${Style.horizontal_line2}`} />
          )}
          <div className={`${Style.bottom} ${Style.flex_direction_row}`}>
            <div className={`${Style.bottom_left}`}>
              <p className={`${Style.bottom_title}`}>Last Seen</p>
              <p className={`${Style.bottom_data}`}>{lastSeenLocation}</p>
            </div>
            <div className={`${Style.bottom_center}`}>
              <p className={`${Style.bottom_title}`}>Exp. Date</p>
              <p className={`${Style.bottom_data}`}>{expDate}</p>
            </div>
            <div className={`${Style.bottom_right}`}>
              <p className={`${Style.bottom_title}`}>Last Updated</p>
              <div className={`${Style.flex_direction_row}`}>
                <p className={`${Style.bottom_data}`}>{`  ${time} - ${date} `}</p>
              </div>
            </div>
          </div>
        </a>
      </Fragment>
    );
  }
}

GridCard.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  object_type_id: PropTypes.string,
  timeLastUpdated: PropTypes.number.isRequired,
  quantity: PropTypes.object,
  filter: PropTypes.bool,
  lastSeenLocation: PropTypes.string,
  tracker_serial: PropTypes.string,
  groupBy: PropTypes.string,
  isMissing: PropTypes.bool
};

GridCard.defaultProps = {
  imageUrl: null,
  quantity: null
};
