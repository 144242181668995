import React, { Component } from 'react';

export default class MissingRuleContent extends Component {
  render() {
    return (
      <div>
        <div>
          <div>
            <p className="tabbedSectionComponent-contentTitle">Missing</p>
            <p style={{ color: '#343434' }}>
              When an asset is marked as missing, a notification will be sent.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
