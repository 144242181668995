import React, { Component, Fragment } from 'react';
import DetailsCard from 'components/DetailsCard';
import TrackedObjectService from '../../services/TrackedObjectService';
import { getCustomerLogo, getFormattedDate } from '../../common/Utilities';
import '../order/TrackOrderPage.css';
import Switch from 'react-switch';
import Moment from 'react-moment';
import ConfigurationService from '../../services/ConfigurationService';
import TrackObjectNotFoundPage from '../error/TrackObjectNotFoundPage';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import AssetTrackService from '../../services/AssetTrackService';
import queryString from 'query-string';
import detailsUtils from './DetailsUtils';
import ConfirmationModal from 'components/ConfirmationModal';
import RestService from '../../services/RestService';

export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackedObjectId: this.props.search,
      info: undefined,
      loading: true,
      validOrder: false,
      doneFetching: false,
      image_info: undefined,
      overallInformation: [],
      metaInformation: null,
      expandedRows: [],
      imageSuccess: false,
      enableEdit: false,
      editPayload: {},
      showDeleteModal: false,
      deleteClicked: false,
      deleteSuccess: false
    };
  }

  componentDidMount() {
    this.collectData();
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.search !== newProps.search) {
      await this.setState({
        trackedObjectId: newProps.search
      });
      this.collectData();
    }
  }

  collectData = async () => {
    try {
      // get Route for object
      let info;
      let missing;
      let imageInfo;
      info = await TrackedObjectService.build(this.state.trackedObjectId).then(routeData => {
        return routeData;
      });

      // get Asset image
      imageInfo = getCustomerLogo();
      if (info.image) {
        const customerConfig = ConfigurationService.getFullConfigurations();
        imageInfo = `${customerConfig.s3.URL}/${info.image.image_url}`;
      }

      missing = info.tags ? info.tags.miss_found_status === 'Missing' : false;

      info.order_route.reverse().slice(0, 10);
      this.setState({
        info,
        validOrder: true,
        missing,
        image_info: imageInfo
      });

      // set Order Card state
      this.setOrderState();

      this.setState({
        doneFetching: true
      });
    } catch (err) {
      console.log(err);
      this.setState({
        error: {
          status: err.response.data.errorType,
          message: err.response.data.message
        },
        doneFetching: true,
        validOrder: false
      });
    }
  };

  setOrderState = () => {
    const { info, image_info, enableEdit } = this.state;
    this.setState({
      overallInformation: [
        {
          name: 'Tag #:',
          action: detailsUtils.getTrackerSerial(info)
        },
        {
          name: 'Status:',
          action: this.getStatus()
        },
        {
          name: 'Last Seen:',
          action: this.getLastSeenInfo()
        },
        {
          name: 'Exp. Date:',
          action: this.getExpirationDate(),
          editable: true
        },
        {
          action: this.getMissingFoundButton()
        },
        {
          name: 'Button',
          action: detailsUtils.getEditActionButtons(enableEdit, this.cancelEdit, this.saveEdit)
        }
      ],
      metaInformation: {
        display_name: 'Asset ID:',
        display_value: info.object_name,
        img: image_info
      }
    });
  };

  handleEditChange = (id, value) => {
    this.setState({
      info: {
        ...this.state.info,
        [id]: value
      },
      editPayload: {
        ...this.state.editPayload,
        [id]: value
      }
    });
  };

  getStatus() {
    return this.state.missing ? (
      <div className="red-text">Missing</div>
    ) : (
      <div className="green-text">Active</div>
    );
  }

  getLastSeenInfo() {
    const { info } = this.state;
    let lastDeptZones = null;
    let lastZone = null;
    let recentTime = info.creation_date;

    if (info.order_route.length > 0) {
      lastDeptZones = info.order_route[0].zones;
      lastZone = lastDeptZones[0];
      recentTime = lastZone.enter_timestamp;
    }

    return (
      <Fragment>
        <p className="last-seen-info">{detailsUtils.getLocation(info, recentTime)}</p>
      </Fragment>
    );
  }

  getTime(creationTime) {
    return ` at
        ${getFormattedDate(creationTime, 'hh:mm A')}
        on
        ${getFormattedDate(creationTime, 'MMM Do')}
      `;
  }

  getLastSeenTime() {
    const { info } = this.state;
    const creationTime = info.creation_date;
    let lastDeptZones = null;
    let lastZone = null;
    if (info.order_route.length > 0) {
      lastDeptZones = info.order_route[info.order_route.length - 1].zones;
      lastZone = lastDeptZones[lastDeptZones.length - 1];
    }
    return (
      <Fragment>
        <div>
          <Moment format="hh:mm A">{lastZone ? lastZone.enter_timestamp : creationTime}</Moment>
        </div>
        <div>
          <Moment format="MMM DD, YYYY">
            {lastZone ? lastZone.enter_timestamp : creationTime}
          </Moment>
        </div>
      </Fragment>
    );
  }

  getExpirationDate() {
    const { info } = this.state;
    let date = '-';
    if (info.due_date) {
      date = getFormattedDate(info.due_date, 'LL');
    }
    return <div>{date}</div>;
  }

  getMissingFoundButton() {
    const { missing } = this.state;
    return (
      <div className="expedite-group">
        <button className="default-button" onClick={this.handleMissingChange}>
          {missing ? 'Mark as Found' : 'Mark as Missing'}
        </button>
      </div>
    );
  }

  /**
   * listen to new file upload event
   * @param event
   */
  editImage = async event => {
    detailsUtils.editImage(event, this);
  };

  getExpediteSwitch() {
    const { expedited } = this.state;
    return (
      <div className="expedite-group">
        <Switch
          onChange={this.handleExpediteChange}
          checked={expedited}
          id="normal-switch"
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#4d8605"
        />
        <span id="expedite-text">{expedited ? 'Being expedited' : 'Not expedited'}</span>
      </div>
    );
  }

  handleRowClick(that, rowId) {
    const newExpandedRows = detailsUtils.getExpandedRows(that.state.expandedRows, rowId);
    that.setState({ expandedRows: newExpandedRows });
  }

  handleMissingChange = async event => {
    const { missing, info } = this.state;
    this.setState({
      missing: await TrackedObjectService.missingOrder(!missing, info)
    });

    this.setOrderState();
  };

  handleEditClick = async () => {
    await this.setState({
      enableEdit: true
    });
    this.setOrderState();
  };

  cancelEdit = async () => {
    await this.setState({
      enableEdit: false
    });
    this.setOrderState();
  };

  saveEdit = async () => {
    const { editPayload, trackedObjectId } = this.state;

    const searchObject = {
      type: queryString.parse(window.location.search).item
        ? 'Item'
        : queryString.parse(window.location.search).location
        ? 'Location'
        : null,
      value:
        queryString.parse(window.location.search).item ||
        queryString.parse(window.location.search).location ||
        null
    };
    const trackService = await AssetTrackService.build(searchObject);
    trackService.updateAssetDetails(trackedObjectId, editPayload);
    this.setState({ editPayload: {}, enableEdit: false });
    this.setOrderState();
  };

  getMoreButtonOptions = () => {
    return (
      <DropdownMenu>
        <DropdownItem className="dropdown-item">
          <div onClick={this.handleEditClick}>Edit Asset Detail</div>
        </DropdownItem>
        <DropdownItem className="dropdown-item">
          <form>
            <label className="dropdown-label">
              Change Asset Photo
              <input hidden type="file" onChange={this.editImage} />
            </label>
          </form>
        </DropdownItem>
        <DropdownItem className="dropdown-item">
          <div
            className="delete-label"
            onClick={() => {
              this.setState({ showDeleteModal: true });
            }}
          >
            Delete Asset
          </div>
        </DropdownItem>
      </DropdownMenu>
    );
  };

  handleDismiss = () => {
    this.setState({ deleteSuccess: false, deleteClicked: false });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  renderMoreButton = () => {
    return (
      <Dropdown>
        <DropdownToggle nav style={{ color: '#343434', float: 'right' }}>
          <MoreIcon />
        </DropdownToggle>
        {this.getMoreButtonOptions()}
      </Dropdown>
    );
  };

  deleteObject = async () => {
    const id = this.state.info.object_id;
    const path = `/tracked-objects/${id}`;
    this.setState({ showDeleteModal: false });
    await RestService.delete(path).then(
      result => {
        this.setState({ deleteClicked: true, deleteSuccess: true });
      },
      err => {
        this.setState({ deleteClicked: true, deleteSuccess: false });
      }
    );
  };

  render() {
    const {
      doneFetching,
      validOrder,
      error,
      overallInformation,
      metaInformation,
      enableEdit,
      info,
      showDeleteModal,
      deleteClicked,
      deleteSuccess
    } = this.state;
    if (doneFetching) {
      if (!validOrder) {
        return <TrackObjectNotFoundPage error={error} />;
      }
      return (
        <Fragment>
          {
            <DetailsCard
              overallInformation={overallInformation}
              metaInformation={metaInformation}
              renderMoreButton={this.renderMoreButton}
              canEdit={enableEdit}
              handleEditChange={this.handleEditChange}
              info={info}
              deleteClicked={deleteClicked}
              deleteSuccess={deleteSuccess}
              handleDismiss={this.handleDismiss}
            />
          }
          <div className="header-button-group">
            <div className="margin_maker" />
            <h3 className="route-table-header">Asset Location History</h3>
          </div>
          <div className="order-backlog-body">{info && detailsUtils.getOrderRouteView(this)}</div>
          {showDeleteModal && (
            <ConfirmationModal
              title="Confirm Deletion"
              bodyText="Permanently delete this item?"
              confirmFunction={this.deleteObject}
              cancelFunction={this.closeDeleteModal}
            />
          )}
        </Fragment>
      );
    }
    return null;
  }
}
