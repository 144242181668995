import React, { Component, Fragment } from 'react';
import queryString from 'query-string';

import OrderDetails from 'pages/tracked-object/OrderDetails';
import PartDetails from 'pages/tracked-object/PartDetails';
import AssetDetails from 'pages/tracked-object/AssetDetails';
import ConfigurationService from 'services/ConfigurationService';

import AbstractHomePage from 'pages/home/AbstractHomePage';

export default class ObjectDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [
        { name: 'order', component: OrderDetails },
        { name: 'part', component: PartDetails },
        { name: 'asset', component: AssetDetails }
      ],
      item: null,
      pageComponent: null
    };

    this.getComponent = this.getComponent.bind(this);
  }

  componentDidMount() {
    const values = queryString.parse(window.location.search);
    this.setState({
      item: values.item,
      pageComponent: this.getComponent(values.type)
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const values = queryString.parse(window.location.search);
    if (values.item !== this.state.searchString) {
      this.setState({
        item: values.item,
        pageComponent: this.getComponent(values.type)
      });
    }
  }

  // Need to show an error page if type is not found. This can only happen if the user manually manipulates the URL
  getComponent(type) {
    const solutionType = ConfigurationService.getSolutionType();
    return this.state.options.find(option => {
      if (type === 'part') {
        return option.name === type;
      }
      if (solutionType === 'order') {
        return option.name === type;
      }
      return option.name === 'asset';
    }).component;
  }

  render() {
    const returnComponent = () => {
      return (
        <Fragment>
          {this.state.pageComponent ? <this.state.pageComponent search={this.state.item} /> : null}
        </Fragment>
      );
    };

    return (
      <Fragment>
        <AbstractHomePage />
        {returnComponent()}
      </Fragment>
    );
  }
}
