import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody } from 'mdbreact';
import queryString from 'query-string';
import AssetTrackService from '../../../services/AssetTrackService';
import Overview from './Overview';
import GridView from './GridView';
import Style from './css/AssetComponent.module.css';
import { getCustomerDemo } from '../../../common/Utilities';

const loadingCircle = () => {
  return (
    <div className={`${Style.lds_roller}`}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default class TrackComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchObject: {
        type: '',
        value: ''
      },
      loading: true,
      objects: [],
      overviewDataLabel1: '',
      overviewDataLabel2: '',
      overviewDataLabel3: '',
      overviewData1: '',
      overviewData2: '',
      overviewData3: '',
      weeklyStatsLabel1: '',
      weeklyStatsLabel2: '',
      weeklyStatsLabel3: '',
      weeklyStats1: '',
      weeklyStats2: '',
      weeklyStats3: '',
      tagMap: {},
      img: getCustomerDemo()
    };
  }

  async componentDidMount() {
    const searchObject = {
      type: queryString.parse(window.location.search).item
        ? 'Item'
        : queryString.parse(window.location.search).location
        ? 'Location'
        : null,
      value:
        queryString.parse(window.location.search).item ||
        queryString.parse(window.location.search).location ||
        null
    };

    await this.onLoad(searchObject);
  }

  async onLoad(searchObject) {
    const trackService = await AssetTrackService.build(searchObject);

    const objects = trackService.getTrackDataList();
    let deptName = null;
    if (searchObject.type === 'Location' && objects.length > 0) {
      deptName = objects[0].department.department_name;
    }

    const overview = trackService.getOverviewData();

    const weekly = trackService.getWeeklyStatus();

    const tagMap = trackService.getTrackerMap();

    const filter = !searchObject.value;
    const title = `${deptName || searchObject.value || `Overall`} Overview`;

    this.setState({
      overviewDataLabel1: overview.overviewDataLabel1,
      overviewDataLabel2: overview.overviewDataLabel2,
      overviewDataLabel3: overview.overviewDataLabel3,
      overviewData1: overview.overviewData1,
      overviewData2: overview.overviewData2,
      overviewData3: overview.overviewData3,
      weeklyStats1: weekly.weeklyStats1,
      weeklyStats2: weekly.weeklyStats2,
      weeklyStats3: weekly.weeklyStats3,
      weeklyStatsLabel1: weekly.weeklyStats1,
      weeklyStatsLabel2: weekly.weeklyStatsLabel2,
      weeklyStatsLabel3: weekly.weeklyStatsLabel3,
      loading: false,
      tagMap,
      objects,
      filter,
      title,
      searchObject
    });
  }

  render() {
    const {
      overviewDataLabel1,
      overviewDataLabel2,
      overviewDataLabel3,
      overviewData1,
      overviewData2,
      overviewData3,
      weeklyStats1,
      weeklyStats2,
      weeklyStats3,
      weeklyStatsLabel1,
      weeklyStatsLabel2,
      weeklyStatsLabel3,
      objects,
      tagMap,
      filter,
      title,
      searchObject,
      loading,
      img
    } = this.state;
    return (
      <Fragment>
        <Card className={`${Style.content_holder}`}>
          <CardBody className={`${Style.override_padding}`}>
            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginBottom: '5%'
              }}
              className={`${Style.chart}`}
            >
              <div className={`${Style.asset_img_container}`}>
                <img
                  src={img}
                  style={{ maxWidth: '450px', maxHeight: '250px', paddingRight: '2%' }}
                  alt="asset img"
                />
              </div>
            </div>
            <div className={`${Style.overview}`}>
              {!loading ? (
                objects && objects.length > 0 ? (
                  <Overview
                    overviewDataLabel1={overviewDataLabel1}
                    overviewDataLabel2={overviewDataLabel2}
                    overviewDataLabel3={overviewDataLabel3}
                    overviewData1={overviewData1}
                    overviewData2={overviewData2}
                    overviewData3={overviewData3}
                    weeklyStats1={weeklyStats1}
                    weeklyStats2={weeklyStats2}
                    weeklyStats3={weeklyStats3}
                    weeklyStatsLabel1={weeklyStatsLabel1}
                    weeklyStatsLabel2={weeklyStatsLabel2}
                    weeklyStatsLabel3={weeklyStatsLabel3}
                    title={title}
                  />
                ) : searchObject.value ? (
                  <div className={`${Style.error_message} ${Style.red_text}`}>
                    {' '}
                    Oops! {searchObject.type} - {searchObject.value} does not exist.{' '}
                  </div>
                ) : (
                  <div className={`${Style.error_message}`}> No Assets to track. </div>
                )
              ) : (
                loadingCircle()
              )}
            </div>
            <hr className={`${Style.line}`} />
            <div className={`${Style.grid_view}`}>
              {!loading ? (
                objects.length > 0 ? (
                  <GridView
                    searchObject={searchObject}
                    filter={filter}
                    tagMap={tagMap}
                    objects={objects}
                  />
                ) : null
              ) : (
                loadingCircle()
              )}
            </div>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}
