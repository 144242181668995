import React, { Fragment, Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CardBody } from 'mdbreact';
import { NavLink } from 'reactstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './ManageTrackers.css';
import { Modal, Alert } from 'react-bootstrap';
import { getFormattedDate, dateComparatorSort, dateComparatorFilter } from 'common/Utilities';
import Autocomplete from 'react-autocomplete';
import DeleteActionRenderer from 'components/DeleteActionRenderer';
import ConfigurationService from '../../services/ConfigurationService';
import RestService from '../../services/RestService';

const bigInt = require('big-integer');

// generates an array of - sequence of numbers from start to end based on the base used
// by the current user
const populateGaps = function(start, end, isHex) {
  const result = [];
  let base = 10;
  if (isHex()) {
    base = 16;
  }

  let i = new bigInt(start, base);
  const endInt = new bigInt(end, base);

  while (i.leq(endInt)) {
    result.push(i.toString(base).toUpperCase());
    i = i.plus(1);
  }

  return result;
};

// assumes arr is an array of numbers of the given base
// returns a simple to understand sequence e.g. "1-5, 8, 11, 17-23"
const simplifySequence = function(arr, base) {
  const output = [];
  let start = 0;
  let end = 0;
  for (let i = 1; i < arr.length; i++) {
    const prev = new bigInt(arr[i - 1], base);
    const curr = new bigInt(arr[i], base);
    if (curr.minus(1).equals(prev)) {
      end = i;
    } else {
      simplifySequenceHelper(start, end, output, arr);
      start = i;
      end = i;
    }
  }

  simplifySequenceHelper(start, end, output, arr);
  return output.join(', ');
};

let simplifySequenceHelper = function(start, end, output, arr) {
  if (end - start < 2) {
    for (let j = start; j <= end; j++) {
      output.push(`${arr[j]}`);
    }
  } else {
    output.push(`${arr[start]}-${arr[end]}`);
  }
};

export default class ManageTrackers extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.activateModal = this.activateModal.bind(this);
    this.incrementModalNum = this.incrementModalNum.bind(this);
    this.decrementModalNum = this.decrementModalNum.bind(this);
    this.calculateTagQuantity = this.calculateTagQuantity.bind(this);
    this.hideErrorMessage = this.hideErrorMessage.bind(this);
    this.deleteRenderer = this.deleteRenderer.bind(this);
    this.gatherInfo = this.gatherInfo.bind(this);
    this.handleBatchDelete = this.handleBatchDelete.bind(this);
    this.deleteSingleTrackerAndObject = this.deleteSingleTrackerAndObject.bind(this);
    this.copyTracker = this.copyTracker.bind(this);
    this.isHex = this.isHex.bind(this);
    this.state = {
      associationType: 'default',
      selectedPartTypeValue_generateTrackedObjects: [],
      tagNames_generateTrackedObjects: [],
      tagCountInputValues_generateTrackedObjects: [],
      trackerDefaultPlant_generateTrackedObjects: undefined,
      num_modals: 1,
      showAddTagsModal: false,
      showBatchDeleteModal: false,
      error: undefined,
      tagError: [],
      blankError: true,
      valueError: [],
      errorStart: [],
      errorEnd: [],
      tags: [['', '']],
      tagCount: [0],
      showError_tags: false,
      objectTypes: undefined,
      submitting: false,
      frameworkComponents: {
        deleteRendererFunction: this.deleteRenderer
      },
      columnDefs: [
        {
          headerName: 'Tracker Number',
          field: 'trackerNumber',
          cellClass: 'cell-class',
          filter: 'agTextColumnFilter',
          filterParams: {
            clearButton: true
          },
          onCellClicked: this.copyTracker
        },
        {
          headerName: 'Creation Date',
          field: 'date_created',
          filter: 'agTextColumnFilter',
          comparator: dateComparatorSort,
          filterParams: {
            clearButton: true,
            comparator: dateComparatorFilter
          }
        },
        {
          headerName: 'Action',
          field: 'delete',
          filter: 'agTextColumnFilter',
          filterParams: {
            clearButton: true
          },
          cellRenderer: 'deleteRendererFunction'
        }
      ]
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const sort = [
      {
        colId: 'date_created',
        sort: 'asc'
      },
      {
        colId: 'trackerNumber',
        sort: 'asc'
      }
    ];
    this.gridApi.setSortModel(sort);
    params.api.sizeColumnsToFit();
  }

  incrementModalNum() {
    const updatedTags = this.state.tags;
    updatedTags.push(['', '']);
    this.setState({
      num_modals: this.state.num_modals + 1,
      tags: updatedTags
    });
  }

  decrementModalNum() {
    const updatedTags = this.state.tags;
    updatedTags.pop();
    this.setState({
      num_modals: this.state.num_modals - 1,
      tags: updatedTags,
      errorStart: {
        ...this.state.errorStart,
        [this.state.num_modals - 1]: false
      },
      errorEnd: { ...this.state.errorEnd, [this.state.num_modals - 1]: false }
    });
  }

  copyTracker() {
    this.gridApi.copySelectedRangeToClipboard(false);
  }

  hideErrorMessage() {
    this.setState({
      error: undefined
    });
  }

  handleClose() {
    this.setState({
      selectedPartTypeValue_generateTrackedObjects: [],
      tagNames_generateTrackedObjects: [],
      tagCountInputValues_generateTrackedObjects: [],
      showAddTagsModal: false,
      showBatchDeleteModal: false,
      num_modals: 1,
      error: undefined,
      errorStart: [],
      errorEnd: [],
      showError_tags: false,
      tagError: [],
      blankError: true,
      valueError: [],
      tags: [['', '']],
      tagCount: [0],
      submitting: false
    });
  }

  activateModal() {
    this.setState({
      showAddTagsModal: true
    });
  }

  refreshTable(result) {
    const new_tags = [];
    for (let i = 0; i < result.length; i++) {
      const row = {
        trackerNumber: result[i].tracker_serial,
        date_created: getFormattedDate(new Date(parseInt(result[i].created_timestamp, 10))),
        delete: result[i].tracker_id
      };
      new_tags.push(row);
    }

    this.setState({
      rowData: new_tags.concat(this.state.rowData)
    });
  }

  validateInput() {
    const errorStartCheck = this.state.errorStart;
    const errorEndCheck = this.state.errorEnd;
    let showError = false;

    for (let i = 0; i < this.state.num_modals; i++) {
      const start_tag = this.state.tags[i][0];
      const end_tag = this.state.tags[i][1];

      errorStartCheck[i] = start_tag === '' || this.state.valueError[i] || this.state.tagError[i];
      errorEndCheck[i] = end_tag === '' || this.state.valueError[i] || this.state.tagError[i];

      if (errorStartCheck[i] || errorEndCheck[i]) {
        showError = true;
      }
    }

    this.setState({
      errorStart: errorStartCheck,
      errorEnd: errorEndCheck
    });
    return showError;
  }

  setError(errorMsg) {
    this.setState({
      error: errorMsg,
      submitting: false
    });
  }

  isHex() {
    return (
      this.state.associationType !== 'generateTrackedObjectsNumerical' &&
      this.state.associationType !== 'addTagsWithNumerical'
    );
  }

  async handleSubmit() {
    this.setState({
      submitting: true
    });
    if (this.state.associationType === 'default') {
      if (this.validateInput()) {
        if (this.state.blankError) {
          this.setError('Action was not completed. Tag Field cannot be left blank.');
          return;
        }
        if (this.state.tagError || this.state.valueError) {
          this.setError(
            'Action was not completed. Quantity must be greater than 0 and less than 25,000.'
          );
          return;
        }
      }

      const payloadArray = [];
      for (let i = 0; i < this.state.num_modals; i++) {
        const start_tag = this.state.tags[i][0];
        const end_tag = this.state.tags[i][1];
        const new_tags = populateGaps(start_tag, end_tag, this.isHex);
        for (let j = 0; j < new_tags.length; j++) {
          payloadArray.push(new_tags[j]);
        }
      }

      const payload = {
        tracker_serial: payloadArray
      };

      const invalidTrackers = this.checkExistingTrackers(payloadArray);
      if (invalidTrackers.length !== 0) {
        this.setError(
          `Following tracker(s) already exist: ${simplifySequence(invalidTrackers, 16)}`
        );
        return;
      }

      await RestService.post('/trackers', payload).then(
        result => {
          this.refreshTable(result);
          this.handleClose();
        },
        err => {
          console.log(err.request);
          const status = err.request.responseText;
          this.setError(status);
        }
      );
    } else if (this.state.associationType === 'generateTrackedObjects') {
      let error = false;
      for (const key in this.state.tagError) {
        if (this.state.tagError[key]) {
          error = true;
          break;
        }
      }

      if (error) {
        this.setError(
          'Action was not completed. Quantity must be greater than 0 and less than 100.'
        );
        return;
      }

      for (let i = 0; i < this.state.num_modals; i++) {
        const payloadArray = [];
        for (let j = 0; j < this.state.tagNames_generateTrackedObjects[i].length; j++) {
          payloadArray.push(this.state.tagNames_generateTrackedObjects[i][j]);
        }

        const trackerPayload = {
          tracker_serial: payloadArray
        };

        const trackers = await RestService.post('/trackers', trackerPayload);
        for (let k = 0; k < trackers.length; k++) {
          // extract object_type_id from tracker serial
          const serialParts = trackers[k].tracker_serial.split('-');
          let objectTypeId = serialParts[0];
          for (let r = 1; r < serialParts.length - 1; r++) {
            objectTypeId += `-${serialParts[r]}`;
          }

          const trackedObjectPayload = {
            plant_id: this.state.trackerDefaultPlant_generateTrackedObjects,
            object_class: 'part',
            tracker_id: trackers[k].tracker_id,
            object_name: trackers[k].tracker_serial,
            object_type_id: objectTypeId
          };

          try {
            await RestService.post('/tracked-objects', trackedObjectPayload);
          } catch (e) {
            // Temporary workaround: If we get here we are in an invalid state in which a tracker
            // has been created but not the object.  A new API is required to close this possibility of a
            // broken state.
            console.log(e);
          }
        }

        this.refreshTable(trackers);
      }
      this.handleClose();
    } else if (
      this.state.associationType === 'generateTrackedObjectsHex' ||
      this.state.associationType === 'generateTrackedObjectsNumerical' ||
      this.state.associationType === 'addTagsWithNumerical'
    ) {
      if (this.validateInput()) {
        if (this.state.blankError) {
          this.setError('Action was not completed. Tag Field cannot be left blank.');
          return;
        }
        if (this.state.tagError || this.state.valueError) {
          this.setError(
            'Action was not completed. Quantity must be greater than 0 and less than 25,000.'
          );
          return;
        }
      }

      let validity;
      // generate trackers to be added
      const payloadArray = [];
      for (let i = 0; i < this.state.num_modals; i++) {
        const start_tag = this.state.tags[i][0];
        const end_tag = this.state.tags[i][1];
        if (this.state.associationType === 'generateTrackedObjectsHex') {
          validity = this.checkDuplicateTrackers(i, 16);
          if (!validity.valid) {
            this.setError(`There are duplicate tags in sets ${i + 1} and ${validity.num + 1}.`);
            return;
          }
          const new_tags = populateGaps(start_tag, end_tag, this.isHex);
          for (let j = 0; j < new_tags.length; j++) {
            payloadArray.push(new_tags[j]);
          }
        } else {
          validity = this.checkDuplicateTrackers(i, 10);
          if (!validity.valid) {
            this.setError(`There are duplicate tags in sets ${i + 1} and ${validity.num + 1}.`);
            return;
          }
          const start = new bigInt(start_tag);
          const end = new bigInt(end_tag);
          for (let j = start; j.lesserOrEquals(end); j = j.plus(1)) {
            payloadArray.push(j.toString());
          }
        }
      }

      // check if the given trackers already exist
      const base =
        this.state.associationType === 'generateTrackedObjectsNumerical' ||
        this.state.associationType === 'addTagsWithNumerical'
          ? 10
          : 16;
      const invalidTrackers = this.checkExistingTrackers(payloadArray);
      if (invalidTrackers.length !== 0) {
        this.setError(
          `Following tracker(s) already exist: ${simplifySequence(invalidTrackers, base)}`
        );
        return;
      }

      // add trackers
      const trackerPayload = {
        tracker_serial: payloadArray
      };
      let trackers;
      try {
        trackers = await RestService.post('/trackers', trackerPayload);
      } catch (e) {
        // in case two different add-tag-modals have duplicate trackers.
        console.log(e);
        this.setError(
          'Failed to add trackers. Make sure you are not trying to add duplicate trackers and try again.'
        );
        return;
      }
      if (this.state.associationType !== 'addTagsWithNumerical') {
        // add objects
        const tagCount = this.state.tagCount;
        let index = 0;
        for (let k = 0; k < trackers.length; k++) {
          if (tagCount[index] === 0) {
            index++;
          }

          const partName = this.state.selectedPartTypeValue_generateTrackedObjects[index];
          tagCount[index]--;
          const objectTypeId = Object.keys(this.state.objectTypes).find(
            key => this.state.objectTypes[key] === partName
          );

          const trackedObjectPayload = {
            plant_id: this.state.trackerDefaultPlant_generateTrackedObjects,
            object_class: 'part',
            tracker_id: trackers[k].tracker_id,
            object_name: `${objectTypeId}_${trackers[k].tracker_serial}`,
            object_type_id: objectTypeId
          };

          try {
            await RestService.post('/tracked-objects', trackedObjectPayload);
          } catch (e) {
            // Temporary workaround: If we get here we are in an invalid state in which a tracker
            // has been created but not the object.  A new API is required to close this possibility of a
            // broken state.
            console.log(e);
          }
        }
      }

      this.refreshTable(trackers);
      this.handleClose();
    }
  }

  checkDuplicateTrackers(modal_num, base) {
    const start = new bigInt(this.state.tags[modal_num][0], base);
    const end = new bigInt(this.state.tags[modal_num][1], base);
    for (let i = 0; i < this.state.num_modals; i++) {
      if (modal_num === i) {
        continue;
      }
      const start_i = new bigInt(this.state.tags[i][0], base);
      const end_i = new bigInt(this.state.tags[i][1], base);
      if (start.lesser(start_i)) {
        if (!end.lesser(start_i)) {
          return { valid: false, num: i };
        }
      } else if (!start.greater(end_i)) {
        return { valid: false, num: i };
      }
    }
    return { valid: true };
  }

  checkExistingTrackers(payloadArray) {
    const invalidTrackers = [];
    for (let i = 0; i < payloadArray.length; i++) {
      for (let j = 0; j < this.state.rowData.length; j++) {
        if (this.state.rowData[j].trackerNumber === payloadArray[i]) {
          invalidTrackers.push(payloadArray[i]);
          break;
        }
      }
    }
    return invalidTrackers;
  }

  deleteRenderer(params) {
    const tracker_id = params.value;
    return <DeleteActionRenderer id={tracker_id} headerName="Tag" gatherInfo={this.gatherInfo} />;
  }

  async gatherInfo(tracker_id) {
    const trackerObject = await RestService.get(`/trackers/${tracker_id}`);
    const tracker_number = trackerObject.tracker_serial;

    const all_parts = await RestService.get('/tracked-objects');
    const part_to_delete = all_parts.find(part => {
      return part.tracker_id === tracker_id;
    });

    let arrangedDisplayInfo;
    let deleteInfo;
    if (!part_to_delete) {
      // there is no object associated with this tracker
      arrangedDisplayInfo = {
        'Item Name': '--',
        'Tracker Number': tracker_number,
        Location: '--'
      };

      deleteInfo = {
        tracker_id,
        tracked_object_id: '--'
      };
    } else {
      // there must be an object associated with this tracker
      let part_type;
      try {
        part_type = await RestService.get(`/tracked-object-types/${part_to_delete.object_type_id}`);
      } catch (e) {
        console.log("The part exists, however the corresponding part type doesn't.");
        console.log(e);
      }

      const part_path = await RestService.get(
        `/tracked-objects/${part_to_delete.tracked_object_id}/route`
      );

      // The part_path.routes.length <= x is a work around. The value of x depends on how the backend creates
      // a new part: does it set path to []? Or does it set it to [{zone: unknown, department: unknown}]?
      const location =
        part_path.routes.length <= 1
          ? '--'
          : `${part_path.zone.zone_name}, ${part_path.department.department_name}`;

      arrangedDisplayInfo = {
        'Item Name': part_type ? part_type.object_type_name : '--',
        'Tracker Number': tracker_number,
        Location: location
      };

      deleteInfo = {
        tracker_id,
        tracked_object_id: part_to_delete.tracked_object_id
      };
    }

    const arrangedData = {
      display_info: arrangedDisplayInfo,
      delete_info: deleteInfo,
      confirm_function: this.deleteSingleTrackerAndObject
    };

    return arrangedData;
  }

  // If the tracker is associated with an object, then deletes the tracker and the object.
  // Otherwise, assumes that tracked_object_id === "--" and deletes just the tracker.
  // Updates the rows.
  async deleteSingleTrackerAndObject(params) {
    const tracked_object_id = params.tracked_object_id;
    const tracker_id = params.tracker_id;

    if (tracked_object_id !== '--') {
      await RestService.delete(`/tracked-objects/${tracked_object_id}`);
    }

    await RestService.delete(`/trackers/${tracker_id}`);
    const newRowData = this.state.rowData.filter(element => {
      return element.delete !== tracker_id;
    });

    this.setState({
      rowData: newRowData
    });
    this.handleClose();
  }

  async handleBatchDelete() {
    this.setState({
      submitting: true
    });
    if (this.validateInput()) {
      if (this.state.blankError) {
        this.setError('Action was not completed. Tag Field cannot be left blank.');
        return;
      }
      if (this.state.tagError || this.state.valueError) {
        this.setError(
          'Action was not completed. Quantity must be greater than 0 and less than 25,000.'
        );
        return;
      }
    }
    const start = this.state.tags[0][0];
    const end = this.state.tags[0][1];
    const gaps = populateGaps(start, end, this.isHex);
    const all_trackers = await RestService.get('/trackers');
    const invalid_trackers = this.validateGapsForDelete(gaps, all_trackers);

    if (invalid_trackers.length !== 0) {
      const base =
        this.state.associationType === 'generateTrackedObjectsNumerical' ||
        this.state.associationType === 'addTagsWithNumerical'
          ? 10
          : 16;
      this.setError(
        `Action was not completed. The following tracker(s) do not exist: ${simplifySequence(
          invalid_trackers,
          base
        )}`
      );
      return;
    }

    // All trackers are valid

    // get all the tracker_ids associated with the tracker numbers
    const tracker_ids = [];
    for (let i = 0; i < gaps.length; i++) {
      for (let j = 0; j < all_trackers.length; j++) {
        if (gaps[i] === all_trackers[j].tracker_serial) {
          tracker_ids.push(all_trackers[j].tracker_id);
        }
      }
    }

    // get all the tracked_object_ids associated with the tracker_ids
    const all_parts = await RestService.get('/tracked-objects');
    const objects = [];
    for (let i = 0; i < tracker_ids.length; i++) {
      for (let j = 0; j < all_parts.length; j++) {
        if (tracker_ids[i] === all_parts[j].tracker_id) {
          objects.push(all_parts[j].tracked_object_id);
        }
      }
    }

    // delete trackers
    for (let i = 0; i < tracker_ids.length; i++) {
      await RestService.delete(`/trackers/${tracker_ids[i]}`);
    }

    // delete objects
    for (let i = 0; i < objects.length; i++) {
      await RestService.delete(`/tracked-objects/${objects[i]}`);
    }

    // for rerendering
    const filteredData = this.state.rowData.filter(element => {
      return tracker_ids.indexOf(element.delete) === -1;
    });

    this.setState({
      rowData: filteredData
    });
    this.handleClose();
  }

  // toDelete contains the tracker serials that the user is trying to remove
  // all_trackers are all the trackers available to the user
  // returns invalid tracker serials that don't exist
  validateGapsForDelete = function(toDelete, all_trackers) {
    const invalid = [];
    for (let i = 0; i < toDelete.length; i++) {
      let found = false;
      for (let j = 0; j < all_trackers.length; j++) {
        if (all_trackers[j].tracker_serial === toDelete[i]) {
          found = true;
          break;
        }
      }

      if (!found) {
        invalid.push(toDelete[i]);
      }
    }
    return invalid;
  };

  componentDidMount() {
    this.onLoad();
  }

  async onLoad() {
    const customerConfiguration = ConfigurationService.getFullConfigurations();
    const associationType = customerConfiguration.associationType
      ? customerConfiguration.associationType
      : 'default';

    if (
      associationType === 'generateTrackedObjects' ||
      associationType === 'generateTrackedObjectsHex' ||
      associationType === 'generateTrackedObjectsNumerical'
    ) {
      // Obtain and store all object types.  This is used to determine the types of tracked objects that can be created
      // when creating tags.
      const objectTypeMap = {};
      const types = await RestService.get('/tracked-object-types');
      types.forEach(type => {
        objectTypeMap[type.object_type_id] = type.object_type_name;
      });
      this.setState({ objectTypes: objectTypeMap });

      // Temporary workaround: Grab and store first plant, all tracked objects will be create using its plant_id.
      // Some design discussion is needed here. Either Some UI exposure of the plant option is needed (with some thought
      // to how to prevent human error) or the requirement that the plant parameter must be supplied can be dropped (in
      // which case we would not know where the tracked object is located until it is detected).
      const plants = await RestService.get('/plants');
      if (plants && plants.length > 0) {
        this.setState({
          trackerDefaultPlant_generateTrackedObjects: plants[0].plant_id
        });
      }
    }

    const trackers = await RestService.get('/trackers');
    const rowData = [];
    const getTrackerOperations = trackers.map(async tracker => {
      const row = {
        trackerNumber: tracker.tracker_serial,
        date_created: getFormattedDate(new Date(parseInt(tracker.created_timestamp, 10))),
        delete: tracker.tracker_id
      };
      rowData.push(row);
    });

    await Promise.all(getTrackerOperations);
    this.setState({
      rowData,
      associationType
    });
  }

  isValueInput = input => {
    if (this.isHex()) {
      // integer, hex value only
      if (!/^[0-9a-fA-F]*$/.test(input)) {
        return false;
      }
    } else {
      // integer value only
      if (!/^[0-9]*$/.test(input)) {
        return false;
      }
    }

    if (input < 0) {
      return false;
    }
    return true;
  };

  changeTagNameValues(event, index, i) {
    const val = event.target.value.toUpperCase();
    if (!this.isValueInput(val)) {
      return;
    }

    const curr = this.state.tags;
    curr[i][index] = val;
    this.setState({
      tags: curr
    });

    // change quantity for the tags
    this.calculateTagQuantity(i);
  }

  calculateTagQuantity(i) {
    const startVal = this.state.tags[i][0];
    const endVal = this.state.tags[i][1];
    let len = 0;

    if (startVal !== '' && endVal !== '') {
      this.setState({ blankError: false });
      let start;
      let end;
      if (this.isHex()) {
        start = new bigInt(startVal, 16);
        end = new bigInt(endVal, 16);
      } else {
        start = new bigInt(startVal, 10);
        end = new bigInt(endVal, 10);
      }

      if (start.lesserOrEquals(end)) {
        this.setState({
          valueError: { ...this.state.valueError, [i]: false }
        });
        if (end.minus(start).lesserOrEquals(25000)) {
          this.setState({
            tagError: { ...this.state.tagError, [i]: false }
          });
          if (this.isHex()) {
            len = populateGaps(startVal, endVal, this.isHex).length;
          } else {
            len = parseInt(end.minus(start), 10) + 1;
          }
        } else {
          this.setState({
            tagError: { ...this.state.tagError, [i]: true }
          });
        }
      } else {
        this.setState({
          valueError: {
            ...this.state.valueError,
            [i]: true
          }
        });
      }
    } else {
      this.setState({ blankError: true });
    }

    this.setState({
      tagCount: { ...this.state.tagCount, [i]: len }
    });
  }

  async onNumberOfTagsChange(event, i) {
    this.setState({
      tagCountInputValues_generateTrackedObjects: {
        ...this.state.tagCountInputValues_generateTrackedObjects,
        [i]: event.target.value
      }
    });

    // Get number of trackers desired
    const number = parseInt(event.target.value, 10);
    if (isNaN(number) || number < 1 || number > 100) {
      this.setState({
        tagError: {
          ...this.state.tagError,
          [i]: true
        }
      });
    } else {
      this.setState({
        tagError: {
          ...this.state.tagError,
          [i]: false
        }
      });
    }

    // Obtain tracked_object_id from selected type (expected format "<type name> [<type-id>]")
    const partName = this.state.selectedPartTypeValue_generateTrackedObjects[i];
    const id = Object.keys(this.state.objectTypes).find(
      key => this.state.objectTypes[key] === partName
    );

    // Find the number of tracked objects of the given type.
    const trackedObjects = await RestService.get('/tracked-objects');
    let count = 0;
    trackedObjects.forEach(trackedObject => {
      if (trackedObject.object_type_id === id) {
        count++;
      }
    });

    // Generate tag names based based on the following format "<type-id>-<index>", where <index>
    // is determined sequentially starting with the count of existing objects of that type.
    const tagNames = [];
    for (let j = count; j < count + number; j++) {
      tagNames.push(`${id}-${j}`);
    }

    this.setState({
      tagCount: { ...this.state.tagCount, [i]: number }
    });

    this.setState({
      tagNames_generateTrackedObjects: {
        ...this.state.tagNames_generateTrackedObjects,
        [i]: tagNames
      }
    });
  }

  resetForm = i => {
    this.setState({
      tagCount: { ...this.state.tagCount, [i]: 0 }
    });

    const newTags = this.state.tags;
    newTags[i] = ['', ''];
    this.setState({ tags: newTags });

    this.setState({
      tagNames_generateTrackedObjects: {
        ...this.state.tagNames_generateTrackedObjects,
        [i]: []
      }
    });

    this.setState({
      tagCountInputValues_generateTrackedObjects: {
        ...this.state.tagCountInputValues_generateTrackedObjects,
        [i]: ''
      }
    });
  };

  //
  // Render
  //

  render() {
    const submissionError = () => {
      return (
        <Alert
          className="submission-error-alerts"
          bsStyle="danger"
          onDismiss={this.hideErrorMessage}
        >
          <h4>Error: </h4>
          <p>{this.state.error}</p>
        </Alert>
      );
    };

    const tagFormWithTrackedObjectGeneration = i => {
      const typeItems = [];
      for (const key in this.state.objectTypes) {
        // item labels have format "<type name> [<id>]"
        const item = { id: key, label: this.state.objectTypes[key] };
        typeItems.push(item);
      }
      return (
        <div key={i}>
          <div className="box1">
            <h5>Choose Part Type</h5>
            <h5>Choose Number of Tags</h5>
          </div>

          <form className="inputStuff">
            <Autocomplete
              getItemValue={item => item.label}
              items={typeItems}
              shouldItemRender={(item, value) => {
                return item.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
              }}
              renderItem={(item, isHighlighted) => (
                <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                  {item.label}
                </div>
              )}
              value={this.state.selectedPartTypeValue_generateTrackedObjects[i]}
              onChange={e => {
                const temp = this.state.selectedPartTypeValue_generateTrackedObjects.slice();
                temp[i] = e.target.value;
                this.setState({
                  selectedPartTypeValue_generateTrackedObjects: temp
                });
                this.resetForm(i);
              }}
              onSelect={value => {
                const temp = this.state.selectedPartTypeValue_generateTrackedObjects.slice();
                temp[i] = value;
                this.setState({
                  selectedPartTypeValue_generateTrackedObjects: temp
                });
                this.resetForm(i);
              }}
              wrapperStyle={{ position: 'relative', display: 'inline-block' }}
              menuStyle={{
                top: 30,
                left: 0,
                position: 'absolute',
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '2px 0',
                fontSize: '90%'
              }}
            />

            <input
              type="text"
              className="start-tag"
              value={
                this.state.tagCountInputValues_generateTrackedObjects[i]
                  ? this.state.tagCountInputValues_generateTrackedObjects[i]
                  : ''
              }
              onChange={event => this.onNumberOfTagsChange(event, i)}
            />
          </form>

          <div className="qty">
            {this.state.tagError[i]
              ? 'Error: Please enter a quantity greater than 0 and less than 100'
              : !this.state.tagNames_generateTrackedObjects[i]
              ? null
              : this.state.tagNames_generateTrackedObjects[i].length > 1
              ? `Tags to be added: ${this.state.tagNames_generateTrackedObjects[i][0]} to ${
                  this.state.tagNames_generateTrackedObjects[i][
                    this.state.tagNames_generateTrackedObjects[i].length - 1
                  ]
                }`
              : `Tag to be added: ${this.state.tagNames_generateTrackedObjects[i]}`}
          </div>
        </div>
      );
    };

    const tagFormWithTrackedObjectGenerationSerial = i => {
      const typeItems = [];
      for (const key in this.state.objectTypes) {
        // item labels have format "<type name> [<id>]"
        const item = { id: key, label: this.state.objectTypes[key] };
        typeItems.push(item);
      }
      return (
        <div key={i}>
          <div className="box1">
            <h5>Choose Part Type</h5>
          </div>

          <form className="inputStuffWithBottomMargin">
            <Autocomplete
              getItemValue={item => item.label}
              items={typeItems}
              shouldItemRender={(item, value) => {
                return item.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
              }}
              renderItem={(item, isHighlighted) => (
                <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                  {item.label}
                </div>
              )}
              value={this.state.selectedPartTypeValue_generateTrackedObjects[i]}
              onChange={e => {
                const temp = this.state.selectedPartTypeValue_generateTrackedObjects.slice();
                temp[i] = e.target.value;
                this.setState({
                  selectedPartTypeValue_generateTrackedObjects: temp
                });
                this.resetForm(i);
              }}
              onSelect={value => {
                const temp = this.state.selectedPartTypeValue_generateTrackedObjects.slice();
                temp[i] = value;
                this.setState({
                  selectedPartTypeValue_generateTrackedObjects: temp
                });
                this.resetForm(i);
              }}
              wrapperStyle={{ position: 'relative', display: 'inline-block' }}
              menuStyle={{
                top: 30,
                left: 0,
                position: 'absolute',
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '2px 0',
                fontSize: '90%'
              }}
            />
          </form>

          {!this.state.selectedPartTypeValue_generateTrackedObjects[i] ? null : (
            <div>
              <div className="box1">
                <h5>Enter Starting Tag #</h5>
                <h5>Enter End Tag #</h5>
              </div>

              <form className="inputStuff">
                <input
                  type="text"
                  className={this.state.errorStart[i] ? 'input-error' : 'start-tag'}
                  onChange={event => this.changeTagNameValues(event, 0, i)}
                  value={this.state.tags[i][0]}
                />
                to
                <input
                  type="text"
                  className={this.state.errorEnd[i] ? 'input-error' : 'end-tag'}
                  onChange={event => this.changeTagNameValues(event, 1, i)}
                  value={this.state.tags[i][1]}
                />
              </form>

              <div className="qty">
                {this.state.tagError[i]
                  ? 'Error: Please enter quantity less then 25,000'
                  : this.state.tagCount[i]}{' '}
                total tags to be added{' '}
              </div>
            </div>
          )}
        </div>
      );
    };

    const tagFormWithTagsGenerationSerial = i => {
      return (
        <div key={i}>
          <div>
            <div className="box1">
              <h5>Enter Starting Tag #</h5>
              <h5>Enter End Tag #</h5>
            </div>

            <form className="inputStuff">
              <input
                type="text"
                className={this.state.errorStart[i] ? 'input-error' : 'start-tag'}
                onChange={event => this.changeTagNameValues(event, 0, i)}
                value={this.state.tags[i][0]}
              />
              to
              <input
                type="text"
                className={this.state.errorEnd[i] ? 'input-error' : 'end-tag'}
                onChange={event => this.changeTagNameValues(event, 1, i)}
                value={this.state.tags[i][1]}
              />
            </form>

            <div className="qty">
              {this.state.tagError[i]
                ? 'Error: Please enter quantity less then 25,000'
                : this.state.tagCount[i]}{' '}
              total tags to be added{' '}
            </div>
          </div>
        </div>
      );
    };

    const tagForm = i => {
      return (
        <div key={i}>
          <div className="box1">
            <h5>Enter Starting Tag #</h5>
            <h5>Enter End Tag #</h5>
          </div>

          <form className="inputStuff">
            <input
              type="text"
              className={this.state.errorStart[i] ? 'input-error' : 'start-tag'}
              onChange={event => this.changeTagNameValues(event, 0, i, true)}
              value={this.state.tags[i][0]}
            />
            to
            <input
              type="text"
              className={this.state.errorEnd[i] ? 'input-error' : 'end-tag'}
              onChange={event => this.changeTagNameValues(event, 1, i, true)}
              value={this.state.tags[i][1]}
            />
          </form>

          <div className="qty">
            {this.state.tagError[i]
              ? 'Error: Please enter quantity less then 25,000'
              : this.state.tagCount[i]}{' '}
            total tags to be added{' '}
          </div>
        </div>
      );
    };

    const tagForms = associationType => {
      const forms = [];
      for (let i = 0; i < this.state.num_modals; i++) {
        switch (associationType) {
          case 'generateTrackedObjects':
            forms.push(tagFormWithTrackedObjectGeneration(i));
            break;
          case 'generateTrackedObjectsHex':
            forms.push(tagFormWithTrackedObjectGenerationSerial(i, true));
            break;
          case 'generateTrackedObjectsNumerical':
            forms.push(tagFormWithTrackedObjectGenerationSerial(i, false));
            break;
          case 'addTagsWithNumerical':
            forms.push(tagFormWithTagsGenerationSerial(i, false));
            break;
          default:
            forms.push(tagForm(i));
            break;
        }
      }
      return forms;
    };

    // Batch delete still assumes that the trackers are in HEX. Not updated for numerical tags.
    const batchDeleteForm = () => {
      return (
        <Fragment>
          <div className="box1">
            <h5>Enter Starting Tag #</h5>
            <h5>Enter End Tag #</h5>
          </div>

          <form className="inputStuff">
            <input
              type="text"
              className={this.state.errorStart[0] ? 'input-error' : 'start-tag'}
              onChange={event => this.changeTagNameValues(event, 0, 0)}
              value={this.state.tags[0][0]}
            />
            to
            <input
              type="text"
              className={this.state.errorEnd[0] ? 'input-error' : 'end-tag'}
              onChange={event => this.changeTagNameValues(event, 1, 0)}
              value={this.state.tags[0][1]}
            />
          </form>

          <div className="qty">
            {this.state.tagError[0]
              ? 'Error: Please enter quantity less then 25,000'
              : this.state.tagCount[0]}{' '}
            total tags to be deleted{' '}
          </div>
        </Fragment>
      );
    };

    const displayAddTagsModal = () => {
      return (
        <Modal show={this.state.showAddTagsModal} onHide={this.handleClose}>
          <Modal.Header className="route-modal-header" closeButton>
            <Modal.Title className="route-modal-title">Register Tags</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.error && submissionError()}
            <div id="form">{tagForms(this.state.associationType)}</div>
          </Modal.Body>
          <Modal.Footer>
            <NavLink id="modal-add" onClick={this.incrementModalNum}>
              {'+ Add Another Set'}
            </NavLink>
            {this.state.num_modals > 1 && (
              <NavLink id="remove" onClick={this.decrementModalNum}>
                {'- Remove Last'}
              </NavLink>
            )}
            <div id="footer-buttons">
              <button
                className="default-button"
                disabled={this.state.submitting}
                onClick={this.handleSubmit}
              >
                Add Tags
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      );
    };

    const displayBatchDeleteModal = () => {
      return (
        <Modal show={this.state.showBatchDeleteModal} onHide={this.handleClose}>
          <Modal.Header className="route-modal-header" closeButton>
            <Modal.Title className="route-modal-title">Delete Multiple Tags</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.error && submissionError()}
            <div id="form">{batchDeleteForm()}</div>
          </Modal.Body>
          <Modal.Footer>
            <div id="footer-buttons">
              <button
                className="confirm-delete-button"
                disabled={this.state.submitting}
                onClick={this.handleBatchDelete}
              >
                Delete Tags
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      );
    };

    return (
      <Fragment>
        <CardBody id="main-card-body">
          <div className="title">
            <h2>Manage Tags</h2>
            <div>Register new tags or removing existing ones</div>
          </div>
          <div className="page-data-table">
            <div className="actions-bar">
              <h4 className="table-name">Tags</h4>
              <button className="default-button" onClick={this.activateModal}>
                + Add Tags
              </button>
              <button
                className="cancel-button delete-button"
                onClick={() => {
                  this.setState({
                    showBatchDeleteModal: true
                  });
                }}
              >
                - Delete Tags
              </button>
            </div>
            <div className="ag-theme-material aggrid-grid">
              <AgGridReact
                onGridReady={this.onGridReady.bind(this)}
                enableSorting
                enableFilter
                rowSelection="multiple"
                animateRows
                enableColResize
                columnDefs={this.state.columnDefs}
                rowData={this.state.rowData}
                enableRangeSelection
                frameworkComponents={this.state.frameworkComponents}
              />
            </div>
          </div>
          {this.state.showAddTagsModal && displayAddTagsModal()}
          {this.state.showBatchDeleteModal && displayBatchDeleteModal()}
        </CardBody>
      </Fragment>
    );
  }
}
