import React, { Component } from 'react';
import AuthService from '../../services/AuthService';
import LoadingWord from '../../components/loading/LoadingWord';
import Switch from 'react-switch';
import './NotificationSettings.css';
import AlertService from '../../services/AlertService';

export default class NotificationSettings extends Component {
  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(recipient, enabled) {
    let recipientsMap = this.state.recipientsMap;

    recipientsMap[recipient].enabled = enabled;
    let enabledList = Object.values(recipientsMap).filter(recipientInfo => {
      return recipientInfo.enabled;
    });

    let payload = enabledList.map(recipientInfo => recipientInfo.recipient);

    AlertService.updateNotificationSubscription(payload).then(() => {
      this.setState({ recipientsMap: recipientsMap });
    });
  }

  UNSAFE_componentWillMount() {
    let sessionInfo = AuthService.getSessionInfo();

    let idPayload = sessionInfo.attributes;
    let email = idPayload.email;
    let phoneNumber = idPayload.phone_number;

    let recipientsMap = {};
    let protocolsMap = {};

    if (isRecipientAvailable(email)) {
      protocolsMap['email'] = [email];
      recipientsMap[email] = {
        recipient: email,
        protocol: 'email',
        enabled: false
      };
    }

    if (isRecipientAvailable(phoneNumber)) {
      protocolsMap['phoneNumber'] = [phoneNumber];
      recipientsMap[phoneNumber] = {
        recipient: phoneNumber,
        protocol: 'phoneNumber',
        enabled: false
      };
    }

    let context = this;
    AlertService.getNotificationRecipients().then(result => {
      let recipients = result.recipients;

      recipients.forEach(recipient => {
        if (recipientsMap[recipient]) {
          recipientsMap[recipient].enabled = true;
        }
      });

      context.setState({
        protocolsMap: protocolsMap,
        recipientsMap: recipientsMap
      });
    });
  }

  render() {
    let isLoading = true;

    if (this.state && this.state.protocolsMap && this.state.recipientsMap) {
      isLoading = false;
    }

    const Recipient = props => {
      let { text, recipient, recipientsMap } = { ...props };
      let recipientInfo = recipientsMap[recipient];

      return (
        <li className="recipient-info">
          <div className="recipient-description">
            {text} - {recipient}
          </div>
          <Switch
            checked={recipientInfo.enabled}
            onChange={checked => this.handleToggle(recipient, checked)}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={'#4d8605'}
          />
        </li>
      );
    };

    return (
      <div className="notification-settings">
        <div className="title">
          <h2>Notification Settings</h2>
          <div>Configure notification settings to subscribe for rules</div>
        </div>

        <div className="notification-settings-content">
          <div className="settings-content">
            <div className="title">Receive notifications for alerts</div>

            {isLoading ? (
              <LoadingWord />
            ) : (
              <ul className="recipients">
                {this.state.protocolsMap['phoneNumber'] &&
                  this.state.protocolsMap['phoneNumber'].map(phoneNumber => (
                    <Recipient
                      key={phoneNumber}
                      text={'Receive via SMS'}
                      recipient={phoneNumber}
                      recipientsMap={this.state.recipientsMap}
                    />
                  ))}

                {this.state.protocolsMap['email'] &&
                  this.state.protocolsMap['email'].map(email => (
                    <Recipient
                      key={email}
                      text={'Receive via Email'}
                      recipient={email}
                      recipientsMap={this.state.recipientsMap}
                    />
                    // <li>Receive via Email - {email}</li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function isRecipientAvailable(recipient) {
  return recipient && recipient.toString().trim() !== '';
}
