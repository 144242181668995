import React, { Component, Fragment } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import RestService from '../../../services/RestService';

import HistoricalBacklogDataLoader from '../../../pages/report/domains/HistoricalBacklogDataLoader';

/**
 * Since this component is used for two very distinct domain object, we use this map to generalize its field names
 * @type {{"tracked-object-types": {name: string, id: string, graphType: string}, departments: {name: string, id: string, graphType: string}}}
 */
const DomainPropertyMap = {
  'tracked-object-types': {
    name: 'object_type_name',
    id: 'object_type_id',
    graphType: 'histogram-by-type'
  },

  departments: {
    name: 'department_name',
    id: 'department_id',
    graphType: 'histogram-by-department'
  }
};

/**
 * These are the field names which are used internally for housekeeping
 * @type {{LastUpdatedTimestamp: string, Active: string, Data: string, ColorHex: string, Entries: string}}
 */
const FieldNames = {
  LastUpdatedTimestamp: '_last_updated_timestamp',
  Active: '_active',
  Data: '_data',
  ColorHex: '_color_hex',
  Entries: '_entries'
};

function hashCode(s) {
  let a = 1;

  let c = 0;

  let h;

  let o;
  if (s) {
    a = 0;
    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h);
      a = ((a << 6) & 268435455) + o + (o << 14);
      c = a & 266338304;
      a = c !== 0 ? a ^ (c >> 21) : a;
    }
  }
  return String(a);
}

/**
 * Add '0' in front of hex string if it does not meet the length requirement.
 *  This happens sometimes when the leading digit is 0 and when converting to string,
 *  the leading 0 is omitted
 * @param hex
 * @param maxLength
 * @return {*}
 */
function padHex(hex, maxLength) {
  let paddedHex = hex;
  if (hex.length < maxLength) {
    for (let i = 0; i < maxLength - hex.length; i++) {
      paddedHex = `0${paddedHex}`;
    }
  }

  return paddedHex;
}

// Convert an int to hexadecimal with a max length of six characters.
function intToARGB(i) {
  let hex =
    ((i >> 24) & 0xff).toString(16) +
    ((i >> 16) & 0xff).toString(16) +
    ((i >> 8) & 0xff).toString(16) +
    (i & 0xff).toString(16);

  // guard against the case when leading 0 is stripped.
  hex = padHex(hex, 6);

  return hex.substring(0, 6);
}

function toHexColour(string) {
  return `#${intToARGB(hashCode(string))}`;
}

/**
 * Parse the entry object to just obtains the data and graph title
 * @param entries {[LineGraphDataEntry]}
 * @return {graphTitle: string, dataPoints: [{}]}
 */
function parseEntries(entries) {
  let graphTitle = 'No Data';
  if (entries.length > 0) {
    graphTitle = `${entries[0].timestamp} - ${entries[entries.length - 1].timestamp}`;
  }

  const dataPoints = entries.map(entry => entry.data);
  return {
    graphTitle,
    dataPoints
  };
}

export default class DepartmentThresholdGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      domain: this.props.domain ? this.props.domain : 'departments',
      hideLegend: this.props.hideLegend,
      categories: ['day', 'week', 'month', 'quarter', 'year'],
      activeCategory: 'day',
      departmentItem: [],
      departmentHealth: this.props.departmentHealth
    };

    this.propertyNames = DomainPropertyMap[this.state.domain];
    this.dataLoader = new HistoricalBacklogDataLoader(
      `/tracked-objects/${this.propertyNames.graphType}`
    );
  }

  async init() {
    const items = await RestService.get(`/${this.state.domain}/${this.props.departmentId}`);
    // assign a unique color code for each
    const itemName = items.department_name;
    items[FieldNames.ColorHex] = this.state.departmentHealth || toHexColour(itemName);

    let dataPoints = [];
    let deptThresholds = {};
    const activeTimeSpanType = this.state.categories[1];

    // fetch data for the first default item
    if (items) {
      const itemId = items.department_id;
      const timeSpanType = activeTimeSpanType;

      await this.dataLoader.loadData(itemId, timeSpanType);
      const entries = this.dataLoader.getData(timeSpanType);

      const dataInfo = parseEntries(entries);
      dataPoints = dataInfo.dataPoints;
      deptThresholds = {
        warning: items.thresholds.orderThresholdWarning,
        critical: items.thresholds.orderThresholdCritical
      };
    }

    this.setState({
      departmentItem: items,
      activeCategory: activeTimeSpanType,
      dataPoints,
      deptThresholds
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.init().then(_ => {});
  }

  /**
   * handle resize of graph
   */
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width, departmentItem, dataPoints, deptThresholds } = this.state;
    const isMobile = width <= 768;

    const returnLegend = () => {
      if (!this.state.hideLegend) {
        return (
          <Legend
            layout={isMobile ? 'horizontal' : 'vertical'}
            verticalAlign={isMobile ? 'bottom' : 'top'}
            align={isMobile ? 'center' : 'right'}
            content={this.renderCustomizedLegend}
            key="legend"
          />
        );
      }
    };

    const getResponsiveContainerAspect = () => {
      if (!isMobile) {
        return 4 / 2;
      }

      return 3 / 2;
    };

    return (
      <Fragment>
        {dataPoints && (
          <ResponsiveContainer aspect={getResponsiveContainerAspect()} width="100%">
            <LineChart
              data={Object.values(dataPoints)}
              height={200}
              margin={{ top: 5, right: 10, bottom: 5, left: -35 }}
            >
              <Line
                key={departmentItem.department_id}
                type="monotone"
                dataKey={departmentItem.department_id}
                stroke={departmentItem[FieldNames.ColorHex]}
                strokeWidth="3"
                name={departmentItem.department_name}
                isAnimationActive
                dot={false}
              />
              <CartesianGrid strokeDasharray="3 3" stroke="#D8D8D8" />
              <XAxis dataKey="timestamp" tick={false} />
              <YAxis domain={[0, dataMax => dataMax + 1]} />
              <Tooltip />
              {returnLegend()}
              {deptThresholds && (
                <ReferenceLine
                  y={deptThresholds.warning}
                  alwaysShow
                  strokeWidth="2"
                  stroke="#FAD192"
                  label={{
                    value: `Warning Threshold: ${deptThresholds.warning}`,
                    position: 'insideTopRight'
                  }}
                />
              )}
              {deptThresholds && (
                <ReferenceLine
                  y={deptThresholds.critical}
                  alwaysShow
                  strokeWidth="2"
                  stroke="#E9777A"
                  label={{
                    value: `Critical Threshold: ${deptThresholds.critical}`,
                    position: 'insideTopRight'
                  }}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </Fragment>
    );
  }
}
