import React from 'react';
import RestService from '../../../services/RestService';
import ResourceProvider from './ResourceProvider';
import { getCustomerLogo } from 'common/Utilities';
import ConfigurationService from '../../../services/ConfigurationService';
import _ from 'lodash';

const resourceProviderName = 'Zone';
const parentName = 'Department';
const childName = 'Gateway';

export default class ZoneResource extends ResourceProvider {
  static async build() {
    let getZones = async () => {
      let zones = await RestService.get('/zones');

      let zoneList = _.map(zones, zone => {
        let zoneName = zone.zone_name;
        let zoneId = zone.zone_id;
        let zoneDeptId = zone.department_id;
        let zoneDesc = zone.zone_info;

        return {
          thisName: zoneName,
          thisId: zoneId,
          deptId: zoneDeptId,
          thisDesc: zoneDesc
        };
      });

      return zoneList;
    };

    let zones = await getZones();

    return new ZoneResource(
      [...zones.sort((a, b) => a.thisName.localeCompare(b.thisName))],
      resourceProviderName
    );
  }

  deleteItem(thisItem, handleCloseFunc, showDeleteErrorFunc) {
    RestService.delete('/zones/' + thisItem.thisId).then(
      () => {
        this.resourceArray = this.resourceArray.filter(function(zone) {
          return zone !== thisItem;
        });
        handleCloseFunc();
      },
      () => {
        showDeleteErrorFunc();
      }
    );
  }

  addItemsSchema(
    index,
    item,
    changeValuesFunc,
    resourceProviders,
    nameCheck,
    parentIdCheck,
    imageValuesFunc
  ) {
    return (
      <div>
        {super.parentIdForm(
          index,
          item.parentId,
          resourceProviders.find(provider => provider.getName() === parentName),
          changeValuesFunc,
          parentIdCheck
        )}
        {super.levelNameForm(index, item.name, changeValuesFunc, nameCheck)}
        {super.descriptionForm(index, item.description, changeValuesFunc)}
        {super.optionalGatewayForm(index, item.optionalGatewayNumber, changeValuesFunc)}
        {super.imageForm(index, item.imageDetails, imageValuesFunc)}
      </div>
    );
  }

  editItemsSchema(
    index,
    itemToEdit,
    changeValuesFunc,
    resourceProviders,
    nameCheck,
    parentIdCheck
  ) {
    let name = resourceProviders
      .find(provider => provider.getName() === parentName)
      .resourceArray.filter(obj => {
        return obj.thisId === itemToEdit.deptId;
      })[0].thisName;
    return (
      <div>
        {super.editParentIdForm(
          name,
          resourceProviders.find(provider => provider.getName() === parentName)
        )}
        {super.editLevelNameForm(index, itemToEdit, changeValuesFunc, nameCheck)}
        {super.editDescriptionForm(index, itemToEdit, changeValuesFunc)}
      </div>
    );
  }

  async editItem(
    formCheckFunc,
    updateErrorsFunc,
    stagedItems,
    resourceProviders,
    customerId,
    itemToEdit
  ) {
    let errorCollection = [];
    let successCollection = [];
    let successRegistry = [];

    var payload = [];

    if (stagedItems[0].name !== '') {
      payload.push({
        op: 'replace',
        path: '/zone_name',
        value: stagedItems[0].name
      });
    }

    if (stagedItems[0].description !== '') {
      payload.push({
        op: 'replace',
        path: '/zone_info',
        value: stagedItems[0].description
      });
    }

    if (payload.length >= 1) {
      await RestService.patch('/zones/' + itemToEdit.thisId, payload).then(
        result => {
          let updateItem = this.getArrayItemById(result.zone_id);

          updateItem.thisName = result.zone_name;
          updateItem.thisDesc = result.zone_info;

          successCollection.push(result.zone_name);
          successRegistry.push(0);
        },
        err => {
          errorCollection.push({
            entry: stagedItems[0].name,
            errMessage: err.response.data.message
          });
        }
      );
    }
    updateErrorsFunc(successRegistry, successCollection, errorCollection);
    return true;
  }

  async addItem(formCheckFunc, updateErrorsFunc, stagedItems, resourceProviders) {
    let resourceProvider = resourceProviders.find(provider => provider.getName() === childName);

    let errorCollection = [];
    let successCollection = [];
    let successRegistry = [];

    let nameChecks = formCheckFunc('name');
    let parentCheck = formCheckFunc('parentId');

    if (!nameChecks || !parentCheck) {
      return false;
    }

    for (const index of stagedItems.keys()) {
      let thisZoneId = '';

      let payload = {
        zone_name: stagedItems[index].name,
        department_id: stagedItems[index].parentId
      };

      if (stagedItems[index].description !== '') {
        payload.zone_info = stagedItems[index].description;
      }

      let imageObject = '';
      if (stagedItems[index].imageDetails !== '') {
        await RestService.uploadImage(
          '/images',
          stagedItems[index].imageDetails,
          stagedItems[index].imageDetails.type
        ).then(
          result => {
            imageObject = result.data;
            successCollection.push(result.data);
          },
          err => {
            errorCollection.push({
              entry: stagedItems[index].imageDetails.name,
              errMessage: err.response.data.message
            });
          }
        );
      }

      if (errorCollection.length > 0) {
        updateErrorsFunc(successRegistry, successCollection, errorCollection);
        return true;
      }

      if (imageObject !== '') {
        payload.image = imageObject;
      }

      await RestService.post('/zones', payload).then(
        result => {
          this.add({
            thisName: result.zone_name,
            thisId: result.zone_id,
            deptId: result.department_id,
            thisDesc: result.zone_info
          });

          thisZoneId = result.zone_id;
          successCollection.push(result.zone_name);
          successRegistry.push(index);
        },
        err => {
          errorCollection.push({
            entry: stagedItems[index].name,
            errMessage: err.response.data.message
          });
        }
      );

      if (stagedItems[index].optionalGatewayNumber !== '') {
        let gatePayload = {
          zone_id: thisZoneId,
          gateway_serial_number: stagedItems[index].optionalGatewayNumber
        };

        await RestService.post('/gateways', gatePayload).then(
          result => {
            resourceProvider.add({
              thisName: result.gateway_serial_number,
              thisId: result.gateway_id,
              zoneId: result.zone_id
            });

            successCollection.push(result.gateway_serial_number);
          },
          err => {
            errorCollection.push({
              entry: stagedItems[index].name,
              errMessage: err.response.data.message
            });
          }
        );
      }
    }

    updateErrorsFunc(successRegistry, successCollection, errorCollection);
    return true;
  }

  static async GetZoneImage(z_id) {
    let customerConfig = ConfigurationService.getFullConfigurations();
    let imageDetails = getCustomerLogo();
    if (z_id && z_id !== 'Unknown') {
      await RestService.get('/zones/' + z_id).then(result => {
        if (result.image) {
          const { image_url, url } = { ...result.image }; // TODO: deprecate image_url since it only captures relative url

          if (url) {
            imageDetails = url;
          } else {
            // TODO: the else part should be gotten rid off once we have fully transition to use full url
            imageDetails = `${customerConfig.s3.URL}/${image_url}`;
          }
        }
      });
    }
    return imageDetails;
  }
}
