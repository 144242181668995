import React, { Component, Fragment } from 'react';

import { Card, CardBody } from 'mdbreact';

import html2canvas from 'html2canvas';
import './InventoryReport.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import exportImage from 'img/export.png';
import Slider from 'react-slick';
import HistoricalBackLogGraph from './HistoricalBackLogGraph';

export default class InventoryReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth
    };
  }

  takeScreenShots(e) {
    const value = e.target.value;

    if (value === 'captureHistoricalGraph') {
      html2canvas(document.querySelector('#captureHistoricalgraph')).then(function(canvas) {
        const a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
        a.download = 'HistoricalLog.jpg';
        a.click();
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };

    const { width } = this.state;
    const isMobile = width <= 500;

    const getDesktopHistoricalGraph = () => {
      return (
        <div>
          <div className="historical-graph-header">
            <h5 className="historical-graph-title">Historical Inventory Trends</h5>
            <input
              className="export-icon"
              alt="export icon"
              type="image"
              src={exportImage}
              onClick={this.takeScreenShots}
              value="captureHistoricalGraph"
            />
          </div>
          <div id="captureHistoricalGraph" className="backlog-graphs-historical">
            <div className="historical-graph-body">
              <HistoricalBackLogGraph domain="tracked-object-types" />
            </div>
          </div>
        </div>
      );
    };

    const getMobileHistoricalGraph = () => {
      return (
        <Slider {...settings}>
          <div>
            <div className="historical-graph-header">
              <h5 className="historical-graph-title">Historical Order Backlog</h5>
              <input
                className="export-icon"
                alt="export icon"
                type="image"
                src={exportImage}
                onClick={this.takeScreenShots}
                value="captureHistoricalGraph"
              />
            </div>
            <div className="historical-graph-body">
              <HistoricalBackLogGraph />
            </div>
          </div>
        </Slider>
      );
    };

    const displayData = () => {
      return (
        <Fragment>{isMobile ? getMobileHistoricalGraph() : getDesktopHistoricalGraph()}</Fragment>
      );
    };

    return (
      <Fragment>
        <h3 className="report-title">Historical Inventory Trends</h3>
        <Card className="content_holder">
          <CardBody>{displayData()}</CardBody>
        </Card>
      </Fragment>
    );
  }
}
