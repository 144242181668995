import React from 'react';
import { Card } from 'mdbreact';
import './InventoryRequestComponent.css';
import Moment from 'react-moment';

const borderStyleMap = {
  Arriving: 'border-yellow',
  Arrived: 'border-green',
  New: 'border-blue',
  Backorder: 'border-red'
};

const backgroundStyleMap = {
  Arriving: 'background-yellow',
  Arrived: 'background-green',
  New: 'background-blue',
  Backorder: 'background-red'
};

const RequestCardComponent = ({
  value,
  hasOptionalColumnHeader,
  getStatusDate,
  showComments
}) => {
  return (
    // eslint-disable-next-line react/self-closing-comp
    <Card className={`list-item ${borderStyleMap[value.status]}`} key={value.restock_request_id}>
      <div id={value.restock_request_id} className="item-large-name">
        {value.item_name}
      </div>
      <div className="item-medium">{value.order_number}</div>
      <div className="item-small">{value.quantity}</div>
      <div className="item-medium">{value.size}</div>
      <div className="item-medium">{value.vendor}</div>
      {hasOptionalColumnHeader('Cost') && (
        <div className="item-medium">{`$${Number(value.cost).toFixed(2)}`}</div>
      )}
      <div className="item-medium">
        <Moment format="MMM DD, YYYY">{value.restock_requested_timestamp}</Moment>
      </div>
      <div className="item-large-name">{value.by}</div>
      <div className="item-large-name">
        <div className={'status '.concat(backgroundStyleMap[value.status])}>
          <div>{value.status}</div>
          <div>
            {value.status !== 'New' && (
              <p> {getStatusDate(value.restock_last_updated_timestamp)} </p>
            )}
          </div>
        </div>
      </div>
      <div className="item-large comments-group">{showComments(value.comment)}</div>
    </Card>
  );
};

export default RequestCardComponent;
