import React, { Fragment, Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDeleteModal from './ConfirmDeleteModal';

/**
 * Required props:
 *  id          --  some form of id that the modal may use to gather
 *                  information about the row when the cell is clicked.
 *  gatherInfo  --  function to be called to gather information about
 *                  the row when the cell is clicked.
 *  headerName  --  Name to be shown at the head of the modal.
 */

export default class DeleteActionRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.displayDeleteModal = this.displayDeleteModal.bind(this);
  }

  async handleClick() {
    this.setState({
      showDeleteModal: true,
      rowData: { loading: true }
    });

    let data = await this.props.gatherInfo(this.props.id);

    this.setState({
      rowData: data
    });
  }

  handleClose() {
    this.setState({
      showDeleteModal: false
    });
  }

  displayDeleteModal() {
    return (
      <ConfirmDeleteModal
        rowData={this.state.rowData}
        headerName={this.props.headerName}
        cancel_function={this.handleClose}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <div className="deleteButton" onClick={this.handleClick}>
          <DeleteIcon />
        </div>
        {this.state.showDeleteModal && this.displayDeleteModal()}
      </Fragment>
    );
  }
}
