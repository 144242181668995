import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * Required props:
 *  confirmFunction      --  function to be called when confirming action
 *  cancelFunction       --  function to be called to hide the modal
 *  title            --  name to be displayed on modal's head
 *  bodyText         --  Text shown in the body of the modal
 *
 */

export default class ConfirmationModal extends PureComponent {
  handleClick() {
    const { confirmFunction, cancelFunction } = this.props;

    confirmFunction();
    cancelFunction();
  }

  render() {
    const { cancelFunction, title, bodyText } = this.props;

    return (
      <Modal show onHide={cancelFunction}>
        <Modal.Header>
          <Modal.Title className="route-modal-title">{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{bodyText}</p>
        </Modal.Body>

        <Modal.Footer>
          <button type="submit" className="cancel-button" onClick={cancelFunction}>
            Cancel
          </button>

          <button
            type="submit"
            className="confirm-delete-button"
            onClick={this.handleClick.bind(this)}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  confirmFunction: PropTypes.func.isRequired,
  cancelFunction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired
};
