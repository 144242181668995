import React, { Component, Fragment } from 'react';

import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Card, CardBody } from 'mdbreact';

import _ from 'lodash';
import html2canvas from 'html2canvas';
import DepartmentsOrdersGraph from './DepartmentsOrdersGraph';
import DepartmentsExpediteOrdersGraph from './DepartmentsExpediteOrdersGraph';
import HistoricalBackLogGraph from './HistoricalBackLogGraph';

import './OrderBackLog.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import exportImage from 'img/export.png';
import Slider from 'react-slick';
import RestService from '../../services/RestService';

const orderTable = 'orderTable';
const expediteTable = 'expediteTable';
const disassociateString = 'Completed';

export default class OrderBackLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeButton: 'current',
      departments: [],
      departmentOrders: [],
      departmentExpediteOrders: [],
      departmentNameMap: {},
      tabKey: 1,
      width: window.innerWidth,
      table: orderTable
      // maxColumnGraph
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleTab = this.handleTab.bind(this);
  }

  insertionSort = (list, val, compareFn) => {
    list.unshift(val);

    for (let index = 1; index < list.length; index++) {
      const curr = list[index];

      if (compareFn(val, curr) > 0) {
        list[index - 1] = curr;
        list[index] = val;
      }
    }
  };

  handleSelect(oldIndex, newIndex) {
    let tableName = orderTable;
    if (newIndex === 1) {
      tableName = expediteTable;
    }

    this.setState({
      table: tableName
    });
  }

  takeScreenShots(e) {
    const value = e.target.value;

    // choosing b||w orderBackLogs and Expited Orders
    if (value === 'captureOrdersBackLogs') {
      html2canvas(document.querySelector('#captureOrderLogs')).then(function(canvas) {
        const a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
        a.download = 'CurrentOrderBackLog.jpg';
        a.click();
      });
    } else if (value === 'captureExpediteOrder') {
      html2canvas(document.querySelector('#captureExpediteOrder')).then(function(canvas) {
        const a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
        a.download = 'ExpediteOrder.jpg';
        a.click();
      });
    } else if (value === 'captureHistoricalgraph') {
      html2canvas(document.querySelector('#captureHistoricalgraph')).then(function(canvas) {
        const a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
        a.download = 'HistoricalLog.jpg';
        a.click();
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  async UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);

    const getSimpleName = fullName => {
      // if has white space
      if (/\s/g.test(fullName)) {
        const matches = fullName.match(/\b(\w)/g); // ['J','S','O','N']
        const acronym = matches.join('');
        return acronym;
      }

      if (fullName.length > 6) {
        return fullName.substring(0, 4);
      }
      return fullName;
    };

    const departments = await RestService.get('/departments');
    const backlogs = [];
    const expediteBacklogs = [];
    this.setState({ departments });

    for (let index = 0; index < departments.length; index++) {
      if (departments[index].department_name !== disassociateString) {
        const department = departments[index];
        const departmentId = department.department_id;
        const departmentName = department.department_name;
        const simpleName = getSimpleName(departmentName);

        RestService.get(`/tracked-objects/active/?department_id=${departmentId}`).then(orders => {
          const expediteOrders = _.filter(orders, order => {
            return order.is_expedited;
          });

          const totalOrders = orders.length;
          const totalExpeditedOrders = expediteOrders.length;
          const totalNonExpeditedOrders = totalOrders - totalExpeditedOrders;

          let currentThresholdState = 'normal';

          if (department.thresholds) {
            if (totalOrders > department.thresholds.orderThresholdCritical) {
              currentThresholdState = 'critical';
            } else if (totalOrders > department.thresholds.orderThresholdWarning) {
              currentThresholdState = 'warning';
            }
          }

          const backlog = {
            departmentId,
            departmentName,
            simpleName,
            numberOfNonExpeditedOrders: totalNonExpeditedOrders,
            numberOfExpeditedOrders: totalExpeditedOrders,
            totalOrders,
            currentThresholdState
          };

          const departmentBackLog = {};
          departmentBackLog[departmentId] = backlog;
          this.setState(departmentBackLog);

          // backlogs.push(backlog);
          this.insertionSort(backlogs, backlog, (newVal, existingVal) => {
            return existingVal.totalOrders - newVal.totalOrders; // desc order
          });

          this.insertionSort(expediteBacklogs, backlog, (newVal, existingVal) => {
            return existingVal.numberOfExpeditedOrders - newVal.numberOfExpeditedOrders;
          });

          this.setState({ departmentOrders: [...backlogs] });
          this.setState({ departmentExpediteOrders: [...expediteBacklogs] });

          // this.state.departmentOrders.push(backlog);
          this.state.departmentNameMap[simpleName] = departmentName;
        });
      }
    }
  }

  handleTab(key, name) {
    if (this.state.tabKey !== key) {
      this.setState({
        tabKey: key,
        activeButton: name
      });
    }
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.handleSelect,
      arrows: false
    };

    const { width } = this.state;
    const isMobile = width <= 500;

    const getTableViewWithData = (list, valueField) => {
      return (
        <div className="departments-links-detail">
          {list.map(department => {
            const backlog = this.state[department.departmentId];

            let totalOrders = 'Loading';
            if (backlog) {
              totalOrders = backlog[valueField];
            }

            return (
              <div className="department-link-row" key={department.departmentId}>
                <div className="department-name">{department.departmentName}</div>

                <div className="department-orders">{totalOrders}</div>
              </div>
            );
          })}
        </div>
      );
    };

    const getMobileTableView = () => {
      const tableInfo = {
        displayFieldName: '',
        list: [],
        fieldName: ''
      };
      if (this.state.table === orderTable) {
        tableInfo.displayFieldName = 'Current Tickets';
        tableInfo.list = this.state.departmentOrders;
        tableInfo.fieldName = 'totalOrders';
      } else if (this.state.table === expediteTable) {
        tableInfo.displayFieldName = 'Expedited Tickets';
        tableInfo.list = this.state.departmentExpediteOrders;
        tableInfo.fieldName = 'numberOfExpeditedOrders';
      }

      return (
        <div className="departments-links">
          <div className="departments-links-header">
            <div>Department</div>
            <div>{tableInfo.displayFieldName}</div>
          </div>

          <div className="departments-links-detail">
            {' '}
            {getTableViewWithData(tableInfo.list, tableInfo.fieldName)}
          </div>
        </div>
      );
    };

    const getDesktopTableViews = () => {
      return (
        <div className="department-tables">
          <div className="departments-links">
            <div className="departments-links-header">
              <div>Department</div>
              <div>Current Orders</div>
            </div>

            <div className="departments-links-detail">
              {getTableViewWithData(this.state.departmentOrders, 'totalOrders')}
            </div>
          </div>

          <div className="departments-links">
            <div className="departments-links-header">
              <div>Department</div>
              <div>Expedited Orders</div>
            </div>

            <div className="departments-links-detail">
              {getTableViewWithData(this.state.departmentExpediteOrders, 'numberOfExpeditedOrders')}
            </div>
          </div>
        </div>
      );
    };

    const getMobileGraph = () => {
      return (
        <Slider {...settings}>
          <div id="captureOrderLogs" className="backlog-graph-body">
            <h5 className="backlog-graph-title">Current Order Backlog</h5>
            <input
              className="export-icon"
              alt="export icon"
              type="image"
              src={exportImage}
              onClick={this.takeScreenShots}
              value="captureOrdersBackLogs"
            />
            <DepartmentsOrdersGraph
              departmentOrders={this.state.departmentOrders}
              departmentNameMap={this.state.departmentNameMap}
            />
          </div>
          <div id="captureExpediteOrder" className="backlog-graph-body">
            <h5 className="backlog-graph-title">Current Expedited Orders</h5>
            <input
              className="export-icon"
              alt="export icon"
              type="image"
              src={exportImage}
              onClick={this.takeScreenShots}
              value="captureExpediteOrder"
            />
            <DepartmentsExpediteOrdersGraph
              departmentOrders={this.state.departmentExpediteOrders}
              departmentNameMap={this.state.departmentNameMap}
            />
          </div>
        </Slider>
      );
    };

    const getDesktopGraph = () => {
      return (
        <div className="backlog-graphs">
          <div id="captureOrderLogs" className="backlog-graph-body">
            <h5 className="backlog-graph-title">Current Order Backlog</h5>
            <input
              className="export-icon"
              alt="export icon"
              type="image"
              src={exportImage}
              onClick={this.takeScreenShots}
              value="captureOrdersBackLogs"
            />
            <DepartmentsOrdersGraph
              departmentOrders={this.state.departmentOrders}
              departmentNameMap={this.state.departmentNameMap}
            />
          </div>
          <div id="captureExpediteOrder" className="backlog-graph-body">
            <h5 className="backlog-graph-title">Current Expedited Orders</h5>
            <input
              className="export-icon"
              alt="export icon"
              type="image"
              src={exportImage}
              onClick={this.takeScreenShots}
              value="captureExpediteOrder"
            />
            <DepartmentsExpediteOrdersGraph
              departmentOrders={this.state.departmentExpediteOrders}
              departmentNameMap={this.state.departmentNameMap}
            />
          </div>
        </div>
      );
    };

    const getDesktopHistoricalGraph = () => {
      return (
        <div>
          <div className="historical-graph-header">
            <h5 className="historical-graph-title">Historical Order Backlog</h5>
            <input
              className="export-icon"
              alt="export icon"
              type="image"
              src={exportImage}
              onClick={this.takeScreenShots}
              value="captureHistoricalgraph"
            />
          </div>
          <div id=" captureHistoricalgraph" className="backlog-graphs-historical">
            <div className="historical-graph-body">
              <HistoricalBackLogGraph />
            </div>
          </div>
        </div>
      );
    };

    const getMobileHistoricalGraph = () => {
      return (
        <Slider {...settings}>
          <div>
            <div className="historical-graph-header">
              <h5 className="historical-graph-title">Historical Order Backlog</h5>
              <input
                className="export-icon"
                alt="export icon"
                type="image"
                src={exportImage}
                onClick={this.takeScreenShots}
                value="captureHistoricalgraph"
              />
            </div>
            <div className="historical-graph-body">
              <HistoricalBackLogGraph />
            </div>
          </div>
        </Slider>
      );
    };

    const displayData = () => {
      if (this.state.activeButton === 'current') {
        return (
          <div className="order-backlog-body">
            {isMobile ? getMobileGraph() : getDesktopGraph()}
            {isMobile ? getMobileTableView() : getDesktopTableViews()}
          </div>
        );
      }
      return (
        <Fragment>{isMobile ? getMobileHistoricalGraph() : getDesktopHistoricalGraph()}</Fragment>
      );
    };

    return (
      <Fragment>
        <Card className="content_holder">
          <CardBody>
            <ButtonToolbar className="buttonbar">
              <ToggleButtonGroup className="backlog-toggle" type="radio" name="backlogs">
                <ToggleButton
                  className={this.state.activeButton === 'current' ? 'active' : ''}
                  value="current"
                  onClick={() => this.handleTab(1, 'current')}
                  bsClass="tabs"
                >
                  Current Backlog
                </ToggleButton>
                <ToggleButton
                  className={this.state.activeButton === 'historical' ? 'active' : ''}
                  value="historical"
                  onClick={() => this.handleTab(2, 'historical')}
                  bsClass="tabs tabs2"
                >
                  Historical Backlog
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
            {displayData()}
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}
