import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

/**
 * Required props:
 *  cancel_function       --  function to be called to hide the modal
 *  headerName            --  name to be displayed on modal's head
 *
 *
 *  rowData = {
 *      display_info      --  information to be displayed in the modal body
 *      confirm_function  --  function to be called if user confirms action
 *      delete_info       --  information required for deletion
 *
 *      loading           --  if true, then the modal's body doesn't show display_info
 *                            and shows  Loading...  instead. Also disables the
 *                            Submit Delete button.
 *  }
 */

export default class ConfirmDeleteModal extends Component {
  createUnorderedList(elements, loading) {
    if (loading) {
      return <div>Loading...</div>;
    }

    let listItems = Object.entries(elements).map(function(pair) {
      return (
        <li>
          {pair[0]}: {pair[1]}
        </li>
      );
    });
    return listItems;
  }

  async handleClick() {
    if (!this.props.rowData.loading) {
      this.props.rowData.confirm_function(this.props.rowData.delete_info);
      this.props.cancel_function();
    }
  }

  render() {
    let rowData = this.props.rowData;
    return (
      <Modal show={true} onHide={this.props.cancel_function}>
        <Modal.Header>
          <Modal.Title className="route-modal-title">Delete {this.props.headerName}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The following item will be deleted:</p>
          {this.createUnorderedList(rowData.display_info, rowData.loading)}
        </Modal.Body>

        <Modal.Footer>
          <button className="cancel-button" onClick={this.props.cancel_function}>
            Cancel
          </button>

          <button className="confirm-delete-button" onClick={this.handleClick.bind(this)}>
            Confirm Delete
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
