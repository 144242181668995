import React from 'react';
import './ItemListComponent.css';
import { Tabs, Tab, TabList } from 'react-web-tabs';
import { FormControl } from 'react-bootstrap';

export default class ItemListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKeyword: null
    };
  }

  tabAllClicked = event => {
    const item = { tagId: event.target.value, listId: this.props.listId };

    const list = [];
    this.props.options.forEach(each => {
      list.push(each.name);
    });
    this.props.handleSelected(item, list);
  };

  tabClicked = event => {
    const item = {
      tagId: event.target.value || null,
      listId: this.props.listId
    };
    this.props.handleSelected(item, [item.tagId]);
  };

  handleChange = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  renderSearchBar = () => {
    if (this.props.searchable) {
      return (
        <FormControl
          id="searchKeyword"
          type="text"
          placeholder="Search Vendors..."
          onChange={event => {
            this.handleChange(event.target.id, event.target.value);
          }}
        />
      );
    }
  };

  renderTabs = () => {
    let keyword = this.state.searchKeyword;
    if (keyword) {
      keyword = keyword.replace(/[^\w\s]/gi, '');
    }
    const regex = new RegExp(keyword, 'gi');
    return this.props.options.map(each => {
      if ((!this.state.searchKeyword || each.name.match(regex)) && each.display) {
        return (
          <Tab
            key={each.name}
            id={each.name}
            className={
              this.props.selected &&
              this.props.selected.tagId === each.name &&
              this.props.selected.listId === this.props.listId
                ? 'tab-style-selected'
                : 'tab-style'
            }
            onClick={this.tabClicked}
            value={each.name}
            tabFor={!each.name ? 'No Vendor' : each.name}
          >
            {each.name || 'No Vendor'}
            {this.props.showDigit ? (each.data ? ` (${each.data})` : '') : null}
          </Tab>
        );
      }
      return null;
    });
  };

  renderTitleTab = () => {
    let totalNumber = 0;
    this.props.options.forEach(each => {
      if (each.data) {
        totalNumber += each.data;
      }
    });
    if (totalNumber <= 0 && this.props.listId === 'new-requests') {
      return <div className="tab-style-no-requests"> No New Requests </div>;
    }
    return (
      <Tab
        id={`All ${this.props.title}`}
        className={
          this.props.selected &&
          this.props.selected.tagId === `All ${this.props.title}` &&
          this.props.selected.listId === this.props.listId
            ? 'tab-style-selected'
            : 'tab-style'
        }
        onClick={this.tabAllClicked}
        value={`All ${this.props.title}`}
        tabFor={`All${this.props.title}`}
      >
        {`All ${this.props.title}`}
      </Tab>
    );
  };

  render() {
    return (
      <Tabs defaultTab="vertical-tab-one">
        <div className="menu-title">{this.props.title}</div>
        {this.renderSearchBar()}
        <TabList className="vertical-tag">
          {this.renderTitleTab()}
          {this.renderTabs()}
        </TabList>
      </Tabs>
    );
  }
}
