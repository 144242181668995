import config from '../config';

const CustomerIdKey = 'ConfigurationService_customerId';

function loadCustomerIdFromCache() {
  return sessionStorage.getItem(CustomerIdKey);
}

/**
 * This
 */
class ConfigurationService {
  /**
   * Register customerId to create a stateful session
   * @param customerId
   */
  registerCustomerId(customerId) {
    // check customerId is valid before register it.
    if (!config.customerIDtoCustomerMap[customerId]) {
      const err = {
        code: 'AccountNotFoundException'
      };
      throw err;
    }
    this.customerId = customerId;
    sessionStorage.setItem(CustomerIdKey, customerId);
  }

  isCustomerAccountRegistered() {
    if (this.customerId) {
      return true;
    }

    return loadCustomerIdFromCache();
  }

  getSolutionType() {
    const configs = this.getFullConfigurations();
    return configs.solutionType;
  }

  getCustomerRules() {
    const configs = this.getFullConfigurations();
    return configs.rules;
  }

  getSolutionConfiguration() {
    const customerConfig = this.getFullConfigurations();
    const solutionType = customerConfig.solutionType;
    return config.solutionTypes[solutionType];
  }

  /**
   * Get a full configurations for session customer
   * @return {*}
   */
  getFullConfigurations() {
    const customerName = this.getCustomerName();
    const customerConfig = config.customers[customerName];
    return customerConfig;
  }

  /**
   * Retrieve the REST service configuration
   * @return {{API: {endpoints: {name: string, endpoint: (string), region: string}[]}}}
   */
  getRestServiceConfiguration() {
    const customerConfig = this.getFullConfigurations();

    const configuration = {
      API: {
        endpoints: [
          {
            name: 'xemelgo',
            endpoint: customerConfig.apiGateway.URL,
            region: customerConfig.apiGateway.REGION
          }
        ]
      },
      imageBucket: {
        region: customerConfig.s3.REGION,
        endpoint: customerConfig.s3.URL
      }
    };

    return configuration;
  }

  /**
   * Retrieve the Auth Service configuration
   * @return {{Auth: {mandatorySignIn: boolean, region: string, userPoolId: (string), identityPoolId: string, userPoolWebClientId: (string)}}}
   */
  getAuthConfiguration() {
    const customerConfig = this.getFullConfigurations();
    const configuration = {
      Auth: {
        mandatorySignIn: true,
        region: customerConfig.cognito.REGION,
        userPoolId: customerConfig.cognito.USER_POOL_ID,
        identityPoolId: customerConfig.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: customerConfig.cognito.APP_CLIENT_ID
      }
    };

    return configuration;
  }

  getCustomerName() {
    if (!this.customerId) {
      this.customerId = loadCustomerIdFromCache();
    }

    const err = {
      code: 'AccountNotFoundException'
    };

    const customerName = config.customerIDtoCustomerMap[this.customerId];
    if (!customerName) {
      throw err;
    }

    return customerName;
  }
}

export default new ConfigurationService();
