import React from 'react';
import Loading from 'components/loading/LoadingWord';
import ConfigurationService from './ConfigurationService';
import RestService from './RestService';
import LowStockRuleContent from '../pages/alert/LowStockRuleContent';
import OverstockRuleContent from '../pages/alert/OverstockRuleContent';
import ExpeditedRuleContent from '../pages/alert/ExpeditedRuleContent';
import ArrivedAtRuleContent from '../pages/alert/ArrivedAtRuleContent';
import MissingRuleContent from '../pages/alert/MissingRuleContent';
import ExpiredRuleContent from '../pages/alert/ExpiredRuleContent';

export const temporaryComponent = () => (
  <div>
    Under Construction
    <Loading />
  </div>
);

const lowStockRuleTemplate =
  // eslint-disable-next-line no-template-curly-in-string
  'Stock count for ${this.object_name} is low. You have ${this.value} left in stock, which is below the threshold of ${this.threshold_value}.';
const lowStockRuleTemplateEmail =
  'Stock count for <strong>${this.object_name}</strong> is low. You have <strong>${this.value}</strong> left in stock, which is below the threshold of <strong>${this.threshold_value}</strong>.';
// eslint-disable-next-line no-template-curly-in-string
const OverstockRuleTemplate =
  'Stock count for ${this.object_name} is high. You have ${this.value} in stock, which is above the threshold of ${this.threshold_value}.';
// eslint-disable-next-line no-template-curly-in-string
const OverstockRuleTemplateEmail =
  'Stock count for <strong>${this.object_name}</strong> is high. You have <strong>${this.value}</strong> in stock, which is above the threshold of <strong>${this.threshold_value}</strong>.';
// eslint-disable-next-line no-template-curly-in-string
const expeditedRuleTemplate = 'Order ${this.object_name} has been expedited.';
// eslint-disable-next-line no-template-curly-in-string
const orderArrivedAtRuleTemplate =
  'Order ${this.tracked_object.object_name} has arrived at ${this.department.department_name} department.';
// eslint-disable-next-line no-template-curly-in-string
const missingRuleTemplate =
  'Asset ${this.object_name} ${this.miss_found_status === "Missing" ? "is missing." : "has been found."}';

const expiredRuleTemplate =
  'Asset ${this.object_name} ${Date.now() > this.due_date? "has expired" : "is expiring"} on ${this.format_date}.';

const valString = 604800000;

export const ruleInfoMap = {
  'Low Stock': {
    eventId: 'track-object-type-count-updated',
    notification_template: {
      email: lowStockRuleTemplateEmail,
      sms: lowStockRuleTemplate,
      alert: lowStockRuleTemplate
    },
    component: ruleId => {
      return () => <LowStockRuleContent ruleId={ruleId} />;
    }
  },
  Overstock: {
    eventId: 'track-object-type-count-updated',
    notification_template: {
      email: OverstockRuleTemplateEmail,
      sms: OverstockRuleTemplate,
      alert: OverstockRuleTemplate
    },
    component: ruleId => {
      return () => <OverstockRuleContent ruleId={ruleId} />;
    }
  },
  'Arrived At': {
    eventId: 'analyzed-tracker-event-historical-count-by-department',
    notification_template: {
      email: orderArrivedAtRuleTemplate,
      sms: orderArrivedAtRuleTemplate,
      alert: orderArrivedAtRuleTemplate
    },
    component: ruleId => {
      return () => <ArrivedAtRuleContent ruleId={ruleId} />;
    }
  },
  Expedited: {
    eventId: 'tracked-object-expedited',
    notification_template: {
      email: expeditedRuleTemplate,
      sms: expeditedRuleTemplate,
      alert: expeditedRuleTemplate
    },
    component: () => {
      return () => <ExpeditedRuleContent />;
    },
    conditions: [
      {
        rule_condition_name: 'expedite',
        tags: {
          target: 'tracked_object'
        }
      }
    ]
  },
  Missing: {
    eventId: 'tracked-object-missing',
    notification_template: {
      email: missingRuleTemplate,
      sms: missingRuleTemplate,
      alert: missingRuleTemplate
    },
    component: () => {
      return () => <MissingRuleContent />;
    },
    conditions: [
      {
        rule_condition_name: 'missing',
        tags: {
          target: 'tracked_object'
        }
      }
    ]
  },
  Expired: {
    eventId: 'tracked-object-expired',
    notification_template: {
      email: expiredRuleTemplate,
      sms: expiredRuleTemplate,
      alert: expiredRuleTemplate
    },
    component: () => {
      return () => <ExpiredRuleContent />;
    },
    conditions: [
      {
        rule_condition_name: 'expired',
        enabled: true,
        tags: {
          target: 'tracked_object'
        },
        conditions: [
          {
            property: 'due_date',
            op: '<=',
            value: {
              value: valString,
              sqlString: `timestamp() + ${valString}`
            }
          }
        ]
      }
    ]
  }
};

class AlertService {
  async asyncForEach(list, asyncAction) {
    if (!list || !Array.isArray(list)) {
      return;
    }

    for (const index in list) {
      const item = list[index];
      await asyncAction(item, index);
    }
  }

  async getNotificationRecipients() {
    return RestService.get('/notification-settings/recipients').then(result => {
      return result;
    });
  }

  async updateNotificationSubscription(payload) {
    this.fetchRules().then(result => {
      RestService.put('/notification-settings/recipients', {
        recipients: payload
      }).then(() => {
        for (const eachRule in result.ruleNotificationMap) {
          RestService.put(
            `/rules/${result.ruleNotificationMap[eachRule].ruleId}/notification-settings`,
            { enabled: result.ruleNotificationMap[eachRule].enabled }
          );
        }
      });
    });
  }

  async fetchRules() {
    const rulesList = ConfigurationService.getCustomerRules();
    const ruleNotificationMap = {};
    const rulesOfInterest = rulesList;

    const rules = await RestService.get('/rules');
    await this.asyncForEach(rules, async rule => {
      const ruleName = rule.name;
      const ruleId = rule.rule_id;

      // register only those rules of interest
      if (rulesOfInterest.includes(ruleName)) {
        const notificationSettings = await RestService.get(
          `/rules/${ruleId}/notification-settings`
        );

        ruleNotificationMap[ruleName] = {
          ruleId,
          enabled: notificationSettings.enabled,
          notification_template: rule.notification_template
        };
      }
    });

    // create any missing rule in the list of ruleOfInterests
    await this.asyncForEach(rulesOfInterest, async ruleName => {
      let rule = ruleNotificationMap[ruleName];

      // create rule if it does not exist
      if (!rule) {
        const eventId = ruleInfoMap[ruleName].eventId;

        const notificationTemplate = ruleInfoMap[ruleName].notification_template;
        const payload = {
          name: ruleName,
          event_id: eventId,
          enabled: true,
          notification_template: notificationTemplate
        };
        const createdRule = await RestService.post('/rules', payload);

        rule = {
          ruleId: createdRule.rule_id,
          enabled: false, // notification is always false when rule is first created.
          notification_template: createdRule.notification_template
        };
        ruleNotificationMap[ruleName] = rule;
      }

      // create rule condition(s) if configuration requests for them
      const conditionRequests = ruleInfoMap[ruleName].conditions;
      if (conditionRequests) {
        const existingConditionMap = (await RestService.get(
          `/rules/${rule.ruleId}/conditions`
        )).reduce((map, condition) => {
          map[condition.rule_condition_name] = condition;
          return map;
        }, {});

        const missingConditionRequests = conditionRequests.filter(request => {
          const conditionName = request.rule_condition_name;
          return !existingConditionMap[conditionName];
        });

        await this.asyncForEach(missingConditionRequests, async payload => {
          await RestService.post(`/rules/${rule.ruleId}/conditions`, payload);
        });
      }
    });

    const notificationPreference = await RestService.get('/notification-settings/recipients');
    const notificationSubscriptionFeatureEnabled = notificationPreference.recipients.length > 0;

    return {
      ruleNotificationMap,
      notificationFeatureEnabled: notificationSubscriptionFeatureEnabled
    };
  }
}

export default new AlertService();
