import React from 'react';
import Moment from 'react-moment';
import ConfigurationService from 'services/ConfigurationService';
import TrackedObjectService from '../../services/TrackedObjectService';
import { getFormattedDate } from '../../common/Utilities';

const detailsUtils = {
  getTrackerSerial(info) {
    return info.tracker_serial || '-';
  },

  getLocation(info, recentTime) {
    if (info.is_active) {
      return (
        <div className="description-section">
          <div>{info.zone ? info.zone.zone_name : 'Not Detected'}</div>
          <div>
            {info.department ? info.department.department_name : 'Not Detected'}
            {this.getTime(recentTime)}
          </div>
        </div>
      );
    }
    return 'Completed';
  },

  getTime(time) {
    return ` at
        ${getFormattedDate(time, 'hh:mm A')}
        on
        ${getFormattedDate(time, 'MMM Do')}
      `;
  },

  getEditActionButtons(enableEdit, cancelFn, saveFn) {
    if (enableEdit) {
      return (
        <div className="edit-form-buttons">
          <button className="cancel-button" onClick={cancelFn}>
            Cancel
          </button>
          <button className="default-button" onClick={saveFn}>
            Save
          </button>
        </div>
      );
    }
  },

  msToTime(duration) {
    const seconds = parseInt(Math.floor((duration / 1000) % 60), 10);

    const minutes = parseInt(Math.floor(duration / (1000 * 60)) % 60, 10);

    const hours = parseInt(Math.floor((duration / (1000 * 60 * 60)) % 24), 10);

    const days = parseInt(Math.floor((duration / (1000 * 60 * 60 * 24)) % 31), 10);

    const months = parseInt(Math.floor((duration / (1000 * 60 * 60 * 24 * 31)) % 12), 10);

    const years = parseInt(Math.floor(duration / (1000 * 60 * 60 * 24 * 31 * 12)), 10);

    let time = '';
    if (years > 0) {
      time += `${years}y `;
    }
    if (months > 0) {
      time += `${months}mo `;
    }
    if (days > 0) {
      time += `${days}d `;
    }
    if (hours > 0) {
      time += `${hours}h `;
    }
    if (minutes > 0) {
      time += `${minutes}m `;
    }
    if (seconds > 0 || time === '') {
      time += `${seconds}s`;
    }

    return time || '--';
  },

  getLatestTimeDataFromRoute(route) {
    const length = route.zones.length;
    const enterTime = route.enter_timestamp;
    const exitTime = route.zones[length - 1].exit_timestamp;

    return {
      entryTimestamp: <Moment format="HH:mm A on MM/DD/YY">{enterTime}</Moment>,
      exitTimestamp: exitTime ? <Moment format="HH:mm A on MM/DD/YY">{exitTime}</Moment> : '--',
      departmentDuration: exitTime
        ? detailsUtils.msToTime(exitTime - enterTime)
        : detailsUtils.msToTime(Date.now() - enterTime)
    };
  },

  getZoneDurationForStage(zone) {
    const enterTime = zone.enter_timestamp;
    const exitTime = zone.exit_timestamp;

    return {
      entryTimestamp: <Moment format="HH:mm A on MM/DD/YY">{enterTime}</Moment>,
      exitTimestamp: exitTime ? <Moment format="HH:mm A on MM/DD/YY">{exitTime}</Moment> : '--',
      departmentDuration: exitTime
        ? detailsUtils.msToTime(exitTime - enterTime)
        : detailsUtils.msToTime(Date.now() - enterTime)
    };
  },

  getExpandedRows(currentExpandedRows, rowId) {
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);
    return newExpandedRows;
  },

  getOrderRouteView(that) {
    const rowData = that.state.info.order_route;
    const getLatestTimeDataFromRoute = detailsUtils.getLatestTimeDataFromRoute;
    const getZoneDurationForStage = detailsUtils.getZoneDurationForStage;

    return (
      <div className="tracking-department-tables">
        <div className="tracking-departments-links">
          <div className="tracking-links-header">
            <div className="time-detail">Location</div>
            <div className="time-detail">Entry Time</div>
            <div className="time-detail">Exit Time</div>
            <div className="time-detail">Time Duration</div>
          </div>
          <div className="tracking-departments-links-detail">
            <div className="tracking-departments-links-detail">
              {rowData.map(route => {
                // commented out for Asset Tracking Soltuion
                const clickCallback = () =>
                  that.handleRowClick(that, `${route.department_id}${route.enter_timestamp}`);
                const itemRows = [
                  <td
                    className="tracking-department-link-row"
                    onClick={clickCallback}
                    key={`${route.department_id}${route.enter_timestamp}`}
                  >
                    <div className="time-duration"> {route.department_name} </div>
                    <div className="time-duration">
                      {' '}
                      {getLatestTimeDataFromRoute(route).entryTimestamp}
                    </div>
                    <div className="time-duration">
                      {' '}
                      {getLatestTimeDataFromRoute(route).exitTimestamp}
                    </div>
                    <div className="time-duration">
                      {' '}
                      {getLatestTimeDataFromRoute(route).departmentDuration}
                    </div>
                  </td>
                ];
                // commented out for Asset Tracking Soltuion
                route.zones.forEach(function(zone) {
                  if (
                    that.state.expandedRows.includes(
                      `${route.department_id}${route.enter_timestamp}`
                    )
                  ) {
                    itemRows.push(
                      <td
                        className="tracking-department-link-row"
                        key={`${route.department_id}${route.enter_timestamp}`}
                      >
                        <div className="expanded-tracking-department-name"> {zone.zone_name} </div>
                        <div className="expanded-time-duration">
                          {' '}
                          {getZoneDurationForStage(zone).entryTimestamp}
                        </div>
                        <div className="expanded-time-duration">
                          {' '}
                          {getZoneDurationForStage(zone).exitTimestamp}
                        </div>
                        <div className="expanded-time-duration">
                          {' '}
                          {getZoneDurationForStage(zone).departmentDuration}
                        </div>
                      </td>
                    );
                  }
                });
                return itemRows;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  },

  async editImage(event, that) {
    if (event && event.target && event.target.files[0]) {
      const file = event.target.files[0];
      let image_info = null;
      image_info = await TrackedObjectService.uploadObjectImage(
        file,
        that.state.info.object_id
      ).then(imageInfo => {
        return imageInfo;
      });

      if (image_info) {
        const customerConfig = ConfigurationService.getFullConfigurations();
        const imgUrl = `${customerConfig.s3.URL}/${image_info.image.image_url}`;

        that.setState({
          image_info: imgUrl,
          imageSuccess: true
        });
      }
      that.setOrderState();
    }
  }
};

export default detailsUtils;
