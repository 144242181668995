import 'babel-polyfill'; // IE11 Compatibility Import for 'Promise' and 'Object'
import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ConfigurationService from './services/ConfigurationService';
import AuthService from './services/AuthService';
import RestService from './services/RestService';

if (ConfigurationService.isCustomerAccountRegistered()) {
  AuthService.configure(ConfigurationService.getAuthConfiguration());
  RestService.configure(ConfigurationService.getRestServiceConfiguration());
}

ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();
