import React, { Component } from 'react';
import { NavbarBrand, Navbar, Collapse, NavbarToggler } from 'mdbreact';
import logo from 'logo/Xemelgo-Sticker-v1.png';
import './PageHeader.css';

export default class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
      dropdownOpen: false
    };
    this.onClick = this.onClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    return (
      <Navbar id="main-navbar" color="white" light expand="md" fixed="top" scrolling>
        <NavbarBrand href="/">
          <img id="header-logo" src={logo} alt="" height="32" />
        </NavbarBrand>

        {!this.state.isWideEnough && <NavbarToggler onClick={this.onClick} />}
        <Collapse isOpen={this.state.collapse} navbar>
          {this.props.children}
        </Collapse>
      </Navbar>
    );
  }
}
