import React, { Component } from 'react';
import ConfigurationService from '../../services/ConfigurationService';

export default class ExpiredRuleContent extends Component {
  render() {
    const solutionType = ConfigurationService.getSolutionType();
    return (
      <div>
        <div>
          <div>
            <p className="tabbedSectionComponent-contentTitle">Expired</p>
            <p style={{ color: '#343434' }}>
              {`When an ${solutionType} is expiring within a certain time, or if an ${solutionType} is expired, a notification will be sent.`}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
