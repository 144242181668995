import RestService from './RestService';
import ConfigurationService from './ConfigurationService';

const TIMESPAN = 'week';

export default class AssetTrackService {
  constructor(snapData, tagData, missingAssetHistoryData) {
    const inventoryCount = {};

    snapData.forEach(each => {
      inventoryCount[each.tracked_object_id] = inventoryCount[each.tracked_object_id]
        ? inventoryCount[each.tracked_object_id] + 1
        : 1;
    });

    const config = ConfigurationService.getRestServiceConfiguration();
    this.trackData = {};
    snapData.forEach(object => {
      this.trackData[object.tracked_object_id] = object;
      if (object.image) {
        this.trackData[
          object.tracked_object_id
        ].image_url = `${config.imageBucket.endpoint}/${object.image.image_url}`;
      } else {
        this.trackData[object.tracked_object_id].image_url = null;
      }
    });

    this.tagMap = {};
    tagData.forEach(tag => {
      this.tagMap[tag.tracker_id] = tag.tracker_serial;
    });

    this.missingAssetHistoryData = missingAssetHistoryData;
  }

  getWeeklyStatus() {
    const weeklyStatus = {
      // weeklyStatsLabel1: '',
      weeklyStatsLabel2: 'Assets Went Missing',
      // weeklyStats1: null,
      weeklyStats2: 0
    };

    this.missingAssetHistoryData.forEach(each => {
      if (this.trackData[each.object_type_id]) {
        weeklyStatus.weeklyStats2++;
      }
    });

    return weeklyStatus;
  }

  getOverviewData() {
    const overview = {
      overviewDataLabel1: 'Total No. of Assets',
      overviewDataLabel2: 'No. of Missing Assets',
      overviewData1: 0,
      overviewData2: 0
    };

    Object.keys(this.trackData).forEach(eachkey => {
      overview.overviewData1++;
      if (
        this.trackData[eachkey].tags &&
        this.trackData[eachkey].tags.miss_found_status === 'Missing'
      )
        overview.overviewData2++;
    });

    return overview;
  }

  getTrackDataList() {
    const objectList = [];
    Object.keys(this.trackData).forEach(eachkey => {
      const times = [];

      const time =
        this.trackData[eachkey].received_timestamp || this.trackData[eachkey].published_timestamp;
      times.push(new Date(time));
      this.trackData[eachkey].lastUpdated =
        times.length > 0 ? Math.max.apply(null, times) : undefined;
      objectList.push(this.trackData[eachkey]);
    });

    const sortedList = objectList.sort((a, b) => {
      return a.object_name.localeCompare(b.object_name);
    });

    return sortedList;
  }

  getTrackerMap = () => {
    return this.tagMap;
  };

  static async build(searchObject) {
    let snapData = await RestService.get('/tracked-objects/active');
    snapData = snapData.filter(object => {
      return object.object_class === 'order';
    });

    const tagData = await RestService.get('/trackers');
    if (searchObject.value) {
      if (searchObject.type === 'Item') {
        snapData = snapData.filter(object => {
          return object.object_name.split(' ')[0] === searchObject.value;
        });
      } else if (searchObject.type === 'Location') {
        snapData = snapData.filter(object => {
          return object.department.department_id === searchObject.value;
        });
      }
    }
    const plant = await RestService.get('/plants');

    const missingAssetHistoryData =
      plant.length !== 0
        ? await RestService.get(
            `/analytics/missing-asset-history?plant_id=${plant[0].plant_id}&timespan=${TIMESPAN}`
          )
        : [];
    return new AssetTrackService(snapData, tagData, missingAssetHistoryData);
  }

  async updateAssetDetails(object_id, assetPayload) {
    const payload = buildUpdatePayload(assetPayload);
    return RestService.patch(`/tracked-objects/${object_id}`, payload).then(result => {
      return result;
    });
  }
}

const buildUpdatePayload = object => {
  const payload = [];
  Object.keys(object).forEach(key => {
    payload.push({ op: 'replace', path: `/${key}`, value: object[key] });
  });
  return payload;
};
