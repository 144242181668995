import React, { PureComponent, Fragment } from 'react';
import { Tabs, Tab, TabList } from 'react-web-tabs';
import Style from './css/SideFilter.module.css';

const tabContents = [
  {
    name: 'Location'
  }
];

export default class SideFilter extends PureComponent {
  tabClicked = event => {
    const item = event.target.value;
    this.props.handleSelected(item);
  };

  render() {
    if (!this.props.filter) {
      return (
        <Fragment>
          <div className={`${Style.area}`}>
            <Tabs>
              <Tab
                className={`${
                  this.props.selectedFilter && this.props.selectedFilter === 'All Orders'
                    ? Style.selectable_menu_title_selected
                    : Style.selectable_menu_title
                }`}
                onClick={this.tabClicked}
                value={'All Orders'}
              >
                All Orders
              </Tab>
              <div className={`${Style.menu_title}`}>Group By</div>
              <TabList className={`${Style.vertical_tag}`}>
                {tabContents.map(each => {
                  return (
                    <Tab
                      key={each.name}
                      id={each.name}
                      className={
                        this.props.selectedFilter && this.props.selectedFilter === each.name
                          ? `${Style.filter_tab_style_selected}`
                          : `${Style.filter_tab_style}`
                      }
                      onClick={this.tabClicked}
                      value={each.name}
                      tabFor={each.name}
                    >
                      {each.name}
                    </Tab>
                  );
                })}
              </TabList>
            </Tabs>
          </div>
        </Fragment>
      );
    }
    return null;
  }
}
