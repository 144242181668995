import React, { Component, Fragment } from 'react';
import { Card, CardBody, Input } from 'mdbreact';
import { Modal, Alert } from 'react-bootstrap';
import './UserProfilePage.css';
import ProfileForm from '../../components/ProfileForm';
import { isValidPhoneNumber } from 'react-phone-number-input';

import AlertService from '../../services/AlertService';
import RestService from '../../services/RestService';
import AuthService from '../../services/AuthService';
import ConfigurationService from '../../services/ConfigurationService';

const attributeNameToProfileNameMap = {
  'custom:title': 'jobTitle',
  given_name: 'givenName',
  family_name: 'familyName',
  email: 'email',
  phone_number: 'phone',
  'custom:profile_image_url': 'imageOptional'
};

/**
 * This method contains the knowledge of how data is stored in the state.
 *  It compares the default value and newly entered value and construct proper payload if value has changed.
 * @param state
 * @return {*}
 */
function buildUpdateAttributePayload(state) {
  //reverse the map: profile name attribute name
  const nameMap = Object.keys(attributeNameToProfileNameMap).reduce((map, key) => {
    const value = attributeNameToProfileNameMap[key];
    map[value] = key;
    return map;
  }, {});

  const profileFieldNames = Object.keys(nameMap);

  //compare new and existing value, only update when new value is different than existing one
  let payload = null;
  profileFieldNames.forEach(field => {
    let newValue = state.payload[field];

    if (!newValue) {
      return;
    }

    newValue = newValue.trim();
    const existingValue = state.current[field] && state.current[field].trim();
    if (newValue === existingValue) {
      return;
    }

    // only create payload if there is at least one field has changed
    if (!payload) {
      payload = {};
    }
    const attributeName = nameMap[field];
    payload[attributeName] = newValue;
  });

  return payload;
}

function mapSessionInfoToProfileInfo(sessionInfo) {
  const profileInfo = {};
  const attributeMap = sessionInfo.attributes;

  if (attributeMap) {
    profileInfo.username = sessionInfo.username;

    const nameMap = attributeNameToProfileNameMap;
    Object.entries(nameMap).forEach(([attributeName, profileName]) => {
      profileInfo[profileName] = attributeMap[attributeName];
    });
  }

  return profileInfo;
}

/**
 * React's component simple form
 * @param className
 * @param disabled
 * @param value
 * @param label
 * @param id
 * @param onInput
 * @param type
 * @param hint
 * @return {*}
 * @constructor
 */

export default class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: {},
      payload: {},
      editable: {
        givenName: true,
        familyName: true,
        username: false,
        email: false,
        phone: true,
        jobTitle: true
      },
      customerConfiguration: undefined,
      inputEnabled: false,
      isModal: false,
      passwordChangeSuccess: false,
      imageOptional: undefined,
      imageOptionalInput: undefined,
      file: '',
      imageObject: '',
      isValidPhoneNumber: true
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    this.fetchUserProfile();
  }

  fetchUserProfile() {
    const sessionInfo = AuthService.getSessionInfo();
    const profileInfo = mapSessionInfoToProfileInfo(sessionInfo);
    this.setState({
      current: { ...profileInfo },
      payload: {},
      inputEnabled: false
    });
  }

  async editImage() {
    const context = this;

    await RestService.configure(ConfigurationService.getRestServiceConfiguration());
    await RestService.uploadImage('/images', this.state.file, this.state.file.type).then(
      img => {
        const payload = { ...context.state.payload };
        payload.imageOptional = img.url;

        context.setState({
          payload: payload
        });
      },
      err => {
        alert('Fail to upload image: ' + err);
      }
    );
  }

  /**
   * handle input change event
   * @param event
   */
  handleChange = (id, value) => {
    this.setState({
      payload: {
        ...this.state.payload,
        [id]: value
      }
    });
  };

  /**
   * listen to new file upload event
   * @param event
   */
  onFileChange = event => {
    if (event && event.target && event.target.files) {
      this.setState({
        file: event.target.files[0]
      });
    }
  };

  /**
   * Open password change modal
   * @param event
   * @return {Promise<void>}
   */
  handleChangePasswordModal = async event => {
    event.preventDefault();

    this.setState({ isModal: true });
  };

  /**
   * Toggle password change modal
   * @return {Promise<void>}
   */
  toggleModal = async () => {
    this.setState({
      isModal: !this.state.isModal
    });
  };

  /**
   * Perform password change
   * @param event
   * @return {Promise<void>}
   */
  handleChangePassword = async event => {
    event.preventDefault();
    const context = this;
    AuthService.changePassword(
      this.state.payload.currentPassword,
      this.state.payload.newPassword
    ).then(
      () => {
        context.setState({ isModal: false, passwordChangeSuccess: true });
        context.setState({
          payload: {}
        });
      },
      err => {
        alert(err.message);
      }
    );
  };

  /**
   * Switch to edit profile mode
   * @param event
   * @return {Promise<void>}
   */
  handleSubmitEditProfile = async event => {
    event.preventDefault();
    if (!this.state.imageOptional || !this.state.imageOptional.trim()) {
      this.setState({
        imageOptional: require('../../img/default_user_profile.png')
      });
    }
    this.setState({
      inputEnabled: true
    });
  };

  /**
   * Cancel the edit form
   * @param event
   * @return {Promise<void>}
   */
  handleCancelEditProfile = async event => {
    event.preventDefault();
    this.setState({
      inputEnabled: false,
      payload: {},
      file: null
    });
  };

  /**
   * Update profile if there is any change made
   * @param event
   * @return {Promise<void>}
   */
  handleSubmitChangeProfile = async event => {
    event.preventDefault();
    if (this.state.file) {
      await this.editImage();
    }
    // validate phone number format
    if (this.state.phoneNumberInput && this.state.phoneNumberInput.trim()) {
      let phoneNumber = this.state.phoneNumberInput.replace(/\D/g, '');
      if (phoneNumber.length < 10) {
        alert(
          `Incorrect phone number format ${this.state.phoneNumberInput}. Phone number must contains country code and area code.`
        );
        return;
      }
    }
    const payload = buildUpdateAttributePayload(this.state);
    if (!payload) {
      // when there is no change in profile, do nothing
      return;
    }
    try {
      await AuthService.updateAttributes(payload);
      if (payload['phone_number']) {
        AlertService.getNotificationRecipients().then(result => {
          let newPayload = [];
          result.recipients.forEach(recipient => {
            if (this.isEmail(recipient)) {
              newPayload.push(recipient);
            }
          });
          newPayload.push(payload['phone_number']);
          AlertService.updateNotificationSubscription(newPayload);
        });
      }
      this.fetchUserProfile();
    } catch (err) {
      alert('Something is wrong: ' + err.message);
      console.error(err);
    }
  };

  /**
   * validate and ensure new password and confirm new password match
   * @return {string|boolean}
   */
  isValidPasswordForm = () => {
    return (
      this.state.payload.currentPassword &&
      this.state.payload.currentPassword.length > 0 &&
      this.state.payload.newPassword &&
      this.state.payload.newPassword.length > 0 &&
      this.state.payload.newPassword === this.state.payload.confirmNewPassword
    );
  };

  /**
   * Create a top banner indicating operation succeeds
   * @return {*}
   */
  createPasswordChangeSuccessBanner = () => {
    return (
      <Alert className="change-password-alerts" bsStyle="success" onDismiss={this.handleDismiss}>
        <h4>Success!</h4>
        <p>Your password has been changed!</p>
      </Alert>
    );
  };

  /**
   * Handle cancellation of password change operation
   */
  handleDismiss = () => {
    this.setState({
      passwordChangeSuccess: false,
      payload: {}
    });
  };

  canBeSubmitted = () => {
    const errors = this.validateCreateUserForm();
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    return !isDisabled;
  };

  shouldMarkError = field => {
    const errors = this.validateCreateUserForm();
    const hasError = errors[field];
    return hasError;
  };

  validatePhoneNumber = () => {
    let valid = true;
    if (this.state.payload.phone) {
      valid = isValidPhoneNumber(this.state['payload'].phone);
    }
    this.setState({ isValidPhoneNumber: valid });
    return valid;
  };

  isEmail = email => {
    return /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/g.test(email);
  };

  validateCreateUserForm = () => {
    return {
      givenName: false,
      familyName: false,
      username: /\s/g.test(this.state['payload'].username),
      email: this.state['payload'].email && !this.isEmail(this.state['payload'].email),
      phone: this.state['payload'].phone && !this.state.isValidPhoneNumber
    };
  };

  render() {
    const profileImage = this.state.current.imageOptional
      ? this.state.current.imageOptional
      : require('../../img/default_user_profile.png');
    return (
      <Fragment>
        {this.state.passwordChangeSuccess === true && this.createPasswordChangeSuccessBanner()}
        <Card className="profile-card">
          <div className="responsive-container">
            <img src={profileImage} alt="profile pic" />
          </div>
          <CardBody className="body-styling">
            <div className="user-profile-title">Your Profile</div>
            <div>
              <ProfileForm
                clean={!this.state.inputEnabled}
                handleChange={this.handleChange}
                shouldMarkError={this.shouldMarkError}
                hint={this.state.current}
                disabled={!this.state.inputEnabled}
                editable={this.state.editable}
                validatePhoneNumber={this.validatePhoneNumber}
              />

              {this.state.inputEnabled && (
                <Input
                  className="input-text-enabled"
                  hint=" "
                  type="file"
                  id="imageDetails"
                  label="Change Profile Photo"
                  onChange={this.onFileChange}
                />
              )}
            </div>

            {this.state.inputEnabled && (
              <div className="profile-form-buttons">
                <button className="cancel-button" onClick={this.handleCancelEditProfile}>
                  Cancel
                </button>
                <button
                  disabled={!this.canBeSubmitted()}
                  className="default-button"
                  onClick={this.handleSubmitChangeProfile}
                >
                  Save
                </button>
              </div>
            )}

            {!this.state.inputEnabled && (
              <div className="profile-form-buttons">
                <div onClick={this.handleChangePasswordModal} className="change-password">
                  Change Password
                </div>
                <button
                  type="submit"
                  onClick={this.handleSubmitEditProfile}
                  className="default-button"
                >
                  Edit Profile
                </button>
              </div>
            )}
          </CardBody>

          <Modal show={this.state.isModal} onHide={this.toggleModal}>
            <Modal.Header className="change-password-header" closeButton>
              <Modal.Title className="change-password-title">Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <p
                  style={{
                    textAlign: 'center',
                    color: 'black'
                  }}
                >
                  {'Please enter your old password followed by your new password.'}
                </p>
                <Input
                  className="input-password"
                  label="Current Password"
                  id="currentPassword"
                  onInput={event => this.handleChange(event.target.id, event.target.value)}
                  type="password"
                />
                <Input
                  className="input-password"
                  label="New Password"
                  id="newPassword"
                  onInput={event => this.handleChange(event.target.id, event.target.value)}
                  type="password"
                />
                <Input
                  className="input-password"
                  label="Confirm New Password"
                  id="confirmNewPassword"
                  onInput={event => this.handleChange(event.target.id, event.target.value)}
                  type="password"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="cancel-button" onClick={this.toggleModal}>
                Cancel
              </button>
              <button
                className="default-button"
                disabled={!this.isValidPasswordForm()}
                onClick={this.handleChangePassword}
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
        </Card>
      </Fragment>
    );
  }
}
