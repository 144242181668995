import React, { Component } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { Form, NavLink } from 'reactstrap';

// TEMPORARY FIX, NEED TO ABSTRACT OUT CSS NEEDED FROM THAT FILE AS IT IS NO LONGER USED
import '../pages/order/TrackOrderPage.css';

import _ from 'lodash';

export default class AddModalMultipleForm extends Component {
  constructor(props) {
    super(props);

    this.updateProviderErrors = this.updateProviderErrors.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.imageValues = this.imageValues.bind(this);

    this.state = {
      stagedItems: [_.cloneDeep(this.props.provider.getInputSchema())],
      inputValidation: [],
      submitting: false
    };
  }

  changeValues(event, value, index) {
    let stagedItems = _.cloneDeep(this.state.stagedItems);
    stagedItems[index][value] = event.target.value;

    this.setState({
      stagedItems: stagedItems
    });
  }

  imageValues(event, value, index) {
    let stagedItems = _.cloneDeep(this.state.stagedItems);
    stagedItems[index][value] = event.target.files[0];

    this.setState({
      stagedItems: stagedItems
    });
  }

  updateProviderErrors(successRegistry, successCollection, errorCollection) {
    this.setState({
      successRegistry: successRegistry,
      successCollection: successCollection,
      errorCollection: errorCollection
    });
  }

  addItemAtIndex() {
    this.setState({
      stagedItems: this.state.stagedItems.concat([
        _.cloneDeep(this.props.provider.getInputSchema())
      ]),
      inputValidation: this.state.inputValidation.concat([])
    });
  }

  removeItemAtIndex(index) {
    this.setState({
      stagedItems: this.state.stagedItems.filter((_, i) => i !== index),
      inputValidation: this.state.inputValidation.filter((_, i) => i !== index)
    });
  }

  showErrorMessage() {
    this.setState({
      showErrorMessage: true,
      submitting: false
    });
  }

  async handleSubmit() {
    this.setState({
      errorCollection: [],
      showErrorMessage: false,
      showTransactionReport: false,
      submitting: true
    });

    let results = [];
    let validationReport = [];
    for (let i = 0; i < this.state.stagedItems.length; i++) {
      let item = this.state.stagedItems[i];
      let report = this.props.provider.validateInput(item);
      results[i] = report.results;
      if (report.hasError) {
        validationReport.push(report);
      }
    }
    if (validationReport.length > 0) {
      this.showErrorMessage();
      this.setState({
        inputValidation: [...results]
      });
      return;
    } else {
      await this.props.provider.addItem(this.updateProviderErrors, this.state.stagedItems);
    }

    if (this.state.errorCollection.length !== 0) {
      while (this.state.successRegistry.length !== 0) {
        this.removeItemAtIndex(this.state.successRegistry.pop());
      }

      this.setState({
        showTransactionReport: true,
        submitting: false
      });
    } else {
      this.props.handleClose();
    }
  }

  render() {
    let submissionErrorMessage = type => {
      return (
        <Alert
          className="submission-report-alerts"
          bsStyle="danger"
          onDismiss={() => this.setState({ showErrorMessage: false })}
        >
          <h4>Error </h4>
          <p>Action was not completed. Please review highlighted items and try again.</p>
        </Alert>
      );
    };

    let addTransactionReport = () => {
      return (
        <Alert
          className="submission-report-alerts"
          bsStyle="danger"
          onDismiss={() => this.setState({ showTransactionReport: false })}
        >
          <h4>Submission Error</h4>
          <p>
            [{this.state.errorCollection.length}] out of [
            {this.state.errorCollection.length + this.state.successCollection.length}]
            {this.state.errorCollection.length + this.state.successCollection.length > 1
              ? ' submissions'
              : ' submission'}{' '}
            has failed. Affected {this.state.errorCollection.length > 1 ? 'values ' : 'value '}{' '}
            {this.state.errorCollection.length > 1 ? 'are ' : 'is '} as follows:
          </p>
          <table>
            <tbody>
              {this.state.errorCollection.map((error, index) => {
                return (
                  <tr key={index}>
                    <td>{error.entry + ' - ' + error.errMessage}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Alert>
      );
    };

    return (
      <Modal show={true} onHide={this.props.handleClose} backdrop="static">
        <Modal.Header className="route-modal-header" closeButton>
          <Modal.Title className="route-modal-title">{'Register Item'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showTransactionReport && addTransactionReport()}
          {this.state.showErrorMessage && submissionErrorMessage()}
          {this.state.stagedItems.map((value, index) => {
            return (
              <Form key={index}>
                {this.props.provider.itemsSchema(
                  index,
                  this.changeValues,
                  this.state.inputValidation[index],
                  this.imageValues,
                  true
                )}
              </Form>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <div id="footer-container">
            <div id="footer-actions">
              <div id="modal-remove-container">
                {this.state.stagedItems.length > 1 && (
                  <NavLink
                    id="level-modal-remove"
                    onClick={() => this.removeItemAtIndex(this.state.stagedItems.length - 1)}
                  >
                    {'- Remove last'}
                  </NavLink>
                )}
              </div>
              <NavLink id="level-modal-add" onClick={() => this.addItemAtIndex()}>
                {'+ Add another'}
              </NavLink>
            </div>
            <div id="footer-buttons">
              <button className="cancel-button" onClick={this.props.handleClose}>
                Cancel
              </button>
              <button
                className="default-button"
                disabled={this.state.submitting}
                onClick={() => this.handleSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
