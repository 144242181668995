import React, { Component, Fragment } from 'react';
import { CardBody, Card } from 'mdbreact';
import 'styles/DetailsCard.css';
import * as Datetime from 'react-datetime';
import { Alert } from 'react-bootstrap';

export default class DetailsCard extends Component {
  constructor(props) {
    super(props);
    const { info } = this.props;
    // 'info' is a prop passed only by AssetDetails as it is editable
    if (info) {
      const selectedDate = !info.due_date || info.due_date === '-' ? null : new Date(info.due_date);
      this.state = { selectedDate };
    }
  }

  UNSAFE_componentWillUpdate(props) {
    if (this.props.info !== props.info) {
      const { info } = props;
      // 'info' is a prop passed only by AssetDetails as it is editable
      if (info) {
        const selectedDate =
          !info.due_date || info.due_date === '-' ? null : new Date(info.due_date);
        this.setState({ selectedDate });
      }
    }
  }

  handleEditChange = event => {
    this.props.handleEditChange(event.target.id, event.target.value);
  };

  renderEditInput = (attrName, hint) => {
    return (
      <input
        className="edit-box"
        type="text"
        id={attrName}
        placeholder={hint}
        onChange={this.handleEditChange}
      />
    );
  };

  showBanner = (deleteSuccess) => {
    const isSuccess = deleteSuccess;
    return (
      <Alert
        className="delete-object-alerts"
        bsStyle={isSuccess ? 'success' : 'danger'}
        onDismiss={this.dismissBanner}
      >
        <h4>{isSuccess ? 'Successfully deleted!' : 'Failed to delete'}</h4>
      </Alert>
    );
  };

  dismissBanner = () =>{
    this.props.handleDismiss();
  };

  handleSubmit = () => {
    const { selectedDate } = this.state;
    const date = new Date(selectedDate);
    const timestamp = date.getTime();
    this.props.handleEditChange('due_date', timestamp);
  };

  /* This method recieves a moment (Datetime
   component), not a date */
  changeDate = moment => {
    const date = moment._d;
    this.setState({ selectedDate: date });
  };

  renderEditDate = () => {
    const { selectedDate } = this.state;
    return (
      <Datetime
        value={selectedDate}
        inputProps={{ className: 'edit-box' }}
        onChange={this.changeDate}
        onBlur={this.handleSubmit}
        timeFormat={false}
      />
    );
  };

  render() {
    const { metaInformation, renderMoreButton, canEdit, overallInformation, deleteClicked, deleteSuccess } = this.props;
    const returnCard = () => {
      return (
        <Card className="outer-card-styling">
        {deleteClicked && this.showBanner(deleteSuccess)}
          <Card className="card-styling">
            <div className="details-card-responsive-container">
              <img src={metaInformation.img} alt="Object" />
            </div>

            <CardBody className="cardbody-styling">
              <div>
                {renderMoreButton ? renderMoreButton() : null}
                <h4 id="object-page-title">
                  <div className="title-group">
                    {`${metaInformation.display_name}  `}
                    {canEdit
                      ? this.renderEditInput('object_name', metaInformation.display_value)
                      : metaInformation.display_value}
                  </div>
                  <div className="title-group">
                    {metaInformation.display_part_id
                      ? `${metaInformation.display_part_id}  `
                      : null}
                    {metaInformation.display_part_id
                      ? canEdit
                        ? this.renderEditInput('part_id', metaInformation.part_id)
                        : `${metaInformation.part_id}`
                      : null}
                  </div>
                </h4>
              </div>
              <div className="object-page-items">
                {overallInformation.map(row => {
                  if (row.name === 'Button') {
                    return (
                      <div key={row.name} className="icon-description-section">
                        <div>{row.action}</div>
                      </div>
                    );
                  }
                  if (row.name === 'Exp. Date:' || row.name === 'Due Date:') {
                    return (
                      <div key={row.name} className="icon-description-section">
                        <div className="icon-section">{row.name}</div>
                        <div className="description-section">
                          {canEdit && row.editable ? this.renderEditDate() : row.action}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div key={row.name} className="icon-description-section">
                      <div className="icon-section">{row.name}</div>
                      <div className="description-section">{row.action}</div>
                    </div>
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </Card>
      );
    };

    return <Fragment>{metaInformation ? returnCard() : ''}</Fragment>;
  }
}
