import React from 'react';
import RestService from '../../../services/RestService';
import ResourceProvider from './ResourceProvider';
import _ from 'lodash';

const resourceProviderName = 'Plant';

export default class PlantResource extends ResourceProvider {
  static async build() {
    let getPlants = async () => {
      let plants = await RestService.get('/plants');

      let plantList = _.map(plants, plant => {
        let plantName = plant.plant_name;
        let plantId = plant.plant_id;
        let plantDesc = plant.plant_info;

        return {
          thisName: plantName,
          thisId: plantId,
          thisDesc: plantDesc
        };
      });

      return plantList;
    };

    let plants = await getPlants();

    return new PlantResource(
      [...plants.sort((a, b) => a.thisName.localeCompare(b.thisName))],
      resourceProviderName
    );
  }

  deleteItem(thisItem, handleCloseFunc, showDeleteErrorFunc) {
    RestService.delete('/plants/' + thisItem.thisId).then(
      result => {
        this.resourceArray = this.resourceArray.filter(function(plant) {
          return plant !== thisItem;
        });
        handleCloseFunc();
      },
      err => {
        showDeleteErrorFunc();
      }
    );
  }

  addItemsSchema(index, item, changeValuesFunc, resourceProviders, nameCheck) {
    return (
      <div>
        {super.levelNameForm(index, item.name, changeValuesFunc, nameCheck)}
        {super.descriptionForm(index, item.description, changeValuesFunc)}
      </div>
    );
  }

  editItemsSchema(index, itemToEdit, changeValuesFunc, resourceProviders, nameCheck) {
    return (
      <div>
        {super.editLevelNameForm(index, itemToEdit, changeValuesFunc, nameCheck)}
        {super.editDescriptionForm(index, itemToEdit, changeValuesFunc)}
      </div>
    );
  }

  async editItem(
    formCheckFunc,
    updateErrorsFunc,
    stagedItems,
    resourceProviders,
    customerId,
    itemToEdit
  ) {
    let errorCollection = [];
    let successCollection = [];
    let successRegistry = [];

    var payload = [];

    if (stagedItems[0].name !== '') {
      payload.push({
        op: 'replace',
        path: '/plant_name',
        value: stagedItems[0].name
      });
    }

    if (stagedItems[0].description !== '') {
      payload.push({
        op: 'replace',
        path: '/plant_info',
        value: stagedItems[0].description
      });
    }

    if (payload.length >= 1) {
      await RestService.patch('/plants/' + itemToEdit.thisId, payload).then(
        result => {
          let updateItem = this.getArrayItemById(result.plant_id);

          updateItem.thisName = result.plant_name;
          updateItem.thisDesc = result.plant_info;

          successCollection.push(result.plant_name);
          successRegistry.push(0);
        },
        err => {
          errorCollection.push({
            entry: stagedItems[0].name,
            errMessage: err.response.data.message
          });
        }
      );
    }
    updateErrorsFunc(successRegistry, successCollection, errorCollection);
    return true;
  }

  async addItem(formCheckFunc, updateErrorsFunc, stagedItems, resourceProviders, customerId) {
    let errorCollection = [];
    let successCollection = [];
    let successRegistry = [];

    if (!formCheckFunc('name')) {
      return false;
    }

    for (const index of stagedItems.keys()) {
      let payload = {
        plant_name: stagedItems[index].name,
        customer_id: customerId
      };

      if (stagedItems[index].description !== '') {
        payload.plant_info = stagedItems[index].description;
      }

      await RestService.post('/plants', payload).then(
        result => {
          this.add({
            thisName: result.plant_name,
            thisId: result.plant_id,
            thisDesc: result.plant_info
          });

          successCollection.push(result.plant_name);
          successRegistry.push(index);
        },
        err => {
          errorCollection.push({
            entry: stagedItems[index].name,
            errMessage: err.response.data.message
          });
        }
      );
    }

    updateErrorsFunc(successRegistry, successCollection, errorCollection);
    return true;
  }
}
