/* eslint-disable react/sort-comp */
/* eslint-disable no-template-curly-in-string */
import React, { Fragment, Component } from 'react';
import './SearchBar.css';
import { InputGroup, Button } from 'react-bootstrap';
import { ListGroupItem, ListGroup } from 'mdbreact';
import TextareaAutosize from 'react-textarea-autosize';
import ConfirmDeleteModal from './ConfirmDeleteModal';

export default class SearchBarAndResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      currentStyle: {
        [this.props.name]: null
      }
    };
  }

  componentDidMount() {
    this.setState({
      currentStyle: {
        ...this.state.currentStyle,
        [this.props.name]: null
      }
    });
  }

  getInputStyle(clicked) {
    const onBlurStyle = {
      width: '50%',
      lineHeight: 2,
      boxSizing: 'border-box',
      borderColor: '#ced4da',
      padding: 5,
      borderRadius: 5,
      resize: 'none'
    };

    const errorStyle = {
      width: '50%',
      lineHeight: 2,
      boxSizing: 'border-box',
      borderColor: 'red',
      padding: 5,
      borderRadius: 5,
      resize: 'none'
    };

    const onFocusStyle = {
      width: '50%',
      lineHeight: 2,
      boxSizing: 'border-box',
      padding: 5,
      borderRadius: 5,
      resize: 'none',
      color: '#495057',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
    };

    const onBlurSearchBarStyle = {
      width: '90%',
      lineHeight: 2,
      boxSizing: 'border-box',
      borderColor: '#ced4da',
      padding: 5,
      borderRadius: 5,
      resize: 'none'
    };

    const onFocusSearchBarStyle = {
      width: '90%',
      lineHeight: 2,
      boxSizing: 'border-box',
      padding: 5,
      borderRadius: 5,
      resize: 'none',
      color: '#495057',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
    };
    const { isAutoResizable, inputNotValid } = this.props;
    if (isAutoResizable) {
      if (clicked) {
        return onFocusStyle;
      }
      if (inputNotValid) {
        return errorStyle;
      }
      return onBlurStyle;
    }
    if (clicked) {
      return onFocusSearchBarStyle;
    }
    return onBlurSearchBarStyle;
  }

  handleDeleteClick = item => {
    // Information to be displayed
    const { deleteStockItem } = this.props;
    const arrangedDisplayInfo = {};
    if (item.key[2]) {
      arrangedDisplayInfo['item name'] = item.key[2];
    }
    if (item.key[0]) {
      arrangedDisplayInfo['vendor'] = item.key[0];
    }
    if (item.key[1]) {
      arrangedDisplayInfo['order number'] = item.key[1];
    }
    if (item.key[3]) {
      arrangedDisplayInfo['package size'] = item.key[3];
    }
    // rowData
    const data = {
      display_info: arrangedDisplayInfo,
      delete_info: item,
      loading: false,
      confirm_function: deleteStockItem
    };
    this.setState({ showDeleteModal: true, rowData: data });
  };

  showDeleteModal() {
    // To display the confirmation message
    const { rowData } = this.state;
    return (
      <ConfirmDeleteModal
        rowData={rowData}
        headerName="Stock Item"
        cancel_function={this.cancelDeleteModal}
      />
    );
  }

  cancelDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  render() {
    const getSearchStyle = () => {
      const { searchResults, searchStyle } = this.props;
      if (searchResults.length > 0) {
        if (searchStyle === 'fixed') {
          return 'search-results-fixed';
        }
        return 'search-results-overlay';
      }
      return null;
    };

    const getDeleteButton = result => {
      return (
        <button
          className="delete-btn-style"
          type="button"
          onClick={() => this.handleDeleteClick(result)}
        ></button>
      );
    };

    /* 
    render max(searchResults.length, 600) results 
    */
    const getSearchResults = () => {
      const { searchResults, searchFn, postAction, deleteStockItem } = this.props;
      return (
        <Fragment>
          <ListGroup className={getSearchStyle()}>
            {searchResults.map((result, index) => {
              return index < 600 ? (
                <div className="rowC" key={result.id}>
                  <ListGroupItem
                    className="list-group-item-style"
                    dangerouslySetInnerHTML={{ __html: result.displayString }}
                    onClick={() => searchFn(result.id)}
                  />
                  {deleteStockItem && getDeleteButton(result)}
                </div>
              ) : null;
            })}
          </ListGroup>
          {this.state.showDeleteModal && this.showDeleteModal()}
          {postAction && getPostAction()}
        </Fragment>
      );
    };

    let getPostAction = () => {
      return (
        <ListGroup className={getSearchStyle()}>
          <ListGroupItem>{this.props.postAction()}</ListGroupItem>
        </ListGroup>
      );
    };
    const {
      searchFn,
      name,
      value,
      placeholder,
      handleInputChange,
      enableSearchButton,
      searchResults
    } = this.props;
    const { currentStyle } = this.state;
    return (
      <Fragment>
        <form
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
            searchFn();
          }}
        >
          <InputGroup>
            <TextareaAutosize
              name={name}
              value={value}
              onFocus={event => {
                const style = this.getInputStyle(true);
                this.setState({
                  currentStyle: {
                    ...this.state.currentStyle,
                    [event.target.name]: style
                  }
                });
              }}
              onBlur={event => {
                this.setState({
                  currentStyle: {
                    ...this.state.currentStyle,
                    [event.target.name]: null
                  }
                });
              }}
              style={currentStyle[name] || this.getInputStyle(false)}
              placeholder={placeholder}
              onChange={event => {
                handleInputChange(event.target.value);
              }}
              maxRows={this.props.isAutoResizable ? 3 : 1}
              wrap={this.props.isAutoResizable ? 'soft' : 'off'}
            />
            {enableSearchButton ? (
              <InputGroup.Button>
                <Button type="button" id="searchbtn" className="btn-md" onClick={() => searchFn()}>
                  <i className="fa fa-search" />
                </Button>
              </InputGroup.Button>
            ) : null}
            <div className="search-results-div">{searchResults && getSearchResults()}</div>
          </InputGroup>
        </form>
      </Fragment>
    );
  }
}
