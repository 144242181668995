import React, { Component } from 'react';
import '../../components/SearchBar.css';
import { Redirect } from 'react-router';
import SearchBarAndResults from 'components/SearchBarAndResults';
import RestService from '../../services/RestService';
import ConfigurationService from '../../services/ConfigurationService';

const searchTimeout = 1000;

export default class TrackedObjectSearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      suggestions: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleInputChange = async eventValue => {
    const context = this;

    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }

    const timer = setTimeout(async function() {
      await context.filterResults(eventValue);
      context.setState({
        timer: null
      });
    }, searchTimeout);

    this.setState({
      timer
    });
  };

  async buildSearchMap() {
    const trackedObjects = await RestService.get('/tracked-objects');
    const trackedObjectTypes = await RestService.get('/tracked-object-types');
    const trackers = (await RestService.get('/trackers')).reduce((map, tracker) => {
      map[tracker.tracker_id] = tracker.tracker_serial;
      return map;
    }, {});

    const suggestionToQueryParam = [];
    for (let i = 0; i < trackedObjects.length; i++) {
      const object = trackedObjects[i];

      if (object.object_class === 'order') {
        let displayText = '';
        if (ConfigurationService.getSolutionType() === 'order') {
          displayText = `<b>Order Number</b>: ${object.object_name}
                        <b>Part Number</b>: ${object.part_id || '-'}
                        <b>Tag</b>: ${trackers[object.tracker_id]}`;
        } else if (object.object_class === 'asset') {
          displayText = `Asset Number: ${object.object_name}`;
        } else {
          displayText = `Part Number: ${object.object_name}`;
        }

        suggestionToQueryParam.push({
          type: 'order',
          id: object.tracked_object_id,
          key: [object.object_name, object.part_id, trackers[object.tracker_id]],
          displayString: displayText
        });
      }
    }
    for (let i = 0; i < trackedObjectTypes.length; i++) {
      const objectType = trackedObjectTypes[i];

      suggestionToQueryParam.push({
        type: 'part',
        id: objectType.object_type_id,
        key: [objectType.object_type_name, objectType.object_type_id],
        displayString: `${objectType.object_type_name}`
      });
    }

    return suggestionToQueryParam;
  }

  async filterResults(eventValue) {
    let filteredMap = null;
    if (eventValue.length > 0) {
      const suggestionToQueryParam = await this.buildSearchMap();
      const searchPhaseRegex = new RegExp(eventValue, 'ig');
      filteredMap = suggestionToQueryParam.filter(object => {
        const toJsonString = JSON.stringify(object.key);
        return toJsonString.search(searchPhaseRegex) !== -1;
      });
    }

    this.setState({
      suggestions: filteredMap,
      enteredSearch: eventValue
    });
  }

  handleOnClick = selectedObject => {
    const searchString = selectedObject || this.state.enteredSearch;

    if (!searchString || searchString.length === 0 || searchString.trim() === '') {
      return;
    }

    const target = this.state.suggestions.find(queryParam => {
      return queryParam.key.includes(searchString) || queryParam.id === searchString;
    });

    if (target) {
      this.setState({
        suggestions: [],
        query: `?type=${target.type}&item=${target.id}`,
        redirect: true
      });
    }
  };

  render() {
    if (this.state.redirect) {
      this.setState({ redirect: false });
      return (
        <Redirect
          push
          to={{
            pathname: '/tracked-object/details',
            search: this.state.query
          }}
        />
      );
    }

    return (
      <SearchBarAndResults
        handleInputChange={this.handleInputChange}
        searchResults={this.state.suggestions}
        searchFn={this.handleOnClick}
        placeholder="Search for tracked item..."
        enableSearchButton
        name="search_bar"
      />
    );
  }
}
