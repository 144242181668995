import React, {Component} from 'react';
import EditableTableComponent from '../../components/editable-table/EditableTableComponent';
import RestService from '../../services/RestService';
import DepartmentResource from "../manage-infrastructure/resources/DepartmentResource";

function parseConditionData(ruleCondition) {
    const tags = ruleCondition.tags;

    let ruleId = ruleCondition.rule_id;
    let conditionId = ruleCondition.rule_condition_id;
    let deptId = tags.department_id;
    let name = tags.department_name;

    return {
        ruleId: ruleId,
        id: conditionId,     //this is for component to keep track
        conditionId: conditionId,
        deptId: deptId,
        name: name,
    };
}


async function fetchConditions(ruleId) {
    let conditions = await RestService.get(`/rules/${ruleId}/conditions`);
    let conditionInfoList = conditions.map(condition => parseConditionData(condition));

    return conditionInfoList;
}

function buildCreateRuleConditionPayloadFromRequest(requestPayload) {
    let {deptId,name} = {...requestPayload};

    return {
        "tags": {
            "department_id": deptId,
            "department_name": name,
        },
        "rule_condition_name": `ArrivedAt_${deptId}`,
        "enabled": true,
        "conditions": [
            {
              "property": 'department.department_id',
              "op": "=",
              "value": deptId
            },
            {
              "not_null": 'previousLocation'
            },
            {
              "property": 'previousLocation.department.department_id',
              "op": "<>",
              "value": deptId
            },
         ],
         "stage": "critical",
         "event_id": "analyzed-tracker-event-historical-count-by-department",
     };
}

export default class ArrivedAtRuleContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ruleId: props.ruleId,
        };

        this.handleRuleConditionRequests = this.handleRuleConditionRequests.bind(this);
        this.handleAutoFill = this.handleAutoFill.bind(this);
    }

    async componentDidMount() {

      await DepartmentResource.build().then(build => {

          fetchConditions(this.state.ruleId).then(result => {
              if (!result) {
                  result = [];
              }

              const allDepts = build.resourceArray;
              this.setState({
                  conditions: result,
                  allDepts:allDepts,
              });
          });
      });

     }


    handleAutoFill(headerId, newValue, data) {
        let allDepts = this.state.allDepts;

            let autoFillObject = {
              data: data,
              changed: true
            };

            if(headerId === "name"){
                let deptIndex = allDepts.map(function(i) {
                      return i.thisName; }).indexOf(newValue);
              let deptId = allDepts[deptIndex].thisId;
                data['deptId'] = deptId;
            }
            autoFillObject.data = data;

            if(data.name === ""){
                autoFillObject.changed = false;
            }

            return autoFillObject;
      }


    async handleRuleConditionRequests(requests) {
        if (!requests || requests.length === 0) {
            return;
        }

        for (let index in requests) {
            let request = requests[index];

            let event = request._event;
            let conditionId = request.conditionId;
            let payload;
            switch(event) {
                case 'pending_new':
                    payload = buildCreateRuleConditionPayloadFromRequest(request);
                    await RestService.post(`/rules/${this.state.ruleId}/conditions`, payload);
                    break;
                case 'pending_delete':
                    await RestService.delete(`/rules/${this.state.ruleId}/conditions/${conditionId}`);
                    break;
                default:
                    console.log(`Unsupported Event[Name:${event}]`);
                    break;
            }

            console.log(`==> Request: ${JSON.stringify(request)}`);
        }

        let conditions = await fetchConditions(this.state.ruleId);
        this.setState({conditions: conditions});
        return conditions.map(condition => condition.id);
    }

    render() {

        let allDepts = [];
        let department={};

        if(this.state.allDepts && this.state.allDepts.length > 0){
          allDepts = this.state.allDepts.map(dept => {
            department = {
              objectTypeId: dept.thisId,
              objectTypeName: dept.thisName,
            };
            return department;
          });
        }

        let dropDownOptions = allDepts;
        let deptsWithoutArrivedAtRule;
        let deptsWithArrivedAtRule = this.state.conditions;

        if(deptsWithArrivedAtRule){
          deptsWithArrivedAtRule = deptsWithArrivedAtRule.map(dept => dept.name);
          deptsWithoutArrivedAtRule = allDepts.filter(dept => deptsWithArrivedAtRule.indexOf(dept.objectTypeName) === -1);
          dropDownOptions = deptsWithoutArrivedAtRule;
        }

        let headers = [
            {
                displayName: 'Department',
                id: 'name',
                cell: {
                  input: 'dropdown',
                  data: dropDownOptions,
                  display: 'text',
                  modifiable: true,   // whether it can be edited after being added
                }
            },
        ];

        return (
            <div>
                <div>
                    <p className="tabbedSectionComponent-contentTitle">Arrived At</p>
                    <p style={{color: '#343434'}}>When an order arrives at any of these departments, a notification will be sent.</p>
                </div>
                <div className='note'>
                    <span>Receive notifications for the departments listed:</span>
                </div>
                <EditableTableComponent headers={headers}
                                        dataList={this.state.conditions}
                                        handleChangesFn={this.handleRuleConditionRequests}
                                        handleAutoFillFn={this.handleAutoFill}
                />
            </div>
        );
    }
}
