import RestService from '../../../services/RestService';
import LineGraphDataEntry from './LineGraphDataEntry';

const FreshnessDurationInMilliseconds = 10 * 60 * 1000; // in milliseconds

/**
 * remove the trailing slash on path if exists
 * @param path
 * @return {string}
 */
function stripLastSlashFromPath(path) {
  if (path.endsWith('/')) {
    return path.substring(0, path.length - 2);
  }

  return path;
}

/**
 * Determine whether caller needs to fetch the data.
 *  This method checks for following conditions
 *      - if data does not exist in cache, returns true
 *      - check against last fetch timestamp, if it has been too long, then it needs to re-fetch
 * @param itemId
 * @param category
 * @param auditMap
 * @return {boolean}
 */
function mustFetchData(itemId, category, auditMap) {
  const categoryAuditMap = auditMap[category];
  if (!categoryAuditMap) {
    return true;
  }

  const lastUpdatedTimestamp = categoryAuditMap[itemId];
  if (!lastUpdatedTimestamp) {
    return true;
  }

  const expired = Date.now() - lastUpdatedTimestamp > FreshnessDurationInMilliseconds;
  return expired;
}

/**
 * This function will record the last fetch timestamp of each data, which will be used to
 *  determine data's freshness
 * @param itemId
 * @param category
 * @param auditMap
 * @return {Object}
 */
function updateAuditMap(itemId, category, auditMap) {
  let categoryAuditMap = auditMap[category];

  if (!categoryAuditMap) {
    categoryAuditMap = {};
  }

  categoryAuditMap[itemId] = Date.now();
  auditMap[category] = categoryAuditMap;

  return auditMap;
}

/**
 *  sort the data entry
 * @param entries {[LineGraphDataEntry]}
 */
function sortDataEntry(entries) {
  const sortedData = entries.sort((a, b) => {
    return a.timeFrame - b.timeFrame;
  });

  return sortedData;
}

export default class HistoricalBacklogDataLoader {
  constructor(baseUrl) {
    this.baseUrl = stripLastSlashFromPath(baseUrl);

    /**
     * @type {{
     *     ${category}: {
     *         ${timeFrame}: {LineGraphDataEntry}
     *     }
     * }}
     */
    this.categoryDataMap = {};
    this.auditMap = {};
  }

  /**
   * This method is NOT thread-safe and should not be called multiple times at once
   * This method serve as a signal to fetch data. However, it only does that if the
   *  data does not exist in cache or has it been expired.
   * @param itemId
   * @param category
   * @return {Promise<void>}
   */
  async loadData(itemId, category) {
    // check to see if we really need to fetch
    if (!mustFetchData(itemId, category, this.auditMap)) {
      return;
    }

    let entryMap = this.categoryDataMap[category];
    if (!entryMap) {
      entryMap = {};
    }

    const url = `${this.baseUrl}/${itemId}?time_span=${category}`;
    console.log(`==> URL: ${url}`);
    const response = await RestService.get(url);

    // update last fetch timestamp
    this.auditMap = updateAuditMap(itemId, category, this.auditMap);

    response.data.forEach(data => {
      const timeFrame = data.time_frame;
      let entry = entryMap[timeFrame];

      if (!entry) {
        entry = new LineGraphDataEntry(data.time_frame);
      }

      entry.addDataPoint(itemId, data.tracked_objects_count);
      entryMap[timeFrame] = entry;
    });

    this.categoryDataMap[category] = entryMap;
  }

  getData(category) {
    const entryMap = this.categoryDataMap[category];

    if (!entryMap) {
      return [];
    }

    return sortDataEntry(Object.values(entryMap));
  }
}
