import React, { Component, Fragment } from 'react';
import memoizeOne from 'memoize-one';

import SearchBarAndResults from 'components/SearchBarAndResults';
import './SearchAndSubmit.css';

const searchTimeout = 0;

export default class SearchAndSubmit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: undefined,
      showSearchList: true,
      filteredMap: undefined
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.getPostAction = this.getPostAction.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { searchMap, value } = this.props;
    this.cachedFilterResults(searchMap.length);
    if (value !== prevProps.value) {
      this.setState({value});
    }
  }

  handleInputChange = async eventValue => {
    const context = this;
    let { timer } = this.state;
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async function() {
      await context.filterResults();
      context.setState({
        timer: null
      });
    }, searchTimeout);

    this.setState({
      timer,
      value: eventValue,
      showSearchList: true
    });
  };

  filterResults = () => {
    const { value } = this.state;
    const { searchMap } = this.props;
    let filteredMap = null;
    if (searchMap && value && value.length > 0) {
      const keyword = value.replace(/[^\w\s]/gi, '');
      const suggestionToQueryParam = searchMap;
      const searchPhaseRegex = new RegExp(keyword, 'gi');
      filteredMap = suggestionToQueryParam.filter(object => {
        const toJsonString = JSON.stringify(object.key);
        return toJsonString.search(searchPhaseRegex) !== -1;
      });
    }
    this.setState({ filteredMap });
  };

  /*
   * Caches the result of filterResults.
   * Recalculates only if either input is
   * different from the last time this was called
   * to avoid repeatitive recalculation over the same input.
   */
  cachedFilterResults = memoizeOne(length => this.filterResults());

  selectItem(selectedItem) {
    if (selectedItem) {
      this.setState({
        showSearchList: false
      });

      this.props.selectItem(selectedItem);
    }
  }

  getPostAction() {
    return this.props.postAction(() => {
      this.setState({
        showSearchList: false
      });
    });
  }

  render() {
    const { value, showSearchList, filteredMap } = this.state;
    const searchList = showSearchList ? filteredMap : undefined;
    return (
      <Fragment>
        <SearchBarAndResults
          handleInputChange={this.handleInputChange}
          searchResults={searchList}
          searchFn={this.selectItem}
          value={value}
          searchStyle={this.props.searchStyle}
          postAction={this.props.postAction && this.getPostAction}
          enableSearchButton={false}
          name={this.props.name}
          isAutoResizable={this.props.isAutoResizable}
          inputNotValid={this.props.inputNotValid}
          deleteStockItem={this.props.deleteStockItem}
        />
      </Fragment>
    );
  }
}
