import React, { Component } from 'react';
import NavLink from 'react-router-dom/es/NavLink';
import {
  NavbarNav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge
} from 'mdbreact';
import PageHeader from './PageHeader';
import AuthService from '../../services/AuthService';
import Notifications, { getNumberOfUnreadAlerts } from '../alert/Notifications';
import './NavbarHeader.css';
import SearchBar from './TrackedObjectSearchBar';
import notificationIcon_unselected from '../../img/notification_icon_dark.png';
import notificationIcon_selected from '../../img/notification_selected.png';

export default class NavbarHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationDropdownToggle: false,
      activeTab: 'backlog',
      tabNumber: 1,
      displaySearch: 0,
      notificationIcon: notificationIcon_unselected
    };

    this.handleTab = this.handleTab.bind(this);
    this.startAPICallInterval = this.startAPICallInterval.bind(this);
  }

  componentDidMount() {
    getNumberOfUnreadAlerts().then(number => {
      this.setState({ numberOfNewNotifications: number });
      if (number < 1) this.startAPICallInterval();
    });

    document.body.addEventListener('click', this.handleClickOut, false);
  }

  handleTab(tabNumber, name) {
    if (this.state.tabNumber !== tabNumber) {
      this.setState({
        tabNumber,
        activeTab: name
      });
    }
  }

  startAPICallInterval() {
    let intervalId;
    if (!this.state.intervalId || this.state.intervalId === null) {
      intervalId = setInterval(
        function() {
          getNumberOfUnreadAlerts().then(number => {
            if (number > 0) {
              this.setState({
                numberOfNewNotifications: number
              });
              clearInterval(intervalId);
            } else {
              this.setState({ intervalId });
            }
          });
        }.bind(this),
        3600000
      ); // once every hour
    }
  }

  handleDropdown() {
    const toggle = this.state.notificationDropdownToggle;

    /*
     * toggle === false -> Dropdown menu is closed
     * toggle === true -> Dropdown menu is open
     */
    if (!toggle) {
      clearInterval(this.state.intervalId);
      this.setState({
        notificationDropdownToggle: true,
        notificationIcon: notificationIcon_selected,
        numberOfNewNotifications: 0,
        intervalId: null
      });
    } else {
      this.closeDropdown();
    }
  }

  handleClickOut = event => {
    if (
      event.target !== this.refs.notification_icon &&
      this.state.notificationDropdownToggle === true
    ) {
      this.closeDropdown();
    }
  };

  closeDropdown() {
    this.startAPICallInterval();
    this.setState({
      notificationDropdownToggle: false,
      notificationIcon: notificationIcon_unselected
    });
  }

  render() {
    const displayBar = () => {
      if (!this.props.hide_bar) {
        return (
          <div className="searchbar-space">
            <SearchBar />
          </div>
        );
      }
    };

    let username = 'guest';
    const trueValue = true;
    const sessionInfo = AuthService.getSessionInfo();

    if (sessionInfo) {
      username = sessionInfo.username;
    }

    return (
      <PageHeader>
        <NavbarNav id="navbar-new">
          <NavItem className="leftside navigation">
            <NavLink
              to={{ pathname: '/' }}
              className={this.state.activeTab === 'backlog' ? 'custom-active' : 'custom-nav'}
              value="backlog"
              onClick={() => this.handleTab(1, 'backlog')}
            >
              Home
            </NavLink>
          </NavItem>
          {displayBar()}
          <div>
            <NavItem onClick={() => this.handleDropdown()}>
              <Dropdown className="dropdown">
                <DropdownToggle nav id="custom-nav">
                  {
                    <img
                      className="notification-icon"
                      src={this.state.notificationIcon}
                      alt="Notications"
                      ref="notification_icon"
                    />
                  }
                  {!this.state.notificationDropdownToggle &&
                  this.state.numberOfNewNotifications > 0 ? (
                    <Badge color="danger" className="notification-badge">
                      {' '}
                    </Badge>
                  ) : null}
                </DropdownToggle>
                <DropdownMenu className="dropdownmenu z-depth-1" right>
                  <div>
                    <Notifications
                      miniVersion={trueValue}
                      dropdownToggle={this.state.notificationDropdownToggle}
                    />
                  </div>
                  <a href="/alerts?activetab=1" className="seeAllNotifications">
                    See all Notifications
                  </a>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
            <NavItem right="true">
              <Dropdown>
                <DropdownToggle nav caret id="custom-nav" className="profile">
                  {username}
                </DropdownToggle>
                <DropdownMenu className="dropdownmenu">
                  <DropdownItem href="/userProfile">Profile</DropdownItem>
                  <DropdownItem href="/login" onClick={() => AuthService.logout()}>
                    <p className="logout">Log Out</p>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          </div>
        </NavbarNav>
      </PageHeader>
    );
  }
}
