import RestService from './RestService';

class InventoryRequestService {
  async createRestockRequest(newRestockRequest) {
    return RestService.post('/restock-requests', newRestockRequest).then(result => {
      return result;
    });
  }

  async getAllRestockRequests() {
    return RestService.get('/restock-requests').then(result => {
      return result;
    });
  }

  async deleteRestockRequest(restock_request_id) {
    return RestService.delete(`/restock-requests/${restock_request_id}`).then(result => {
      return result;
    });
  }

  async updateRestockRequest(restock_request_id, restockRequestPayload) {
    const payload = buildUpdatePayload(restockRequestPayload);
    return RestService.patch(`/restock-requests/${restock_request_id}`, payload).then(result => {
      return result;
    });
  }

  async createStockItem(newStockItem) {
    return RestService.post('/stock-items', newStockItem).then(result => {
      return result;
    });
  }

  async getAllStockItems() {
    return RestService.get('/stock-items').then(result => {
      return result;
    });
  }

  async updateStockItem(stock_item_id, stockItemPayload) {
    const payload = buildUpdatePayload(stockItemPayload);
    return RestService.patch(`/stock-items/${stock_item_id}`, payload).then(result => {
      return result;
    });
  }

  async deleteStockItem(stock_item) {
    return RestService.delete(`/stock-items/${stock_item.id}`).then(result => {
      return result;
    });
  }
}

const buildUpdatePayload = object => {
  const payload = [];
  Object.keys(object).forEach(key => {
    payload.push({ op: 'replace', path: `/${key}`, value: object[key] });
  });
  return payload;
};

export default new InventoryRequestService();
