import React, { Component, Fragment } from 'react';

import ConfigurationService from '../../services/ConfigurationService';
import InventoryComponent from '../../components/TrackPageComponents/Inventory/InventoryComponent';
import OrderComponent from '../../components/TrackPageComponents/Order/OrderComponent';
import AssetComponent from '../../components/TrackPageComponents/Asset/AssetComponent';

// import InventoryDataView from '../order/InventoryDataView';

export default class TrackPage extends Component {
  getPageHeaderAndDescription = () => {
    const solutionConfig = ConfigurationService.getSolutionConfiguration();
    const trackedObjectViewerConfiguration = solutionConfig.trackedObjectDataViewerConfiguration;
    return {
      header: trackedObjectViewerConfiguration.trackedObjectDataViewerPageHeader,
      description: trackedObjectViewerConfiguration.trackedObjectDataViewerPageDescription,
      tableHeader: trackedObjectViewerConfiguration.trackedObjectDataViewerTableHeader
    };
  };

  render() {
    const solutionType = ConfigurationService.getSolutionType();

    const pageHeaderAndDescription = this.getPageHeaderAndDescription();

    switch (solutionType) {
      case 'asset':
        return (
          <Fragment>
            <AssetComponent
              header={pageHeaderAndDescription.header}
              description={pageHeaderAndDescription.description}
            />
          </Fragment>
        );
      case 'inventory':
        return (
          <Fragment>
            <InventoryComponent
              header={pageHeaderAndDescription.header}
              tableHeader={pageHeaderAndDescription.tableHeader}
              description={pageHeaderAndDescription.description}
            />
          </Fragment>
        );
      case 'order':
        return (
          <Fragment>
            <OrderComponent
              header={pageHeaderAndDescription.header}
              tableHeader={pageHeaderAndDescription.tableHeader}
              description={pageHeaderAndDescription.description}
            />
          </Fragment>
        );
      default:
        // to fix compiler warning; should not enter this case
        return null;
    }
  }
}
